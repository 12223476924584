import { createSlice } from '@reduxjs/toolkit';
import { InvoiceState } from '../invoice/invoice.type';
import { InvoiceApi, SellInvoiceApi } from 'api';

const initialState: InvoiceState = {
  isLoading: false,
  isInvoiceLoading: false,
  isInvoiceFilterLoading: false,
};

const invoiceSlice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    setInvoiceIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setInvoiceError: (state, action) => {
      state.invoiceError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(InvoiceApi.getInvoiceFilterData.pending, (state, action) => {
        state.isInvoiceFilterLoading = true;
        state.invoiceFilterData = undefined;
      })
      .addCase(InvoiceApi.getInvoiceFilterData.fulfilled, (state, action) => {
        state.invoiceFilterData = action.payload;
        state.isInvoiceFilterLoading = false;
      })
      .addCase(InvoiceApi.getInvoiceFilterData.rejected, (state, action) => {
        state.invoiceError = action.payload;
        state.isInvoiceFilterLoading = false;
      })
      .addCase(InvoiceApi.getInvoiceData.pending, (state, action) => {
        state.isInvoiceLoading = true;
        state.invoiceData = undefined;
      })
      .addCase(InvoiceApi.getInvoiceData.fulfilled, (state, action) => {
        state.invoiceData = action.payload;
        state.isInvoiceLoading = false;
      })
      .addCase(InvoiceApi.getInvoiceData.rejected, (state, action) => {
        state.invoiceError = action.payload;
        state.isInvoiceLoading = false;
      })
      .addCase(InvoiceApi.deleteInvoice.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(InvoiceApi.deleteInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(InvoiceApi.deleteInvoice.rejected, (state, action) => {
        state.invoiceError = action.payload;
        state.isLoading = false;
      })
      .addCase(InvoiceApi.getInvoiceDetails.pending, (state, action) => {
        state.isLoading = true;
        state.invoiceDetailData = undefined;
      })
      .addCase(InvoiceApi.getInvoiceDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.invoiceDetailData = action.payload;
      })
      .addCase(InvoiceApi.getInvoiceDetails.rejected, (state, action) => {
        state.invoiceError = action.payload;
        state.isLoading = false;
      })
      .addCase(InvoiceApi.getInvoiceSellBuyerDetail.pending, (state, action) => {
        state.isLoading = true;
        state.buyerSellerDetailData = undefined;
      })
      .addCase(InvoiceApi.getInvoiceSellBuyerDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.buyerSellerDetailData = action.payload;
      })
      .addCase(InvoiceApi.getInvoiceSellBuyerDetail.rejected, (state, action) => {
        state.invoiceError = action.payload;
        state.isLoading = false;
      })
      .addCase(InvoiceApi.updateInvoiceBuyerDetail.pending, (state, action) => {
        state.isLoading = true;
        state.buyerSellerDetailData = undefined;
      })
      .addCase(InvoiceApi.updateInvoiceBuyerDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.buyerSellerDetailData = action.payload;
      })
      .addCase(InvoiceApi.updateInvoiceBuyerDetail.rejected, (state, action) => {
        state.invoiceError = action.payload;
        state.isLoading = false;
      }) // Charts
      .addCase(InvoiceApi.getActivelyfundedamount.pending, (state, action) => {
        state.isActiveLoading = true;
        state.activelyfundedamountData = undefined;
      })
      .addCase(InvoiceApi.getActivelyfundedamount.fulfilled, (state, action) => {
        state.activelyfundedamountData = action.payload;
        state.isActiveLoading = false;
      })
      .addCase(InvoiceApi.getActivelyfundedamount.rejected, (state, action) => {
        state.invoiceError = action.payload;
        state.isActiveLoading = false;
      })
      .addCase(InvoiceApi.getUpcomingPayments.pending, (state, action) => {
        state.isUpcomingLoading = true;
        state.upcomingPaymentsData = undefined;
      })
      .addCase(InvoiceApi.getUpcomingPayments.fulfilled, (state, action) => {
        state.upcomingPaymentsData = action.payload;
        state.isUpcomingLoading = false;
      })
      .addCase(InvoiceApi.getUpcomingPayments.rejected, (state, action) => {
        state.invoiceError = action.payload;
        state.isUpcomingLoading = false;
      })
      .addCase(InvoiceApi.getTotalActiveUsers.pending, (state, action) => {
        state.isTotalActiveUserLoading = true;
        state.totalActiveUsersData = undefined;
      })
      .addCase(InvoiceApi.getTotalActiveUsers.fulfilled, (state, action) => {
        state.totalActiveUsersData = action.payload;
        state.isTotalActiveUserLoading = false;
      })
      .addCase(InvoiceApi.getTotalActiveUsers.rejected, (state, action) => {
        state.invoiceError = action.payload;
        state.isTotalActiveUserLoading = false;
      })
      .addCase(InvoiceApi.getAmountFundedBySurfScore.pending, (state, action) => {
        state.isAmountFundedSScoreLoading = true;
        state.amountFundedBySurfScoreData = undefined;
      })
      .addCase(InvoiceApi.getAmountFundedBySurfScore.fulfilled, (state, action) => {
        state.amountFundedBySurfScoreData = action.payload;
        state.isAmountFundedSScoreLoading = false;
      })
      .addCase(InvoiceApi.getAmountFundedBySurfScore.rejected, (state, action) => {
        state.invoiceError = action.payload;
        state.isAmountFundedSScoreLoading = false;
      })
      .addCase(InvoiceApi.getDashboardMatrix.pending, (state, action) => {
        state.isDashboardMatrixLoading = true;
        state.dashboardMatrixData = undefined;
      })
      .addCase(InvoiceApi.getDashboardMatrix.fulfilled, (state, action) => {
        state.dashboardMatrixData = action.payload;
        state.isDashboardMatrixLoading = false;
      })
      .addCase(InvoiceApi.getDashboardMatrix.rejected, (state, action) => {
        state.invoiceError = action.payload;
        state.isDashboardMatrixLoading = false;
      })
      .addCase(InvoiceApi.getDistributionOfTerms.pending, (state, action) => {
        state.isDistributionOfTermsLoading = true;
        state.distributionOfTermsData = undefined;
      })
      .addCase(InvoiceApi.getDistributionOfTerms.fulfilled, (state, action) => {
        state.distributionOfTermsData = action.payload;
        state.isDistributionOfTermsLoading = false;
      })
      .addCase(InvoiceApi.getDistributionOfTerms.rejected, (state, action) => {
        state.invoiceError = action.payload;
        state.isDistributionOfTermsLoading = false;
      })
      .addCase(InvoiceApi.getOfferAcceptedOnRejected.pending, (state, action) => {
        state.isOfferAcceptOnLoading = true;
        state.offerAcceptedOnRejectedData = undefined;
      })
      .addCase(InvoiceApi.getOfferAcceptedOnRejected.fulfilled, (state, action) => {
        state.offerAcceptedOnRejectedData = action.payload;
        state.isOfferAcceptOnLoading = false;
      })
      .addCase(InvoiceApi.getOfferAcceptedOnRejected.rejected, (state, action) => {
        state.invoiceError = action.payload;
        state.isOfferAcceptOnLoading = false;
      })
      .addCase(InvoiceApi.getDistributionOfRegisteredUserStatus.pending, (state, action) => {
        state.isDisOfRegisteredUserStatusLoading = true;
        state.distributionOfRegisteredUserStatusData = undefined;
      })
      .addCase(InvoiceApi.getDistributionOfRegisteredUserStatus.fulfilled, (state, action) => {
        state.distributionOfRegisteredUserStatusData = action.payload;
        state.isDisOfRegisteredUserStatusLoading = false;
      })
      .addCase(InvoiceApi.getDistributionOfRegisteredUserStatus.rejected, (state, action) => {
        state.invoiceError = action.payload;
        state.isDisOfRegisteredUserStatusLoading = false;
      })
      .addCase(InvoiceApi.getAverageRepaymentsReceived.pending, (state, action) => {
        state.isAverageRepaymentsRecLoading = true;
        state.averageRepaymentsReceivedData = undefined;
      })
      .addCase(InvoiceApi.getAverageRepaymentsReceived.fulfilled, (state, action) => {
        state.averageRepaymentsReceivedData = action.payload;
        state.isAverageRepaymentsRecLoading = false;
      })
      .addCase(InvoiceApi.getAverageRepaymentsReceived.rejected, (state, action) => {
        state.invoiceError = action.payload;
        state.isAverageRepaymentsRecLoading = false;
      })
      .addCase(InvoiceApi.getAverageFunded.pending, (state, action) => {
        state.isAverageFundedLoading = true;
        state.averageFundedData = undefined;
      })
      .addCase(InvoiceApi.getAverageFunded.fulfilled, (state, action) => {
        state.averageFundedData = action.payload;
        state.isAverageFundedLoading = false;
      })
      .addCase(InvoiceApi.getAverageFunded.rejected, (state, action) => {
        state.invoiceError = action.payload;
        state.isAverageFundedLoading = false;
      })
      .addCase(InvoiceApi.getOnTimePortfolioPerc.pending, (state, action) => {
        state.isOnTimePortFolioLoading = true;
        state.onTimePortfolioPercData = undefined;
      })
      .addCase(InvoiceApi.getOnTimePortfolioPerc.fulfilled, (state, action) => {
        state.onTimePortfolioPercData = action.payload;
        state.isOnTimePortFolioLoading = false;
      })
      .addCase(InvoiceApi.getOnTimePortfolioPerc.rejected, (state, action) => {
        state.invoiceError = action.payload;
        state.isOnTimePortFolioLoading = false;
      })
      .addCase(InvoiceApi.getAging.pending, (state, action) => {
        state.isAginIsLoading = true;
        state.agingData = undefined;
      })
      .addCase(InvoiceApi.getAging.fulfilled, (state, action) => {
        state.agingData = action.payload;
        state.isAginIsLoading = false;
      })
      .addCase(InvoiceApi.getAging.rejected, (state, action) => {
        state.invoiceError = action.payload;
        state.isAginIsLoading = false;
      })
      .addCase(InvoiceApi.getAverageProfits.pending, (state, action) => {
        state.isAverageProfitsLoading = true;
        state.averageProfitsData = undefined;
      })
      .addCase(InvoiceApi.getAverageProfits.fulfilled, (state, action) => {
        state.averageProfitsData = action.payload;
        state.isAverageProfitsLoading = false;
      })
      .addCase(InvoiceApi.getAverageProfits.rejected, (state, action) => {
        state.invoiceError = action.payload;
        state.isAverageProfitsLoading = false;
      })
      .addCase(InvoiceApi.getTotalInvestment.pending, (state, action) => {
        state.isTotalInvestmentLoading = true;
        state.totalInvestmentData = undefined;
      })
      .addCase(InvoiceApi.getTotalInvestment.fulfilled, (state, action) => {
        state.totalInvestmentData = action.payload;
        state.isTotalInvestmentLoading = false;
      })
      .addCase(InvoiceApi.getTotalInvestment.rejected, (state, action) => {
        state.invoiceError = action.payload;
        state.isTotalInvestmentLoading = false;
      });
  },
});

export const { setInvoiceIsLoading, setInvoiceError } = invoiceSlice.actions;

export default invoiceSlice.reducer;
