import ArrowUpward from '@mui/icons-material/ArrowUpward';
import Button from '@mui/material/Button';
import { FileUploader } from 'react-drag-drop-files';
interface OwnsProps {
  handleFileUpload: (file: File) => void;
  fileName?: string | null;
  name?: string;
}

const DocumentFileUploadBtn = ({ handleFileUpload, fileName, name }: OwnsProps) => {
  const uploadFile = (file: File) => {
    if (!fileName) {
      handleFileUpload(file);
    }
  };
  return (
    <FileUploader
      multiple={false}
      handleChange={uploadFile}
      name={name ? name : 'file'}
      disabled={!!fileName}
    >
      <Button
        variant="contained"
        endIcon={<ArrowUpward />}
        component={'label'}
        fullWidth
        disabled={!!fileName}
        sx={{
          height: '32px',
          backgroundColor: (theme) => theme.palette.common.white,
          color: (theme) => theme.palette.text.secondary,
          justifyContent: 'space-between',
          marginTop: '10px',
          borderRadius: '10px',
          padding: '5px 15px',
          fontSize: '13px',
          boxShadow: (theme) => theme.shadows[0],
          '&:hover': {
            backgroundColor: (theme) => theme.palette.primary.main,
            color: (theme) => theme.palette.common.white,
          },
        }}
      >
        {!!fileName ? fileName : 'Upload'}
      </Button>
    </FileUploader>
  );
};
export default DocumentFileUploadBtn;
