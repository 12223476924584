// TODO review it
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ServerErrors } from 'redux/common/common.type';
import axios, { handleServerErrors } from 'utils/axios.config';

//TODO review it, check the payload , seller
const updateDepositSellerInvoiceApproval = createAsyncThunk<
  any,
  any,
  { rejectValue: ServerErrors }
>('updateDepositSellerInvoiceApproval', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await axios.put<any>(
      `${process.env.REACT_APP_SERVER_URL}invoices/sell_invoices/seller_invoice_approval/`,
      { ...payload }
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

// TODO review it, check response, seller only
const getBulkActionList = createAsyncThunk<any, string, { rejectValue: ServerErrors }>(
  'getBulkActionList',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<any>(
        `${process.env.REACT_APP_SERVER_URL}invoices/sell_invoices/bulk_action_status/${params}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO review it, check response, payload
const rejectBid = createAsyncThunk<any, any, { rejectValue: ServerErrors }>(
  'rejectBid',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.put<any>(
        `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/auction/bid/reject/`,
        { ...payload }
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO review it, check response, payload
const acceptBid = createAsyncThunk<any, any, { rejectValue: ServerErrors }>(
  'acceptBid',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.put<any>(
        `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/auction/bid/accept/`,
        { ...payload }
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

export default {
  updateDepositSellerInvoiceApproval,
  getBulkActionList,
  rejectBid,
  acceptBid,
};
