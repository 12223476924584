import { createAsyncThunk } from '@reduxjs/toolkit';
import { ServerErrors } from 'redux/common/common.type';
import {
  ACHTermsandConditionsData,
  RepaymentDetailData,
  SelectAllInvoiceData,
  VerifyOrderPayload,
} from 'redux/repayment/repayment.type';
import axios, { handleServerErrors } from 'utils/axios.config';

const getRepaymentDetails = createAsyncThunk<
  RepaymentDetailData,
  string,
  { rejectValue: ServerErrors }
>('getRepaymentDetails', async (params, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<RepaymentDetailData>(
      `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/repayment/details/${params}`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

const getSelectAllInvoiceData = createAsyncThunk<
  SelectAllInvoiceData[],
  void,
  { rejectValue: ServerErrors }
>('getSelectAllInvoiceData', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<SelectAllInvoiceData[]>(
      `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/repayment-invoice/select-all/`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

// TODO review it, check response
const getShareAccountInformation = createAsyncThunk<any, void, { rejectValue: ServerErrors }>(
  'getShareAccountInformation',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<any>(
        `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/auction/share_account_information/`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO review it, check the payload
const makePayment = createAsyncThunk<any, any, { rejectValue: ServerErrors }>(
  'makePayment',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post<any>(
        `${process.env.REACT_APP_SERVER_URL}snapcheck/make-payment/`,
        { ...payload }
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO review it, check the payload
const manageFunderRepay = createAsyncThunk<any, any, { rejectValue: ServerErrors }>(
  'manageFunderRepay',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post<any>(
        `${process.env.REACT_APP_SERVER_URL}snapcheck/manage-money/`,
        { ...payload }
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO review it, check the params
const getACHTermsandConditions = createAsyncThunk<
  ACHTermsandConditionsData,
  string,
  { rejectValue: ServerErrors }
>('getACHTermsandConditions', async (params, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<ACHTermsandConditionsData>(
      `${process.env.REACT_APP_SERVER_URL}appui/external_app_terms/${params}`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

// TODO review it , check input and output
const verifyOrder = createAsyncThunk<any, VerifyOrderPayload, { rejectValue: ServerErrors }>(
  'verifyOrder',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post<any>(
        `${process.env.REACT_APP_SERVER_URL}western_union/order_verification/`,
        { ...payload }
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO check response, check bank url
const getOrderVerificationInfo = createAsyncThunk<any, string, { rejectValue: ServerErrors }>(
  'getOrderVerificationInfo',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<any>(
        `${process.env.REACT_APP_SERVER_URL}western_union/order_verification/${params}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO check response, check bank url
const saveSharedAccountInformation = createAsyncThunk<any, any, { rejectValue: ServerErrors }>(
  'saveSharedAccountInformation',
  async ([payload], { rejectWithValue }) => {
    try {
      const { data } = await axios.post<any>(
        `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/auction/share_account_information/`,
        payload
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

export default {
  getRepaymentDetails,
  getSelectAllInvoiceData,
  getShareAccountInformation,
  makePayment,
  manageFunderRepay,
  getACHTermsandConditions,
  verifyOrder,
  getOrderVerificationInfo,
  saveSharedAccountInformation,
};
