import { createSlice } from '@reduxjs/toolkit';
import { LoginState, RegistrationSteps } from '../login/login.type';
import { LoginApi } from 'api';
import { VerificationTypes } from 'redux/common/common.type';

const initialState: LoginState = {
  isLoading: false,
  isAuthenticated: false,
  userData: undefined,
  userToken: undefined,
  loginData: undefined,
  registrationStepsData: undefined,
  verificationMethod: VerificationTypes.SMS,
  loginError: undefined,
  isMissingBasicSteps: false,
};

const isMissingSteps = (steps?: RegistrationSteps[]) => {
  if (Array.isArray(steps) && !steps.find((f) => f.step_number <= 7 && !f.is_completed)) {
    return false;
  }
  return true;
};
const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setLoginError: (state, { payload }) => {
      state.loginError = payload;
    },
    setLogged: (state, { payload }) => {
      state.isAuthenticated = payload;
    },
    setVerificationMethod: (state, { payload }) => {
      state.verificationMethod = payload;
    },
    setUserData: (state, { payload }) => {
      state.userData = payload;
    },
    isMissingBasicSteps: (state, { payload }) => {
      state.isMissingBasicSteps = payload;
    },
    reset: (state) => {
      return { ...state, ...initialState };
    },
    setUserToken: (state, { payload }) => {
      state.userToken = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(LoginApi.signIn.pending, (state) => {
        state.loginData = undefined;
        state.isLoading = true;
        state.loginError = undefined;
      })
      .addCase(LoginApi.signIn.fulfilled, (state, action) => {
        state.loginData = action.payload;
        state.isAuthenticated = true;
      })
      .addCase(LoginApi.signIn.rejected, (state, action) => {
        state.isLoading = false;
        state.loginError = action.payload;
      })

      .addCase(LoginApi.keyInvitationValidation.rejected, (state, action) => {
        state.loginError = { ...action.payload, isLogOut: true };
        state.isLoading = false;
      })
      .addCase(LoginApi.getUserDetails.pending, (state) => {
        state.isLoading = true;
        state.userData = undefined;
      })
      .addCase(LoginApi.getUserDetails.fulfilled, (state, action) => {
        state.userData = action.payload;
        state.isLoading = false;
      })
      .addCase(LoginApi.getUserDetails.rejected, (state, action) => {
        state.loginError = action.payload;
        state.isLoading = false;
        state.isAuthenticated = false;
      })
      .addCase(LoginApi.getRegistrationSteps.pending, (state) => {
        state.registrationStepsData = undefined;
      })
      .addCase(LoginApi.getRegistrationSteps.fulfilled, (state, action) => {
        state.registrationStepsData = action.payload;
        state.isMissingBasicSteps = isMissingSteps(action.payload);
        state.isLoading = false;
      })
      .addCase(LoginApi.getRegistrationSteps.rejected, (state, action) => {
        state.loginError = action.payload;
        state.isLoading = false;
      })

      .addCase(LoginApi.getTenantDetail.pending, (state) => {
        state.tenantDetailData = undefined;
      })
      .addCase(LoginApi.getTenantDetail.fulfilled, (state, action) => {
        state.tenantDetailData = action.payload;
      })
      .addCase(LoginApi.getTenantDetail.rejected, (state, action) => {
        state.loginError = action.payload;
      })
      .addCase(LoginApi.updateUserLevel.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(LoginApi.updateUserLevel.fulfilled, (state, action) => {
        state.loginData = action.payload; //TODO check the response
        state.isLoading = false;
        state.isAuthenticated = true;
      })
      .addCase(LoginApi.updateUserLevel.rejected, (state, action) => {
        state.loginError = action.payload;
        state.isLoading = false;
      })
      .addCase(LoginApi.logout.pending, (state) => {
        return { ...state, ...initialState };
      })
      .addCase(LoginApi.logout.fulfilled, (state) => {
        return { ...state, ...initialState };
      })
      .addCase(LoginApi.logout.rejected, (state, action) => {
        return { ...state, ...initialState, loginError: action.payload };
      });
  },
});

export const {
  setIsLoading,
  reset,
  setUserData,
  setLogged,
  setUserToken,
  setLoginError,
  setVerificationMethod,
} = loginSlice.actions;

export default loginSlice.reducer;
