import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import loginReducer from './slices/login';
import configReducer from './slices/config';
import overviewReducer from './slices/overview';
import repaymentReducer from './slices/repayment';
import activeReducer from './slices/active';
import contactsReducer from './slices/contacts';
import commonReducer from './slices/common';
import historyReducer from './slices/history';
import marketplaceReducer from './slices/marketplace';
import parametersReducer from './slices/parameters';
import settingsReducer from './slices/settings';
import userNotificationReducer from './slices/userNotifications';
import surfScoreReducer from './slices/surfScore';
import notificationReducer from './slices/notification';
import walletReducer from './slices/wallet';
import userManagementReducer from './slices/userManagement';
import bankReducer from './slices/bank';
import invoiceReducer from './slices/invoice';
import { LoginState } from './login/login.type';
import { ConfigState } from './config/config.type';
import { OverviewState } from './overview/overview.type';
import { RepaymentState } from './repayment/repayment.type';
import { ActiveState } from './active/active.type';
import { ContactsState } from './contacts/contacts.type';
import { CommonState } from './common/common.type';
import { HistoryState } from './history/history.type';
import { MarketplaceState } from './marketplace/marketplace.type';
import { ParametersState } from './parameters/parameters.type';
import { SettingsState } from './settings/settings.type';
import { UserNotificationState } from './userNotification/userNotification.type';
import { SurfScoreState } from './surfScore/surfScore.type';
import { NotificationState } from './notification/notification.type';
import { WalletState } from './wallet/wallet.type';
import { UserManagementState } from './userManagement/userManagement.type';
import { BankState } from './bank/bank.type';
import { InvoiceState } from './invoice/invoice.type';

export interface InitialRootState {
  login: LoginState;
  config: ConfigState;
  overview: OverviewState;
  repayment: RepaymentState;
  active: ActiveState;
  contacts: ContactsState;
  common: CommonState;
  history: HistoryState;
  marketplace: MarketplaceState;
  parameters: ParametersState;
  settings: SettingsState;
  userNotification: UserNotificationState;
  surfScore: SurfScoreState;
  notification: NotificationState;
  wallet: WalletState;
  userManagement: UserManagementState;
  bank: BankState;
  invoice: InvoiceState;
}

const rootPersistConfig = {
  key: 'login',
  storage,
  whitelist: ['login', 'config'],
};
const combinedReducer = combineReducers({
  login: loginReducer,
  config: configReducer,
  overview: overviewReducer,
  repayment: repaymentReducer,
  active: activeReducer,
  contacts: contactsReducer,
  common: commonReducer,
  history: historyReducer,
  marketplace: marketplaceReducer,
  parameters: parametersReducer,
  settings: settingsReducer,
  userNotification: userNotificationReducer,
  surfScore: surfScoreReducer,
  notification: notificationReducer,
  wallet: walletReducer,
  userManagement: userManagementReducer,
  bank: bankReducer,
  invoice: invoiceReducer,
});

export { rootPersistConfig, combinedReducer };
