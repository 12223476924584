import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import * as Yup from 'yup';
import { InputLabel, TextField, Button } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { BasicSignupPayload } from 'redux/login/login.type';
import FormikSelect from 'components/formik/FormikSelect';
import FormikTextField from 'components/formik/FormikTextField';
import { useSelector, useDispatch } from 'redux/store';
import AutoComplete, { usePlacesWidget } from 'react-google-autocomplete';
import { useEffect, useState } from 'react';
import { CommonApi, UserManagementApi } from 'api';
import useAuth from 'hooks/useAuth';

interface OwnProps {
  onSubmit: (value: Partial<BasicSignupPayload>) => void;
  handleCountrySearch: (code: string) => void;
  init: Partial<BasicSignupPayload>;
}
const ValidationSchema = Yup.object().shape({
  address1: Yup.string().required('This field is required'),
  city: Yup.string().required('This field is required.'),
  country: Yup.string().required('This field is required.'),
  zipcode: Yup.number().required('This field is required.'), //TODO Pattern should be added for zipcode and maxLength According to country
});

// TODO clean after test
const AddressRegStep = ({ onSubmit, handleCountrySearch, init }: OwnProps) => {
  const { stateList, countryListData } = useSelector((state) => state.common);
  const [countryCode, setCountryCode] = useState<string>('');
  const { profileFieldsData } = useSelector((state) => state.userManagement);
  const [labelFields, setLabelFields] = useState({
    postalCode: profileFieldsData?.country?.find((p) => p.field_name === 'zip_code'),
    stateLabel: profileFieldsData?.country?.find((p) => p.field_name === 'state'),
  });
  const { userData } = useAuth();

  // const { ref } = usePlacesWidget({
  //   apiKey: process.env.REACT_APP_MAPS_API_KEY,
  //   onPlaceSelected: (place: any) => console.log(place),
  // });

  // const { ref: address2Ref } = usePlacesWidget({
  //   apiKey: process.env.REACT_APP_MAPS_API_KEY,
  //   onPlaceSelected: (place: any) => console.log(place),
  // });

  // country_isd_code: 14,phone: "8667887655"
  // country_isd_code
  // {"country_isd_code":14,"phone":"8667887655","otp":"1222"}
  // usermgmt/countries-list/?limit=250&sort_type=country_name

  const dispatch = useDispatch();

  useEffect(() => {
    let params = '?country_code=' + userData?.user_details?.country_code;
    dispatch(UserManagementApi.getProfileFields(params));
    if (!countryListData) {
      dispatch(CommonApi.getCountriesList('?limit=250&sort_type=country_name'));
    }
  }, []);

  return (
    <Formik
      initialValues={init}
      validationSchema={ValidationSchema}
      onSubmit={(values, actions) => {
        actions.setSubmitting(false);
        onSubmit({ ...values, countryCode });
      }}
    >
      {(props) => (
        <Form autoComplete="off" onSubmit={props.handleSubmit}>
          <Grid container sx={{ marginTop: 5 }}>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                fontSize={'16px'}
                fontWeight={(theme) => theme.typography.fontWeightBold}
                marginBottom={'30px'}
              >
                What is your company's address ?
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Field
                component={FormikSelect}
                name={`country`}
                label={<span style={{ color: 'textSecondary', fontSize: '15px' }}>Country*</span>}
                fontSize={'16px'}
                marginBottom={'10px'}
              >
                {countryListData?.results
                  .filter((f) => !!f && f.country_name)
                  .map((p, index) => (
                    <MenuItem
                      key={index}
                      value={p.country_name}
                      onClick={() => {
                        setCountryCode(p.alpha2_code?.toLowerCase());
                        handleCountrySearch(p.alpha2_code as string);
                        props?.setFieldValue('address1', '');
                        props?.setFieldValue('address2', '');
                        props?.setFieldValue('city', '');
                        props?.setFieldValue('zipcode', '');
                        props?.setFieldValue('state', '');
                      }}
                    >
                      {p.country_name}
                    </MenuItem>
                  ))}
              </Field>
              <ErrorMessage
                name="country"
                component="div"
                render={(msg) => (
                  <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                    {msg}
                  </Typography>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              {/* TODO On Address type , list of Address should be shown in dropdown According to Country And Other fields like city state should automatically fill */}
              <Field
                component={FormikTextField}
                label={<span style={{ color: 'textSecondary', fontSize: '15px' }}>Address 1*</span>}
                name={`address1`}
                fontSize={'16px'}
                marginBottom={'10px'}
                autoComplete={'off'}
              />
              <ErrorMessage
                name="address1"
                component="div"
                render={(msg) => (
                  <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                    {msg}
                  </Typography>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={FormikTextField}
                name="address2"
                label={<span style={{ color: 'textSecondary', fontSize: '15px' }}>Address 2</span>}
                fontSize={'16px'}
                marginBottom={'10px'}
                autoComplete={'off'}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={FormikTextField}
                name={`city`}
                label={<span style={{ color: 'textSecondary', fontSize: '15px' }}>City*</span>}
                fontSize={'16px'}
                marginBottom={'10px'}
                autoComplete={'off'}
              />
              <ErrorMessage
                name="city"
                component="div"
                render={(msg) => (
                  <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                    {msg}
                  </Typography>
                )}
              />
              <Field
                component={FormikTextField}
                name={`zipcode`}
                label={
                  <span style={{ color: 'textSecondary', fontSize: '15px' }}>
                    {labelFields?.postalCode?.label
                      ? labelFields?.postalCode?.label + '*'
                      : 'Zip Code*'}
                  </span>
                }
                fontSize={'16px'}
                marginBottom={'10px'}
                autoComplete={'off'}
                inputProps={{
                  maxLength: countryCode === 'us' ? 5 : countryCode === 'au' ? 4 : 8,
                }}
                InputProps={{
                  pattern: labelFields?.postalCode?.regex
                    ? labelFields?.postalCode?.regex
                    : '^[A-Z0-9_ ]{4,10}$',
                }}
              />
              <ErrorMessage
                name="zipcode"
                component="div"
                render={(msg) => (
                  <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                    {msg}
                  </Typography>
                )}
              />
              <Field
                component={FormikSelect}
                name={`state`}
                label={
                  <span style={{ color: 'textSecondary', fontSize: '15px' }}>
                    {labelFields?.stateLabel?.label
                      ? labelFields?.stateLabel?.label + '*'
                      : 'State*'}
                  </span>
                }
                fontSize={'16px'}
                disabled={!props.values.country}
              >
                {!!stateList &&
                  stateList.results?.map((p, index) => (
                    <MenuItem key={index} value={p.region_name}>
                      {p.region_name}
                    </MenuItem>
                  ))}
              </Field>
              <ErrorMessage
                name="state"
                component="div"
                render={(msg) => (
                  <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                    {msg}
                  </Typography>
                )}
              />
            </Grid>
            <Grid item sx={{ marginTop: 2 }} xs={12}>
              <Button type="submit" variant="contained">
                Next
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default AddressRegStep;
