import { createAsyncThunk } from '@reduxjs/toolkit';

import { ServerErrors, ResponseSuccess } from 'redux/common/common.type';

import axios, { handleServerErrors } from 'utils/axios.config';
// TODO review it, check response, payload
const getExportDataInvoicesFile = createAsyncThunk<
  Blob,
  { selectedType: string; params: string },
  { rejectValue: ServerErrors }
>('getExportDataInvoicesFile', async (queryData, { rejectWithValue }) => {
  const path =
    queryData.selectedType === 'saas_contract'
      ? 'invoice-exchange/dashboard/export/invoices_receivable'
      : 'invoice-exchange/dashboard/export/invoices';
  try {
    const { data } = await axios.get<Blob>(
      `${process.env.REACT_APP_SERVER_URL}${path}/${queryData.params}`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

// TODO review it, check response, payload
const getExportDataInvoicePreAuctionFileWithQuery = createAsyncThunk<
  Blob,
  string,
  { rejectValue: ServerErrors }
>('getExportDataInvoicePreAuctionFileWithQuery', async (params, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<Blob>(
      `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/export/invoices/${params}`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

// TODO review it, check response, payload
const getExportDataInvoiceRepaymentAuctionFile = createAsyncThunk<
  Blob,
  { selectedType: string; params: string },
  { rejectValue: ServerErrors }
>('getExportDataInvoiceRepaymentAuctionFile', async (queryData, { rejectWithValue }) => {
  const path =
    queryData.selectedType === 'saas_contract'
      ? 'invoice-exchange/dashboard/export/repayment_receivable'
      : 'invoice-exchange/dashboard/export/repayment';
  try {
    const { data } = await axios.get<Blob>(
      `${process.env.REACT_APP_SERVER_URL}${path}/${queryData.params}`,
      {
        responseType: 'blob',
      }
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});
// TODO check not needed
const Old_getExportDataInvoiceRepaymentFileAuction = createAsyncThunk<
  Blob,
  string,
  { rejectValue: ServerErrors }
>('Old_getExportDataInvoiceRepaymentFileAuction', async (params, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<Blob>(
      `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/export/repayment/${params}`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

// TODO check not needed
const Old_getExportDataSellInvoices = createAsyncThunk<Blob, string, { rejectValue: ServerErrors }>(
  'Old_getExportDataSellInvoices',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<Blob>(
        `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/export/auctions/${params}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO review it, check response, payload

const getExportDataHistoryAuctionFile = createAsyncThunk<
  Blob,
  { selectedType: string; params: string },
  { rejectValue: ServerErrors }
>('getExportDataHistoryAuctionFile', async (queryData, { rejectWithValue }) => {
  const path =
    queryData.selectedType === 'saas_contract'
      ? 'invoice-exchange/dashboard/export/history_receivable'
      : 'invoice-exchange/dashboard/export/history';
  try {
    const { data } = await axios.get<Blob>(
      `${process.env.REACT_APP_SERVER_URL}${path}/${queryData.params}`,
      {
        responseType: 'blob',
      }
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

// TODO review it, check response, payload
// export api
const getExportInvoiceAuctionFileByType = createAsyncThunk<
  Blob,
  { selectedType: string; params: string },
  { rejectValue: ServerErrors }
>('getExportDataInvoices', async (queryData, { rejectWithValue }) => {
  const path =
    queryData.selectedType === 'saas_contract'
      ? 'invoice-exchange/dashboard/export/auctions_receivable'
      : 'invoice-exchange/dashboard/export/auctions';
  try {
    const { data } = await axios.get<Blob>(
      `${process.env.REACT_APP_SERVER_URL}${path}/${queryData.params}`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

// TODO check
//query  invoice /?file_type=' + fileType  + filters , {
// ?file_type=' + fileType  + filters  + '&month=' + date.month + '&year=' + date.year + '&receivable_type=' + tab, {

const getExportDataTransactionFileAuction = createAsyncThunk<
  Blob,
  string,
  { rejectValue: ServerErrors }
>('getExportDataTransactionFileAuction', async (params, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<Blob>(
      `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/export/transactions/${params}`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

// TODO check not needed
const getExportDataWalletAuctionFile = createAsyncThunk<
  Blob,
  string,
  { rejectValue: ServerErrors }
>('getExportDataWalletAuctionFile', async (params, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<Blob>(
      `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/export/wallet/${params}`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});
// TODO review it, check response, and params
//
const getInvoiceBVisibilityActiveDataFile = createAsyncThunk<
  Blob,
  string,
  { rejectValue: ServerErrors }
>('getInvoiceBVisibilityActiveDataFile', async (params, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<Blob>(
      `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/invoice_visibility/list/export/${params}`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

// TODO review it, check response, and params
//
const getApprovalFile = createAsyncThunk<Blob, string, { rejectValue: ServerErrors }>(
  'getApprovalFile',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<Blob>(
        `${process.env.REACT_APP_SERVER_URL}usermgmt/export/approvals/${params}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);
const getContactsVisibilityFile = createAsyncThunk<Blob, string, { rejectValue: ServerErrors }>(
  'getContactsVisibilityFile',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<Blob>(
        `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/invoice_contacts_visibility/list/export/${params}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

const getBuyInvoiceDataFile = createAsyncThunk<Blob, string, { rejectValue: ServerErrors }>(
  'getBuyInvoiceDataFile',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<Blob>(
        `${process.env.REACT_APP_SERVER_URL}invoices/buy_invoices/export/${params}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

const getSellInvoiceDataFile = createAsyncThunk<Blob, string, { rejectValue: ServerErrors }>(
  'getSellInvoiceDataFile',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<Blob>(
        `${process.env.REACT_APP_SERVER_URL}invoices/sell_invoices/export/${params}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO
const getExportSellerProfile = createAsyncThunk<Blob, string, { rejectValue: ServerErrors }>(
  'getExportSellerProfile',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<Blob>(
        `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/export/seller_profile/${params}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO
const getExportBuyerApprovalFile = createAsyncThunk<Blob, string, { rejectValue: ServerErrors }>(
  'getExportBuyerApprovalFile',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<Blob>(
        `${process.env.REACT_APP_SERVER_URL}invoices/buyer_approval/export/${params}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO
const downloadAgreement = createAsyncThunk<Blob, string, { rejectValue: ServerErrors }>(
  'downloadAgreement',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<Blob>(
        `${process.env.REACT_APP_SERVER_URL}appui/html_docs_download/${params}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO
const getSurfScoreFile = createAsyncThunk<Blob, string, { rejectValue: ServerErrors }>(
  'getSurfScoreFile',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<Blob>(
        `${process.env.REACT_APP_SERVER_URL}smartscore/surfscore/export/${params}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

const deleteDocument = createAsyncThunk<any, string, { rejectValue: ServerErrors }>(
  'deleteDocument',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.delete<Blob>(
        `${process.env.REACT_APP_SERVER_URL}usermgmt/download_document/${params}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

const downloadDocument = createAsyncThunk<any, string, { rejectValue: ServerErrors }>(
  'downloadDocument',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<Blob>(
        `${process.env.REACT_APP_SERVER_URL}usermgmt/download_document/${params}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO
const uploadBulkInvoiceFile = createAsyncThunk<
  ResponseSuccess,
  FormData,
  { rejectValue: ServerErrors }
>('uploadBulkInvoiceFile', async (payload, { rejectWithValue }) => {
  try {
    const data = await fetch(
      `${process.env.REACT_APP_SERVER_URL}invoiceexchange/invoice/mass-upload/`,
      {
        method: 'POST',
        body: payload,
        credentials: 'include',
        headers: {
          apikey: `${process.env.REACT_APP_SERVER_APIKEY}`,
        },
      }
    ).then((res) => res.json());

    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});
export default {
  getSurfScoreFile,
  deleteDocument,
  getExportDataTransactionFileAuction,
  Old_getExportDataSellInvoices,
  Old_getExportDataInvoiceRepaymentFileAuction,
  getExportDataWalletAuctionFile,
  getExportDataInvoicesFile,
  getExportDataHistoryAuctionFile,
  getExportBuyerApprovalFile,
  getExportSellerProfile,
  getSellInvoiceDataFile,
  getBuyInvoiceDataFile,
  getContactsVisibilityFile,
  getApprovalFile,
  getInvoiceBVisibilityActiveDataFile,
  getExportInvoiceAuctionFileByType,
  getExportDataInvoiceRepaymentAuctionFile,
  getExportDataInvoicePreAuctionFileWithQuery,
  uploadBulkInvoiceFile,
  downloadAgreement,
  downloadDocument,
};
