import { createSlice } from '@reduxjs/toolkit';
import { ParametersState } from '../parameters/parameters.type';
import { ParametersApi } from 'api';

const initialState: ParametersState = {
  isLoading: false,
};

const parametersSlice = createSlice({
  name: 'parameters',
  initialState,
  reducers: {
    setIsLoading: (state) => {
      state.isLoading = true;
    },
    reset: (state) => {
      state.isLoading = false; //TODO
    },
    setParametersError: (state, action) => {
      state.parametersError = action.payload;
    },
    resetParameters: (state) => {
      state.parameterData = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(ParametersApi.getParameterData.pending, (state) => {
        state.isLoading = true;
        state.parameterData = undefined;
      })
      .addCase(ParametersApi.getParameterData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.parameterData = action.payload;
      })
      .addCase(ParametersApi.getParameterData.rejected, (state, action) => {
        state.isLoading = false;
        state.parametersError = action.payload;
      });
  },
});

export const { setIsLoading, reset, setParametersError, resetParameters } = parametersSlice.actions;

export default parametersSlice.reducer;
