import { ReactNode } from 'react';
import useAuth from 'hooks/useAuth';
import { Navigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import LoadingProgress from 'components/LoadingProgress';

type RoleBasedGuardProp = {
  accessibleRoles: string[];
  allowedTenants: string[];
  children: ReactNode;
};

export default function RoleBasedGuard({
  accessibleRoles,
  allowedTenants,
  children,
}: RoleBasedGuardProp) {
  const { role, isAuthenticated, isLoading, userData } = useAuth();

  if (isLoading) {
    return <LoadingProgress />;
  }
  if (
    isAuthenticated &&
    !!userData &&
    (!accessibleRoles.includes(role) ||
      !allowedTenants.includes(process.env.REACT_APP_TENANT_NAME || ''))
  ) {
    return <Navigate to={'/404'} />;
  }

  return <>{children}</>;
}
