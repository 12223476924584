import { IconButton, Stack, styled } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';
import { ChangeEvent } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CommonBootstrapDialogTitle, { BootstrapDialog } from 'components/CommonBootstrapDialogTitle';

interface OwnProps {
  open: boolean;
  onClose: () => void;
  handleFileUpload: (e: ChangeEvent<HTMLInputElement>) => void;
  message?: string;
}
const DialogCustom = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
  maxWidth: '450px',
}));

const BulkReceivablesUpload = ({ open, onClose, handleFileUpload, message }: OwnProps) => {
  return (
    <BootstrapDialog onClose={onClose} open={open} maxWidth={'xs'}>
      <CommonBootstrapDialogTitle title={' Bulk Receivable Upload'} onClose={onClose} />

      <DialogContent sx={{ p: 0 }}>
        {!!message ? (
          <>
            <Typography variant={'subtitle2'} fontSize={'13px'}>
              {message}
            </Typography>
          </>
        ) : (
          <>
            <Typography variant={'subtitle2'} fontSize={'13px'}>
              Download our CSV template for uploading your receivables in bulk.
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '20px',
              }}
            >
              <Box
                sx={{
                  border: '1px dashed',
                  borderColor: (theme) => theme.palette.grey[400],
                  width: '130px',
                  height: '130px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Stack textAlign={'center'}>
                  <IconButton color="primary" aria-label="upload picture" component="label">
                    <input hidden accept=".csv" type="file" onChange={handleFileUpload} />
                    <CloudUploadIcon />
                  </IconButton>
                  <Typography variant={'subtitle2'} fontSize={'12px'}>
                    upload your receivables.
                  </Typography>
                </Stack>
              </Box>
            </Box>
          </>
        )}
      </DialogContent>
      {!!message ? (
        <DialogActions>
          <Button variant="text" sx={{ textTransform: 'none' }} onClick={onClose}>
            Close
          </Button>
        </DialogActions>
      ) : (
        ''
      )}
    </BootstrapDialog>
  );
};

export default BulkReceivablesUpload;
