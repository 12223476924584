import { createSlice } from '@reduxjs/toolkit';
import { ActiveState } from '../active/active.type';
import { ActiveApi } from 'api';

const initialState: ActiveState = {
  isLoading: false,
};

const activeSlice = createSlice({
  name: 'active',
  initialState,
  reducers: {
    setIsLoading: (state) => {
      state.isLoading = true;
    },
    setActiveError: (state, { payload }) => {
      state.activeError = payload;
    },
    reset: (state) => {
      state.isLoading = false; //TODO
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(ActiveApi.getActiveInvoiceList.pending, (state) => {
        state.isLoading = true;
        state.activeInvoiceData = undefined;
      })
      .addCase(ActiveApi.getActiveInvoiceList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.activeInvoiceData = action.payload;
      })
      .addCase(ActiveApi.getActiveInvoiceList.rejected, (state, action) => {
        state.isLoading = false;
        state.activeError = action.payload;
      })
      .addCase(ActiveApi.getActiveInvoiceFilterList.pending, (state) => {
        state.isLoading = true;
        state.activeInvoiceFilterData = undefined;
      })
      .addCase(ActiveApi.getActiveInvoiceFilterList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.activeInvoiceFilterData = action.payload;
      })
      .addCase(ActiveApi.getActiveInvoiceFilterList.rejected, (state, action) => {
        state.isLoading = false;
        state.activeError = action.payload;
      });
  },
});

export const { setIsLoading, reset, setActiveError } = activeSlice.actions;

export default activeSlice.reducer;
