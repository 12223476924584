import { ArrowDownward, ArrowUpward, MoreVertSharp } from '@mui/icons-material';
import { Button, Grid, Stack, styled, Typography, useTheme } from '@mui/material';
import { CommonApi, FileApi, SettingsApi } from 'api';
import SettingsBreadCrumbs from 'components/breadCrumbs/SettingsBreadCrumbs';
import CustomSnackbar from 'components/CustomSnackbar';
import CommonMessageModal from 'components/Models/CommonMessageModal';
import { createElement, useEffect, useState } from 'react';
import { CommonErrorModalProps } from 'redux/common/common.type';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'redux/store';
import DocumentFileUploadBtn from './DocumentFileUploadBtn';
import Page from 'components/Page';
import useAuth from 'hooks/useAuth';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { FileUploader } from 'react-drag-drop-files';
import LegalAgreementModal from 'components/Models/LegalAgreementModal';

const StyledTypo = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  marginBottom: 1,
  color: theme.palette.grey[900],
}));

const StyleButton = {
  height: '32px',
  justifyContent: 'space-between',
  marginTop: '10px',
  borderRadius: '10px',
  padding: '5px 15px',
  fontSize: '13px',
};

interface ActionProps {
  handleActionClick: (type: string, file?: File) => void;
}
const ActionMenuItem = ({ handleActionClick }: ActionProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const eclipsMenuopen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls={eclipsMenuopen ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={eclipsMenuopen ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={eclipsMenuopen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          style: {
            padding: 0,
          },
        }}
        PaperProps={{
          style: {
            width: '25ch',
            borderRadius: '10px',
            padding: '0',
            margin: '0',
          },
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            handleActionClick('delete');
          }}
          sx={{
            justifyContent: 'center',
            fontSize: 14,
            padding: '10px 15px',
            '&:hover': {
              backgroundColor: (theme) => theme.palette.primary.main,
              color: (theme) => theme.palette.primary.contrastText,
            },
          }}
        >
          Delete
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
          }}
          sx={{
            justifyContent: 'center',
            fontSize: 14,
            padding: '10px 15px',
            '&:hover': {
              backgroundColor: (theme) => theme.palette.primary.main,
              color: (theme) => theme.palette.primary.contrastText,
            },
          }}
        >
          <FileUploader
            multiple={false}
            handleChange={(file: File) => handleActionClick('upload', file)}
          >
            Upload New One
          </FileUploader>
        </MenuItem>
      </Menu>
    </>
  );
};

const DocumentLibraryPage = () => {
  const breadCrumbs = { name: 'Settings/Document Library', path: '#' };
  const [openSnackbar, setOpenSnackbar] = useState<CommonErrorModalProps>({ open: false });
  const [openLegalAgreement, setOpenLegalAgreement] = useState(false);
  const dispatch = useDispatch();
  const { libraryDocs } = useSelector((state) => state.settings);
  const { initUserInfo } = useAuth();
  const [agreeCertifyMess, setAgreeCertifyMess] = useState<any>({ open: false });
  const theme = useTheme();

  const uploadDocument = async () => {
    const bodyFormData = new FormData();
    bodyFormData.append('documents', JSON.stringify(agreeCertifyMess?.data)); // TODO not needed
    bodyFormData.append('file_obj', agreeCertifyMess?.file);

    const action = await dispatch(CommonApi.uploadDocument(bodyFormData));
    if (CommonApi.uploadDocument.fulfilled.match(action)) {
      // setOpenConfirmationModal(true);
      initUserInfo();
      action.payload?.success &&
        setOpenSnackbar({
          open: true,
          message: action.payload?.success?.message,
          type: 'success',
        });
    }
    if (CommonApi.uploadDocument.rejected.match(action)) {
      action.payload?.error &&
        setOpenSnackbar({ open: true, message: action.payload?.error?.message, type: 'error' });
    }
  };

  const handleUploadFile = (file: File) => {
    setAgreeCertifyMess({
      open: true,
      data: [{ doc_type: 'cashflow_statement', request_id: null }],
      file: file,
    });
  };

  useEffect(() => {
    dispatch(SettingsApi.getAllLibraryDocuments());
  }, []);

  const handleDownloadDocument = async (id: string | null) => {
    let param = `?doc_id=${id}`;
    let action = await dispatch(FileApi.downloadDocument(param));
    if (FileApi.downloadAgreement.fulfilled.match(action)) {
      const a = document.createElement('a') as HTMLAnchorElement;
      a.href = window.URL.createObjectURL(action.payload);
      a.setAttribute('download', 'document.pdf');
      document.body.appendChild(a);
      a.click();
      a.remove();
    }

    if (FileApi.downloadAgreement.rejected.match(action)) {
      action.payload?.error &&
        setOpenSnackbar({ open: true, message: action.payload?.error?.message, type: 'error' });
    }
  };

  const handleMenuTypeClick = async (id: string | null) => {
    let param = `?doc_id=${id}`;
    let action = await dispatch(FileApi.deleteDocument(param));
    if (FileApi.deleteDocument.fulfilled.match(action)) {
      action.payload?.success &&
        setOpenSnackbar({ open: true, message: action.payload?.success?.message, type: 'success' });
    }

    if (FileApi.deleteDocument.rejected.match(action)) {
      action.payload?.error &&
        setOpenSnackbar({ open: true, message: action.payload?.error?.message, type: 'error' });
    }
  };

  return (
    <Page title="Document upload">
      <SettingsBreadCrumbs data={breadCrumbs} />
      <Grid container columnSpacing={4} rowGap={4}>
        {Array.isArray(libraryDocs) &&
          libraryDocs.map((p) => (
            <Grid item xs={12} sm={12} md={6} lg={6} key={p.label}>
              <Stack>
                <StyledTypo variant="body2">{p?.label}</StyledTypo>
                {p?.label === 'Agreement' ? (
                  <>
                    <Button
                      variant="contained"
                      endIcon={
                        <IconButton>
                          <ArrowDownward />
                        </IconButton>
                      }
                      component={'label'}
                      fullWidth
                      onClick={() => setOpenLegalAgreement(true)}
                      sx={{
                        backgroundColor: (theme) => theme.palette.common?.white,
                        color: (theme) => theme.palette.text?.secondary,
                        boxShadow: (theme) => theme.shadows[0],
                        '&:hover': {
                          backgroundColor: (theme) => theme.palette.primary.main,
                          color: (theme) => theme.palette.primary.contrastText,
                        },
                        ...StyleButton,
                      }}
                    >
                      Download
                    </Button>
                  </>
                ) : (
                  <>
                    {p.doc_name ? (
                      <Stack direction="row">
                        <Button
                          variant="contained"
                          endIcon={
                            <IconButton>
                              <ArrowDownward />
                            </IconButton>
                          }
                          component={'label'}
                          fullWidth
                          onClick={() => handleDownloadDocument(p?.doc_id)}
                          sx={{
                            backgroundColor: (theme) => theme.palette.customColors?.disabledBg,
                            color: (theme) => theme.palette.customColors?.disabledColor,
                            boxShadow: (theme) => theme.shadows[0],
                            '&:hover': {
                              backgroundColor: (theme) => theme.palette.customColors?.disabledBg,
                              color: (theme) => theme.palette.customColors?.disabledColor,
                            },
                            ...StyleButton,
                          }}
                        >
                          {p.doc_name}
                        </Button>
                        <ActionMenuItem
                          handleActionClick={(type: string, file?: File) => {
                            if (type === 'delete') handleMenuTypeClick(p?.doc_id);
                            if (type === 'upload') !!file && handleUploadFile(file);
                          }}
                        />
                      </Stack>
                    ) : (
                      <DocumentFileUploadBtn
                        handleFileUpload={handleUploadFile}
                        fileName={p.doc_name}
                      />
                    )}
                  </>
                )}
              </Stack>
            </Grid>
          ))}
      </Grid>
      {openSnackbar.open && (
        <CustomSnackbar
          open={openSnackbar.open}
          severityType={openSnackbar.type}
          message={openSnackbar.message as string}
          header={openSnackbar.message_header}
          onClose={() => {
            setOpenSnackbar({ open: false, message: undefined, message_header: undefined });
          }}
        />
      )}
      {openLegalAgreement && (
        <LegalAgreementModal
          open={openLegalAgreement}
          onClose={() => {
            setOpenLegalAgreement(false);
          }}
          type="from_docLibrary"
        />
      )}
      {agreeCertifyMess?.open && (
        <CommonMessageModal
          onClose={() => setAgreeCertifyMess({ open: false })}
          open={agreeCertifyMess?.open}
          title={'Confirmation'}
          message={
            'I certify that the documents I have provided are accurate and correct to the best of my knowledge'
          }
          handleAction={uploadDocument}
        >
          I Agree
        </CommonMessageModal>
      )}
    </Page>
  );
};
export default DocumentLibraryPage;
