import { Container, Grid, Typography, Button } from '@mui/material';

interface OwnProps {
  handleNext: () => void;
  firstName: string;
  lastName: string;
  company: string;
  appName: string;
  shortName: string;
}
const InviteUserView = ({
  handleNext,
  firstName,
  lastName,
  company,
  appName,
  shortName,
}: OwnProps) => {
  return (
    <Container>
      <Grid container rowGap={3}>
        <Grid item>
          <Typography variant="h4" fontWeight={(theme) => theme.typography.fontWeightBold}>
            Hello, {firstName} {lastName},
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="body2"
            fontSize={'14px'}
            fontWeight={(theme) => theme.typography.fontWeightMedium}
          >
            We would like to Welcome {company} to {appName}.
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="body2"
            fontSize={'14px'}
            fontWeight={(theme) => theme.typography.fontWeightMedium}
          >
            This is a program that allows companies to get cash advances on their Receivables.{' '}
            {shortName} created this program to help small business owner improve their cashflow.
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="body2"
            fontSize={'14px'}
            fontWeight={(theme) => theme.typography.fontWeightMedium}
          >
            The Crowdz Administrator has invited you to the InvoiceExchange. Click the below to
            register.
          </Typography>
        </Grid>
        <Grid item sx={{ marginTop: 2 }} xs={12}>
          <Button type="submit" variant="contained" onClick={handleNext}>
            Next
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};
export default InviteUserView;
