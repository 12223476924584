// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import useResponsive from 'hooks/useResponsive';
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import NotificationsPopover from './NotificationsPopover';
import { useDispatch, useSelector } from 'redux/store';
import { useLocation, useNavigate } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import ExportTableDataModal from 'components/Models/ExportTableDataModal';
import useAuth from 'hooks/useAuth';
import { DASHBOARD_PAGES, SETTINGS_PAGES } from 'routes/paths';
import BulkReceivablesUpload from 'components/Models/BulkReceivablesUpload';
import { FileApi } from 'api';
import { alpha } from '@mui/material/styles';
import { CommonErrorModalProps } from 'redux/common/common.type';
import CustomSnackbar from 'components/CustomSnackbar';

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 64;

const HeaderMainDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  height: APPBAR_MOBILE,
  paddingLeft: '50px',
  paddingRight: '50px',
  [theme.breakpoints.up('md')]: {
    height: APPBAR_DESKTOP,
  },
  [theme.breakpoints.down('sm')]: {
    paddingTop: '92px',
  },
  [theme.breakpoints.down('lg')]: {
    height: APPBAR_DESKTOP,
    paddingLeft: '24px',
    paddingRight: '24px',
  },
  p: {
    color: `${alpha(theme.palette.text.primary, 0.6)}`,
    textTransform: 'capitalize',
    fontWeight: theme.typography.fontWeightMedium,
  },
  h6: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const StyledHeaderRightBox = styled(Box)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

// TODO clean
export default function DashboardHeader() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const location = useLocation();
  const { userData, isUserAllowedToAccess, role } = useAuth();
  const smup = useResponsive('down', 'sm');
  const isDesktop = useResponsive('up', 'md');
  const isMobiletab = useMediaQuery('(min-width: 600px)', { noSsr: true });
  const [openSnackbar, setOpenSnackbar] = useState<CommonErrorModalProps>({ open: false });
  const [openBulkUpload, setOpenBulkUpload] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleBulkUploadClose = () => {
    setOpenBulkUpload(false);
  };
  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!!e.target.files) {
      const file = e.target.files[0];
      if (!!file) {
        const formData = new FormData();
        formData.append('invoice_file', file);
        const action = await dispatch(FileApi.uploadBulkInvoiceFile(formData));
        if (FileApi.uploadBulkInvoiceFile.fulfilled.match(action)) {
          action?.payload?.success &&
            setOpenSnackbar({
              open: true,
              message: action.payload?.success?.message,
              type: 'success',
            });
        }
        if (FileApi.uploadBulkInvoiceFile.rejected.match(action)) {
          action?.payload?.error &&
            setOpenSnackbar({
              open: true,
              message: action.payload?.error?.message,
              type: 'error',
            });
          // TODO something else
        }
      }
    }
  };
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e: any) => {
    setAnchorEl(null);
  };
  const pathname = location.pathname.split('/')[2];
  //TODO why <button>Logo</button> general review remove Toolbar, clean
  return (
    <HeaderMainDiv>
      {isDesktop && (
        <Box
          sx={{
            maxWidth: '300px',
            width: '100%',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <Typography variant="body1">
            {userData?.company_details?.name}
            {/* {location.pathname.split('/')[2]} TODO why */}
          </Typography>
        </Box>
      )}

      <StyledHeaderRightBox
        style={{
          width: '100%',
          justifyContent: 'end',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <>
            {/* {isMobile && <Button variant="contained">Logo</Button>} */}
            {isDesktop && (
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: '17px',
                  minWidth: '180px',
                  paddingBottom: '6px',
                }}
              >
                Welcome , {userData?.user_details?.full_name}
              </Typography>
            )}
            {isUserAllowedToAccess &&
              role === 'seller' &&
              [DASHBOARD_PAGES.dashboard, DASHBOARD_PAGES.marketplace].includes(
                location.pathname
              ) && (
                <>
                  <IconButton onClick={handleClick} color="inherit">
                    <AddCircleIcon color="inherit" />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                      style: {
                        padding: 0,
                      },
                    }}
                    PaperProps={{
                      style: {
                        width: '25ch',
                        borderRadius: '10px',
                        padding: '0',
                        margin: '0',
                      },
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        navigate(SETTINGS_PAGES.accountingSystemCreateInvoice);
                      }}
                      sx={{
                        justifyContent: 'center',
                        fontSize: 14,
                        padding: '10px 15px',
                        '&:hover': {
                          backgroundColor: (theme) => theme.palette.primary.main,
                          color: (theme) => theme.palette.primary.contrastText,
                        },
                      }}
                    >
                      Create Receivables
                    </MenuItem>

                    <MenuItem
                      onClick={() => setOpenBulkUpload(true)}
                      sx={{
                        justifyContent: 'center',
                        fontSize: 14,
                        padding: '10px 15px',
                        '&:hover': {
                          backgroundColor: (theme) => theme.palette.primary.main,
                          color: (theme) => theme.palette.primary.contrastText,
                        },
                      }}
                    >
                      Upload Spreadsheet
                    </MenuItem>
                  </Menu>
                </>
              )}
            <NotificationsPopover />
            <Searchbar />

            <AccountPopover />
          </>
        </Stack>
      </StyledHeaderRightBox>
      {/* </Toolbar> */}
      {openBulkUpload && (
        <BulkReceivablesUpload
          open={openBulkUpload}
          onClose={handleBulkUploadClose}
          handleFileUpload={handleFileUpload}
        />
      )}
      {openSnackbar.open && (
        <CustomSnackbar
          open={openSnackbar.open}
          severityType={openSnackbar.type}
          message={openSnackbar.message as string}
          header={openSnackbar.message_header}
          onClose={() => {
            setOpenSnackbar({ open: false, message: undefined, message_header: undefined });
          }}
        />
      )}
    </HeaderMainDiv>
  );
}
