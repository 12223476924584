import {
  Button,
  Divider,
  Grid,
  Input,
  InputAdornment,
  Link,
  Paper,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import SettingsBreadCrumbs from 'components/breadCrumbs/SettingsBreadCrumbs';
import FormikTextField from 'components/formik/FormikTextField';
import { ErrorMessage, Field, Form, Formik, FormikProps, useField, useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'redux/store';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { SettingsApi } from 'api';
import useAuth from 'hooks/useAuth';
import Container from '@mui/material/Container';
import Box from '@mui/system/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { FinancialProfileData } from '../../redux/settings/settings.type';
import ConfirmationModal from 'components/Models/ConfirmationModal';
import { CommonErrorModalProps } from 'redux/common/common.type';
import CommonMessageModal from 'components/Models/CommonMessageModal';
import CustomSnackbar from 'components/CustomSnackbar';
import * as Yup from 'yup';
import InformationModal from 'components/Models/InformationModal';
import Page from 'components/Page';
import { getWarnings } from 'commonFunctions/commonFunctions';
import Notifications from 'components/Notifications';
import CustomTooltip from 'components/UI/CustomTooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { OnlyNum_VALIDATION } from 'utils/inputValidation';

interface AutProps {
  value?: number;
}

// TODO fix Nan
const ValidationSchema = Yup.object().shape({
  annual_revenue: Yup.number().required('This field is required.').min(1, 'Minimum Should be 1'),

  current_liabilities: Yup.number()
    .required('This field is required.')
    .min(0, 'Minimum Should be 0'),
  long_term_liabilities: Yup.number()
    .required('This field is required.')
    .min(0, 'Minimum Should be 0'),
  current_shareholders_equity: Yup.number()
    .required('This field is required.')
    .min(0, 'Minimum Should be 0'),
  highest_current_credit_line: Yup.number()
    .required('This field is required.')
    .min(0, 'Minimum Should be 0'),
  ebitda: Yup.number().required('This field is required.').min(0, 'Minimum Should be 0'),
});

const AutoSubmitToken = ({ value }: AutProps) => {
  // Grab values and submitForm from context
  const { values, setFieldValue } = useFormikContext();
  useEffect(() => {
    if (!!value) setFieldValue('ebitda', value);
  }, [value, values, setFieldValue]);
  return null;
};
const FinancialProfilePage = () => {
  const [openEbitda, setOpenEbitda] = useState<boolean>(false);
  const [basicInfoNotification, setBasicInfoNotification] = useState(true);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const breadCrumbs = { name: 'Settings/Financial profile', path: '#' };
  const [openSnackbar, setOpenSnackbar] = useState<CommonErrorModalProps>({ open: false });
  const { financialProfileData, isLoading } = useSelector((state) => state.settings);
  const [preFilledData, setPreFilledData] = useState<any>();
  const [openContactSupportModal, setContactSupport] = useState(false);
  const [init, setInit] = useState<number>();

  const {
    currency,
    isUserAllowedToAccess,
    registrationStepsData,
    tenantDetailData,
    userData,
    initUserInfo,
  } = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(SettingsApi.getFinancialProfile());
    if (financialProfileData) {
      if (financialProfileData?.financial_prefill_data?.length > 0) {
        setPreFilledData(financialProfileData?.financial_prefill_data);
      } else {
        setPreFilledData(financialProfileData);
      }
    }
  }, []);

  const contactSupport = () => {
    setContactSupport(true);
  };

  const updateFinancialProfile = async (values: any) => {
    const action = await dispatch(SettingsApi.updateFinancialProfile(JSON.stringify(values)));
    if (SettingsApi.updateFinancialProfile.fulfilled.match(action)) {
      initUserInfo();
      setOpenSnackbar({ open: true, message: action?.payload?.success?.message, type: 'success' });

      //TODO Show success message
    }
    if (SettingsApi.updateFinancialProfile.rejected.match(action)) {
      // TODO show error
      action.payload?.error &&
        setOpenSnackbar({ open: true, message: action?.payload?.error?.message, type: 'error' });
    }
  };
  const checkCalculationFields = (values: any) => {
    if (!values?.all_expenses || !values?.interest_expense || !values?.taxes_paid) {
      return true;
    }
    if (
      !/\.0+$|(\.[0-9]*[1-9])0+$/.test(values?.all_expenses) ||
      !/\.0+$|(\.[0-9]*[1-9])0+$/.test(values?.interest_expense) ||
      !/\.0+$|(\.[0-9]*[1-9])0+$/.test(values?.taxes_paid)
    ) {
      return true;
    }
    return false;
  };

  const handleCalculate = async (values: FinancialProfileData) => {
    const payload = {
      all_revenue: values.all_revenue,
      all_expenses: values.all_expenses,
      interest_expense: values.interest_expense,
      taxes_paid: values.taxes_paid,
      depreciation_taken: values.depreciation_taken,
      amortization: values.amortization,
    };
    const action = await dispatch(SettingsApi.calculateEDITDA(payload));
    if (SettingsApi.calculateEDITDA.fulfilled.match(action)) {
      //console.log('>>>>>', action.payload);
    }
    if (SettingsApi.calculateEDITDA.rejected.match(action)) {
      // TODO show error
      // console.log(action.payload);
      action.payload?.error &&
        setOpenSnackbar({ open: true, message: action?.payload?.error?.message, type: 'error' });
    }
  };

  if (isLoading) {
    return (
      <Container sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Page title="Financial profile">
      <ConfirmationModal
        message="Are You Sure You want to save and finish later"
        onClose={() => {
          setOpenConfirm(false);
        }}
        open={openConfirm}
        title=""
        //  isHideActions?: boolean;
        // children={<Button onClick={() => {}}> Yes</Button>}
        agreeButtonTitle="Yes"
        cancelButtonTitle="Continue"
      />
      <Stack mb={4}>
        <SettingsBreadCrumbs data={breadCrumbs} />
      </Stack>
      {basicInfoNotification && (
        <Notifications
          type={'information'}
          title={'Note'}
          message={'Applying with our service will not affect your credit score.'}
          handleCloseNotification={() => setBasicInfoNotification(false)}
        />
      )}
      {tenantDetailData?.accounting_system_mandatory &&
        userData?.is_financial_profile_completed === 'pending' && (
          <Notifications
            type={'information'}
            message={
              'Your financial profile is being auto-populated and it will take a few minutes before its information appears on your account. We’ll send an email to confirm when it’s completed.'
            }
          />
        )}

      {tenantDetailData?.accounting_system_mandatory &&
        userData?.is_financial_profile_completed === 'success' && (
          <Notifications
            type={'information'}
            message={
              'Your financial profile has been auto populated from your accounting platform. For any queries, please reach out to Crowdz Customer Support.'
            }
          />
        )}

      <Formik
        initialValues={financialProfileData || {}}
        validationSchema={ValidationSchema}
        enableReinitialize={true}
        onSubmit={(values) => {
          updateFinancialProfile(values);
        }}
      >
        {(formProps) => (
          <Form>
            <Grid container columnSpacing={4} rowGap={4}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Field
                  component={TextField}
                  variant="standard"
                  fullWidth
                  value={formProps?.values?.annual_revenue}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const reg = new RegExp(OnlyNum_VALIDATION);
                    reg.test(e.target.value)
                      ? formProps?.setFieldValue('annual_revenue', e.target.value)
                      : formProps?.setFieldValue('annual_revenue', '');
                  }}
                  label={
                    <CustomTooltip
                      title={
                        '"Annual revenue is the total amount of money made by sales or services in a given year before costs or expenses are taken out. Listed as Sales Revenue on your income statement (P&L)"'
                      }
                    >
                      <Typography variant="inherit" sx={{ display: 'flex' }}>
                        <Typography variant="body2"> Annual Revenue For Last Full Year*</Typography>{' '}
                        <InfoOutlinedIcon sx={{ marginLeft: '10px', fontSize: '20px' }} />
                      </Typography>
                    </CustomTooltip>
                  }
                  placeholder="00.00"
                  currency={currency}
                  name={`annual_revenue`}
                  fontSize={'14px'}
                  disabled={!isUserAllowedToAccess}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Divider sx={{ height: 28, m: 1 }} orientation="vertical" />
                        <Typography>{userData?.user_details?.currency}</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
                <ErrorMessage
                  name="annual_revenue"
                  component="div"
                  render={(msg) => (
                    <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                      {msg}
                    </Typography>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Field
                  component={TextField}
                  variant="standard"
                  fullWidth
                  value={formProps?.values?.current_liabilities}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const reg = new RegExp(OnlyNum_VALIDATION);
                    reg.test(e.target.value)
                      ? formProps?.setFieldValue('current_liabilities', e.target.value)
                      : formProps?.setFieldValue('current_liabilities', '');
                  }}
                  placeholder="00.00"
                  currency={currency}
                  label={
                    <CustomTooltip
                      title={
                        "Current liabilities are a company's short-term financial obligations that are due within one year or within a normal operating cycle. Current liabilities are listed on the balance sheet under the liabilities section."
                      }
                    >
                      <Typography variant="inherit" sx={{ display: 'flex' }}>
                        <Typography variant="body2"> Current Liabilities*</Typography>{' '}
                        <InfoOutlinedIcon sx={{ marginLeft: '10px', fontSize: '20px' }} />
                      </Typography>
                    </CustomTooltip>
                  }
                  name={`current_liabilities`}
                  fontSize={'14px'}
                  disabled={!isUserAllowedToAccess}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Divider sx={{ height: 28, m: 1 }} orientation="vertical" />
                        <Typography>{userData?.user_details?.currency}</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
                <ErrorMessage
                  name="current_liabilities"
                  component="div"
                  render={(msg) => (
                    <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                      {msg}
                    </Typography>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Field
                  component={TextField}
                  variant="standard"
                  fullWidth
                  value={formProps?.values?.long_term_liabilities}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const reg = new RegExp(OnlyNum_VALIDATION);
                    reg.test(e.target.value)
                      ? formProps?.setFieldValue('long_term_liabilities', e.target.value)
                      : formProps?.setFieldValue('long_term_liabilities', '');
                  }}
                  placeholder="00.00"
                  currency={currency}
                  InputLabelProps={{ shrink: true }}
                  label={
                    <CustomTooltip
                      title={
                        'Long-term liabilities are liabilities, like long-term loans, bonds, leases, and the like, that are due more than a year in the future.'
                      }
                    >
                      <Typography variant="inherit" sx={{ display: 'flex' }}>
                        <Typography variant="body2"> Long term Liabilities*</Typography>{' '}
                        <InfoOutlinedIcon sx={{ marginLeft: '10px', fontSize: '20px' }} />
                      </Typography>
                    </CustomTooltip>
                  }
                  name={`long_term_liabilities`}
                  fontSize={'14px'}
                  disabled={!isUserAllowedToAccess}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Divider sx={{ height: 28, m: 1 }} orientation="vertical" />
                        <Typography>{userData?.user_details?.currency}</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
                <ErrorMessage
                  name="long_term_liabilities"
                  component="div"
                  render={(msg) => (
                    <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                      {msg}
                    </Typography>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Field
                  component={TextField}
                  variant="standard"
                  fullWidth
                  value={formProps?.values?.current_shareholders_equity}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const reg = new RegExp(OnlyNum_VALIDATION);
                    reg.test(e.target.value)
                      ? formProps?.setFieldValue('current_shareholders_equity', e.target.value)
                      : formProps?.setFieldValue('current_shareholders_equity', '');
                  }}
                  placeholder="00.00"
                  currency={currency}
                  disabled={!isUserAllowedToAccess}
                  InputLabelProps={{ shrink: true }}
                  label={
                    <CustomTooltip
                      title={
                        "Shareholder's Equity is the remaining amount of assets available to shareholders after the debts and other liabilities have been paid. The stockholders equity subtotal is located in the bottom half of the balance sheet."
                      }
                    >
                      <Typography variant="inherit" sx={{ display: 'flex' }}>
                        <Typography variant="body2"> Current Shareholder Equity*</Typography>{' '}
                        <InfoOutlinedIcon sx={{ marginLeft: '10px', fontSize: '20px' }} />
                      </Typography>
                    </CustomTooltip>
                  }
                  name={`current_shareholders_equity`}
                  fontSize={'14px'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Divider sx={{ height: 28, m: 1 }} orientation="vertical" />
                        <Typography>{userData?.user_details?.currency}</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
                <ErrorMessage
                  name="current_shareholders_equity"
                  component="div"
                  render={(msg) => (
                    <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                      {msg}
                    </Typography>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Field
                  component={TextField}
                  variant="standard"
                  fullWidth
                  value={formProps?.values?.highest_current_credit_line}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const reg = new RegExp(OnlyNum_VALIDATION);
                    reg.test(e.target.value)
                      ? formProps?.setFieldValue('highest_current_credit_line', e.target.value)
                      : formProps?.setFieldValue('highest_current_credit_line', '');
                  }}
                  placeholder="00.00"
                  currency={currency}
                  disabled={!isUserAllowedToAccess}
                  InputLabelProps={{ shrink: true }}
                  label={
                    <CustomTooltip
                      title={
                        'Highest Credit Line is your line of credit, loan, credit card, etc.. with the highest credit line.'
                      }
                    >
                      <Typography variant="inherit" sx={{ display: 'flex' }}>
                        <Typography variant="body2"> Highest Credit Line*</Typography>{' '}
                        <InfoOutlinedIcon sx={{ marginLeft: '10px', fontSize: '20px' }} />
                      </Typography>
                    </CustomTooltip>
                  }
                  name={`highest_current_credit_line`}
                  fontSize={'14px'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Divider sx={{ height: 28, m: 1 }} orientation="vertical" />
                        <Typography>{userData?.user_details?.currency}</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
                <ErrorMessage
                  name="highest_current_credit_line"
                  component="div"
                  render={(msg) => (
                    <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                      {msg}
                    </Typography>
                  )}
                />
                <AutoSubmitToken value={init} />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Stack direction="row">
                  <Field
                    component={TextField}
                    variant="standard"
                    fullWidth
                    value={formProps?.values?.ebitda}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      const reg = new RegExp(OnlyNum_VALIDATION);
                      reg.test(e.target.value)
                        ? formProps?.setFieldValue('ebitda', e.target.value)
                        : formProps?.setFieldValue('ebitda', '');
                    }}
                    placeholder="00.00"
                    currency={currency}
                    disabled={!isUserAllowedToAccess}
                    InputLabelProps={{ shrink: true }}
                    label="EBITDA*"
                    name={`ebitda`}
                    fontSize={'14px'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Divider sx={{ height: 28, m: 1 }} orientation="vertical" />
                          <Typography>{userData?.user_details?.currency}</Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Link
                    sx={{
                      textDecoration: 'none',
                      fontSize: '10px',
                      color: 'inherit',
                      cursor: 'pointer',
                      minWidth: '80px',
                    }}
                    onClick={() => {
                      setOpenEbitda(true);
                    }}
                  >
                    {`Don't have this?`}
                  </Link>
                  <ErrorMessage
                    name="ebitda"
                    component="div"
                    render={(msg) => (
                      <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                        {msg}
                      </Typography>
                    )}
                  />
                </Stack>
              </Grid>
            </Grid>

            {openEbitda && (
              <Stack sx={{ marginTop: 4 }}>
                <Typography variant="h6" sx={{ fontSize: '13px', marginBottom: 2 }}>
                  FILL IN THE FOLLOWING INFORMATION AND WE WILL CALCULATE YOUR EBITDA FOR YOU
                </Typography>

                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Field
                      component={TextField}
                      variant="standard"
                      fullWidth
                      value={formProps?.values?.all_revenue}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        const reg = new RegExp(OnlyNum_VALIDATION);
                        reg.test(e.target.value)
                          ? formProps?.setFieldValue('all_revenue', e.target.value)
                          : formProps?.setFieldValue('all_revenue', '');
                      }}
                      label="All Revenue For Last Full Year"
                      placeholder="00.00"
                      currency={currency}
                      name={`all_revenue`}
                      fontSize={'14px'}
                      disabled={!isUserAllowedToAccess || preFilledData?.annual_revenue}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Divider sx={{ height: 28, m: 1 }} orientation="vertical" />
                            <Typography>{currency}</Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <ErrorMessage
                      name="all_revenue"
                      component="div"
                      render={(msg) => (
                        <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                          {msg}
                        </Typography>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Field
                      component={TextField}
                      variant="standard"
                      fullWidth
                      value={formProps?.values?.all_expenses}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        const reg = new RegExp(OnlyNum_VALIDATION);
                        reg.test(e.target.value)
                          ? formProps?.setFieldValue('all_expenses', e.target.value)
                          : formProps?.setFieldValue('all_expenses', '');
                      }}
                      label="All Expenses For Last Full Year"
                      placeholder="00.00"
                      currency={currency}
                      name={`all_expenses`}
                      fontSize={'14px'}
                      disabled={!isUserAllowedToAccess}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Divider sx={{ height: 28, m: 1 }} orientation="vertical" />
                            <Typography>{currency}</Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <ErrorMessage
                      name="all_expenses"
                      component="div"
                      render={(msg) => (
                        <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                          {msg}
                        </Typography>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Field
                      component={TextField}
                      variant="standard"
                      fullWidth
                      value={formProps?.values?.interest_expense}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        const reg = new RegExp(OnlyNum_VALIDATION);
                        reg.test(e.target.value)
                          ? formProps?.setFieldValue('interest_expense', e.target.value)
                          : formProps?.setFieldValue('interest_expense', '');
                      }}
                      label="Interest expanse For Last Full Year"
                      placeholder="00.00"
                      currency={currency}
                      name={`interest_expense`}
                      fontSize={'14px'}
                      disabled={!isUserAllowedToAccess}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Divider sx={{ height: 28, m: 1 }} orientation="vertical" />
                            <Typography>{currency}</Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <ErrorMessage
                      name="interest_expense"
                      component="div"
                      render={(msg) => (
                        <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                          {msg}
                        </Typography>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Field
                      component={TextField}
                      variant="standard"
                      fullWidth
                      value={formProps?.values?.taxes_paid}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        const reg = new RegExp(OnlyNum_VALIDATION);
                        reg.test(e.target.value)
                          ? formProps?.setFieldValue('taxes_paid', e.target.value)
                          : formProps?.setFieldValue('taxes_paid', '');
                      }}
                      label="Tax Paid For Last Full Year"
                      placeholder="00.00"
                      currency={currency}
                      name={`taxes_paid`}
                      fontSize={'14px'}
                      disabled={!isUserAllowedToAccess}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Divider sx={{ height: 28, m: 1 }} orientation="vertical" />
                            <Typography>{currency}</Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <ErrorMessage
                      name="taxes_paid"
                      component="div"
                      render={(msg) => (
                        <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                          {msg}
                        </Typography>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Field
                      component={TextField}
                      variant="standard"
                      fullWidth
                      value={formProps?.values?.depreciation_taken}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        const reg = new RegExp(OnlyNum_VALIDATION);
                        reg.test(e.target.value)
                          ? formProps?.setFieldValue('depreciation_taken', e.target.value)
                          : formProps?.setFieldValue('depreciation_taken', '');
                      }}
                      label="Depreciation taken For Last Full Year"
                      placeholder="00.00"
                      currency={currency}
                      name={`depreciation_taken`}
                      fontSize={'14px'}
                      disabled={!isUserAllowedToAccess}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Divider sx={{ height: 28, m: 1 }} orientation="vertical" />
                            <Typography>{currency}</Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <ErrorMessage
                      name="depreciation_taken"
                      component="div"
                      render={(msg) => (
                        <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                          {msg}
                        </Typography>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Field
                      component={TextField}
                      variant="standard"
                      fullWidth
                      value={formProps?.values?.amortization}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        const reg = new RegExp(OnlyNum_VALIDATION);
                        reg.test(e.target.value)
                          ? formProps?.setFieldValue('amortization', e.target.value)
                          : formProps?.setFieldValue('amortization', '');
                      }}
                      label="Amortization For Last Full Year"
                      placeholder="00.00"
                      currency={currency}
                      name={`amortization`}
                      fontSize={'14px'}
                      disabled={!isUserAllowedToAccess}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Divider sx={{ height: 28, m: 1 }} orientation="vertical" />
                            <Typography>{currency}</Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <ErrorMessage
                      name="amortization"
                      component="div"
                      render={(msg) => (
                        <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                          {msg}
                        </Typography>
                      )}
                    />
                  </Grid>
                </Grid>
              </Stack>
            )}
            <Stack direction={'row'} justifyContent="flex-end">
              {openEbitda && (
                <Button
                  variant="contained"
                  // type="submit"
                  onClick={() => {
                    handleCalculate(formProps.values);
                  }}
                  sx={{ marginTop: '50px' }}
                  disabled={
                    formProps?.values?.all_expenses &&
                    formProps?.values?.interest_expense &&
                    formProps?.values?.taxes_paid
                      ? false
                      : true
                  }
                >
                  Calculate
                </Button>
              )}
              {!tenantDetailData?.accounting_system_mandatory && (
                <Button
                  variant="contained"
                  type="submit"
                  sx={{ marginTop: '50px', marginRight: 1 }}
                >
                  update
                </Button>
              )}

              {registrationStepsData?.filter((f) => f.step_number === 11 && f.is_completed)
                .length === 0 &&
                !tenantDetailData?.accounting_system_mandatory && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      setOpenConfirm(true);
                    }}
                    sx={{ marginTop: '50px', marginRight: 1 }}
                  >
                    Save and finish later
                  </Button>
                )}
              {tenantDetailData?.accounting_system_mandatory && (
                <Button onClick={contactSupport}>Contact Customer Support</Button>
              )}
            </Stack>
          </Form>
        )}
      </Formik>
      {openSnackbar.open && (
        <CustomSnackbar
          open={openSnackbar.open}
          severityType={openSnackbar.type}
          message={openSnackbar.message as string}
          header={openSnackbar.message_header}
          onClose={() => {
            setOpenSnackbar({ open: false, message: undefined, message_header: undefined });
          }}
        />
      )}

      {openContactSupportModal && (
        <InformationModal open={openContactSupportModal} onClose={() => setContactSupport(false)}>
          Please contact <br /> {tenantDetailData?.support_email}
        </InformationModal>
      )}
    </Page>
  );
};
export default FinancialProfilePage;
