import { useEffect, useState } from 'react';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import { useDispatch, useSelector } from 'redux/store';
import { DASHBOARD_PAGES, SETTINGS_PAGES } from 'routes/paths';
import Popover from '@mui/material/Popover';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircleIcon from '@mui/icons-material/Circle';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import { AuctionApi, InvoiceApi, UserNotificationApi } from 'api';
import {
  ExtraData,
  NotificationResultData,
  UserNotificationData,
} from 'redux/userNotification/userNotification.type';
import useAuth from 'hooks/useAuth';
import ConfirmationModal from 'components/Models/ConfirmationModal';
import { useNavigate } from 'react-router-dom';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { CommonErrorModalProps } from 'redux/common/common.type';
import CustomSnackbar from 'components/CustomSnackbar';

const StyledPopHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '15px 15px 10px 15px',
  fontSize: '12px',
}));
const StyledDescrip = styled('div')(({ theme }) => ({
  paddingLeft: '1em',
  fontSize: '12px',
}));

interface RedirectProps {
  url: string;
  state?: any;
}
export default function NotificationsPopover() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [data, setData] = useState<NotificationResultData[]>([]);
  const [openConfirmationModal, setOpenConfirmationModal] = useState({
    open: false,
    message: '',
    agreeButtonTitle: '',
    cancelButtonTitle: '',
    title: '',
    data: {} as Partial<NotificationResultData>,
  });
  const { userNotifications, isLoading } = useSelector((state) => state.userNotification);
  const [openBulkUploadErrorPop, setBulkErrorPop] = useState<any>();
  const [openSnackbar, setOpenSnackbar] = useState<CommonErrorModalProps>({ open: false });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const { role, userData, tenantDetailData } = useAuth();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const [offset, setOffset] = useState<number>(0);

  useEffect(() => {
    dispatch(UserNotificationApi.getNotification(`?limit=5&offset=${offset}`));
  }, []);

  const getNotificationList = () => {
    const n = offset === 0 ? 5 : 10;
    dispatch(UserNotificationApi.getNotification(`?limit=10&offset=${n + offset}`));
    setOffset(n + offset);
  };

  const markAllAsRead = () => {
    setData([]);
    setAnchorEl(null);
    setOffset(0);
    dispatch(UserNotificationApi.editAllNotifications());
    dispatch(
      UserNotificationApi.getNotification(`?limit=${offset === 0 ? 5 : 10}&offset=${offset}`)
    );
  };

  const getLink = (v: ExtraData): RedirectProps | undefined => {
    if (!!v.redirect_url) {
      if (
        v.redirect_url === 'invoicebulkuploadaudit' ||
        v.redirect_url === 'invoice' ||
        v.redirect_url === 'dashboard'
      ) {
        return {
          url: DASHBOARD_PAGES.marketplace,
          state: {
            searchText: v.q,
            redirect_url: 'Marketplace',
            invoiceType: v.receivable_type,
          },
        };
      } else if (v.redirect_url === 'financialaccounts') {
        return {
          url: SETTINGS_PAGES.bankingInformation,
          state: { redirect_url: 'Bank Information' },
        };
      } else if (v.redirect_url === 'history') {
        return {
          url: DASHBOARD_PAGES.history,
          state: { searchText: v.q, redirect_url: 'History', invoiceType: v.receivable_type },
        };
      } else if (v.redirect_url === 'repayment') {
        return {
          url: DASHBOARD_PAGES.repayment,
          state: { searchText: v.q, redirect_url: 'Repayment', invoiceType: v.receivable_type },
        };
      } else if (
        v.redirect_url === 'settings' ||
        v.redirect_url === 'profile' ||
        v.redirect_url === 'bank_account'
      ) {
        return {
          url: SETTINGS_PAGES.companyProfile,
          state: { redirect_url: 'Company Profile' },
        };
      }
    }
  };

  const getBadgeNumber = () => {
    if (!!userNotifications) {
      if (userNotifications.count > 0)
        return userNotifications.count > 100 ? '100+' : `${userNotifications.count}`;
    }
    return 0;
  };

  const handleUpdate = (id: number) => {
    setAnchorEl(null);
    setOffset(0);
    dispatch(UserNotificationApi.editNotificationById({ id: id, type: 'edit' }));
    dispatch(
      UserNotificationApi.getNotification(`?limit=${offset === 0 ? 5 : 10}&offset=${offset}`)
    );
  };

  const handleBulkUploadError = (value: string) => {
    if (value === 'yes') {
      downloadErrorReport();
      setBulkErrorPop({ open: false });
    } else {
      setBulkErrorPop({ open: false });
    }
  };

  const downloadErrorReport = async () => {
    let action = await dispatch(
      InvoiceApi.downloadMassUploadErrorReport(openBulkUploadErrorPop?.data?.action_id)
    );
    if (InvoiceApi.downloadMassUploadErrorReport.fulfilled.match(action)) {
      const a = document.createElement('a') as HTMLAnchorElement;
      a.href = window.URL.createObjectURL(action?.payload);
      a.setAttribute('download', 'download_Error_Report.pdf');
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
    if (InvoiceApi.downloadMassUploadErrorReport.rejected.match(action)) {
      setOpenSnackbar({
        open: true,
        message: action?.payload?.error?.message,
        type: 'error',
      });
    }
  };

  const updatePaymentOrFunds = async (notification: Partial<NotificationResultData>) => {
    const payload = {
      auction_id: notification?.action_id,
      user_type: role,
    };

    if (notification?.verb?.toLowerCase() === 'confirm repayment') {
      const action = await dispatch(InvoiceApi.invoiceRepaymentComplete(payload));
      if (InvoiceApi.invoiceRepaymentComplete.fulfilled.match(action)) {
        setData([]);
        handleUpdate(notification?.id || 0);
      }
      if (InvoiceApi.invoiceRepaymentComplete.rejected.match(action)) {
        setOpenSnackbar({
          open: true,
          message: action.payload?.error?.message,
          message_header: action.payload?.error?.message_header,
          type: 'error',
        });
      }
    }
    if (notification?.verb?.toLowerCase() === 'confirm funding') {
      const action = await dispatch(AuctionApi.completeFunding(payload));
      if (AuctionApi.completeFunding.fulfilled.match(action)) {
        handleUpdate(notification?.id || 0);
      }
      if (AuctionApi.completeFunding.rejected.match(action)) {
        setOpenSnackbar({
          open: true,
          message: action.payload?.error?.message,
          message_header: action.payload?.error?.message_header,
          type: 'error',
        });
      }
    }
  };

  const handleOpenConfirmationModal = (notification: NotificationResultData) => {
    let m = '';
    if (role === 'funder' && notification.verb?.toLowerCase() === 'confirm repayment') {
      m = 'Yes, I have funded it';
    } else if (role === 'seller') {
      m =
        notification.verb?.toLowerCase() === 'confirm repayment'
          ? 'Yes, I have repaid it'
          : 'Yes, I have received it';
    }
    setOpenConfirmationModal({
      open: true,
      message: `Please contact ${tenantDetailData?.app_name} at ${tenantDetailData?.support_email}`,
      agreeButtonTitle: m,
      title: notification?.description,
      cancelButtonTitle: 'No',
      data: notification,
    });
  };

  const handleClose = (notification: NotificationResultData) => {
    if (
      notification.verb?.toLowerCase() === 'confirm repayment' ||
      notification.verb?.toLowerCase() === 'confirm funding'
    ) {
      handleOpenConfirmationModal(notification);
    } else {
      setAnchorEl(null);
      handleUpdate(notification?.id);
      if (!!notification?.extra_data?.redirect_url) {
        const v = getLink(notification.extra_data);
        if (v && v.url) navigate(v.url, { state: v?.state });
      }
    }
  };

  useEffect(() => {
    if (!!userNotifications?.results && Array.isArray(userNotifications?.results)) {
      setData([...userNotifications.results, ...data]);
    }
  }, [userNotifications]);

  return (
    <div style={{ marginRight: '8px' }}>
      {openConfirmationModal.open && (
        <ConfirmationModal
          open={openConfirmationModal.open}
          onClose={(text) => {
            if (text === 'yes') {
              updatePaymentOrFunds(openConfirmationModal.data);
              setAnchorEl(null);
            }
            setOpenConfirmationModal({
              message: '',
              open: false,
              agreeButtonTitle: '',
              title: '',
              cancelButtonTitle: '',
              data: {},
            });
          }}
          message={openConfirmationModal.message}
          agreeButtonTitle={openConfirmationModal.agreeButtonTitle}
          title={openConfirmationModal.title}
          cancelButtonTitle={openConfirmationModal.cancelButtonTitle}
        />
      )}
      <Badge
        badgeContent={getBadgeNumber()}
        sx={{ cursor: 'pointer' }}
        color="error"
        onClick={handleClick}
      >
        <NotificationsActiveOutlinedIcon
          sx={{
            fontSize: '28px',
            ml: 0.2,
          }}
        />
      </Badge>
      <>
        {!!userNotifications && Array.isArray(userNotifications.results) && (
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={() => {
              setAnchorEl(null);
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            sx={{ borderRadius: '10px' }}
          >
            {isLoading && (
              <Container
                sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}
              >
                <Box sx={{ top: '50px', position: 'absolute', zIndex: 2000 }}>
                  <CircularProgress color="primary" size={40} thickness={4} />
                </Box>
              </Container>
            )}
            <StyledPopHeader>
              <Typography
                sx={{ marginRight: 1 }}
                fontSize={'12px'}
                fontWeight={(theme) => theme.typography.fontWeightBold}
              >
                Notifications
              </Typography>
              {userNotifications.count > 0 && (
                <Link
                  underline="none"
                  sx={{
                    color: (theme) => theme.palette.error.light,
                    fontWeight: (theme) => theme.typography.fontWeightMedium,
                    cursor: 'pointer',
                  }}
                  onClick={markAllAsRead}
                >
                  Mark all as read{' '}
                  {userNotifications.count > 100 ? '100+' : userNotifications.count}
                </Link>
              )}
            </StyledPopHeader>
            <Divider sx={{ width: '100%' }} />
            <Grid container sx={{ maxWidth: '250px', padding: 2 }}>
              {data?.map((notification, index) => {
                return (
                  <Grid
                    item
                    key={index}
                    onClick={() => handleClose(notification)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <CircleIcon
                        sx={{
                          width: '10px',
                          height: '10px',
                          marginRight: '3px',
                          color: (theme) => theme.palette.primary.main,
                        }}
                      />
                      <Typography
                        variant="body1"
                        fontSize="12px"
                        fontWeight={(theme) => theme.typography.fontWeightBold}
                      >
                        {notification.verb}
                      </Typography>
                    </div>
                    <StyledDescrip>
                      <Box
                        className="description"
                        sx={{
                          a: {
                            color: (theme) => theme.palette.primary.main,
                          },
                        }}
                        dangerouslySetInnerHTML={{ __html: notification.description }}
                        onClick={() => getLink(notification?.extra_data)}
                      ></Box>
                    </StyledDescrip>
                    {notification.extra_data?.error_data &&
                      notification?.extra_data?.error_data.length > 0 && (
                        <>
                          <Typography variant="body1">
                            Some invoices are not uploaded due to error.
                            <Link
                              onClick={() => {
                                setBulkErrorPop({ open: true, data: notification });
                              }}
                            >
                              Click here
                            </Link>
                            to view errors.
                          </Typography>
                          <Typography variant="body1">{notification.time_since}</Typography>
                        </>
                      )}
                    {index < userNotifications.results.length - 1 && (
                      <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
                    )}
                  </Grid>
                );
              })}
              {offset < userNotifications.total_count && (
                <>
                  <Divider sx={{ width: '100%' }} />
                  <Grid container sx={{ marginTop: 2, marginBottom: 2, minWidth: '250px' }}>
                    <Grid item sx={{ alignItems: 'center', justifyContent: 'center' }}>
                      <Link
                        underline="none"
                        sx={{
                          fontSize: '14px',
                          cursor: 'pointer',
                          // fontWeight: (theme) => theme.typography.fontWeightMedium,
                        }}
                        onClick={getNotificationList}
                      >
                        View More Notifications ({userNotifications?.total_count - (offset + 5)})
                      </Link>
                    </Grid>
                  </Grid>
                </>
              )}
              {userNotifications.total_count === 0 && <Typography>No notifications</Typography>}
            </Grid>
          </Popover>
        )}
        {openBulkUploadErrorPop?.open && (
          <ConfirmationModal
            onClose={handleBulkUploadError}
            open={openBulkUploadErrorPop.open}
            title={`${tenantDetailData?.receivable_ui_config?.Invoice}(s) has/have not uploaded due to following errors in below${tenantDetailData?.receivable_ui_config?.invoice}, Please correct them first and try again.`}
            agreeButtonTitle={'Download Error Report'}
            cancelButtonTitle={'Got it'}
          >
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      <Typography variant="caption" gutterBottom>
                        {tenantDetailData?.receivable_ui_config?.Invoice} ID
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ width: '300px' }}>
                      <Typography variant="caption" gutterBottom>
                        CSV Row
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ width: '200px' }}>
                      <Typography variant="caption" gutterBottom>
                        Error
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="left" component="th" scope="row">
                      <Typography variant="caption" display="block" gutterBottom>
                        {openBulkUploadErrorPop?.data?.invoice_number}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" component="th" scope="row">
                      <Typography variant="caption" display="block" gutterBottom>
                        {openBulkUploadErrorPop?.data?.row_number}
                      </Typography>
                    </TableCell>
                    {openBulkUploadErrorPop?.data?.errors?.map((error: any, index: any) => (
                      <TableCell align="left" component="th" scope="row" key={index}>
                        <Typography variant="caption" display="block" gutterBottom>
                          {error}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </ConfirmationModal>
        )}
        {openSnackbar?.open && (
          <CustomSnackbar
            open={openSnackbar?.open}
            severityType={openSnackbar?.type}
            message={openSnackbar?.message as string}
            header={openSnackbar?.message_header}
            onClose={() => {
              setOpenSnackbar({ open: false, message: undefined, message_header: undefined });
            }}
          />
        )}
      </>
    </div>
  );
}
