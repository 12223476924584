import './App.css';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useTheme } from '@mui/material/styles';
import { ThemeProvider, ThemeColorPresets, RtlLayout } from './theme';
import Router from './routes';
import MotionLazyContainer from 'components/MotionLazyContainer';
import { I18nextProvider } from 'react-i18next';
import { useDispatch } from 'redux/store';
import { LoginApi, ConfigApi } from 'api';
import { useEffect, Suspense } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import i18n from './translations/i18n';
import useAuth from 'hooks/useAuth';
import { useLocation } from 'react-router-dom';
import { setUserToken } from 'redux/slices/login';
import { CrowdzFavicon } from 'assets';
import { useFavicon } from 'react-use';

const fav = {
  'Avalon Core': CrowdzFavicon,
};
// TODO
function App() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { tenantDetailData, userData, registrationStepsData, isAuthenticated, userToken } =
    useAuth();
  const locale = userData?.user_details.application_language || 'en';
  useFavicon(CrowdzFavicon);

  useEffect(() => {
    dispatch(ConfigApi.getTenantConfig());
    dispatch(LoginApi.getTenantDetail());
    // if (isAuthenticated) {
    //   dispatch(LoginApi.getRegistrationSteps());
    //   dispatch(LoginApi.getUserDetails());
    // }
  }, []);

  const initial = 'initial'; //No loading exception, if not loaded yet
  console.log();
  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <RtlLayout>
          {/* <NotistackProvider> */}
          <MotionLazyContainer>
            {/* <ProgressBarStyle /> */}
            {/* <ChartStyle /> */}
            {/* <Settings /> */}
            {/* <ScrollToTop /> */}
            <Suspense fallback={<div> ..... </div>}>
              <I18nextProvider i18n={i18n}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <GoogleReCaptchaProvider
                    reCaptchaKey={tenantDetailData?.captcha_public_key_v3 || initial}
                    language={locale}
                    // useRecaptchaNet=
                    // useEnterprise=
                    scriptProps={{
                      async: false,
                      defer: false,
                      // appendTo: 'head',
                      nonce: undefined,
                    }}
                    container={{
                      // optional to render inside custom element
                      // element: '[required_id_or_htmlelement]',
                      parameters: {
                        // badge: '[inline|bottomright|bottomleft]', // optional, default undefined
                        theme: theme.palette.mode,
                      },
                    }}
                  >
                    {/* <SettingsProvider> */}
                    {/* <CollapseDrawerProvider> */}
                    <Router />
                    {/* </CollapseDrawerProvider> */}
                    {/* </SettingsProvider> */}
                  </GoogleReCaptchaProvider>
                </LocalizationProvider>
              </I18nextProvider>
            </Suspense>
          </MotionLazyContainer>
          {/* </NotistackProvider> */}
        </RtlLayout>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}

export default App;
