import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';
import PrintIcon from '@mui/icons-material/Print';
import { FileApi, UserManagementApi } from 'api';
import { useDispatch, useSelector } from 'redux/store';
import { useEffect, useRef, useState } from 'react';
import useAuth from '../../hooks/useAuth';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import CommonBootstrapDialogTitle, { BootstrapDialog } from 'components/CommonBootstrapDialogTitle';
import CustomSnackbar from 'components/CustomSnackbar';
import { CommonErrorModalProps } from 'redux/common/common.type';
interface OwnsProps {
  open: boolean;
  onClose: () => void;
  dataFrom?: string; //TODO check if this var needs
  type?: string;
}

// TODO review
const LegalAgreementModal = ({ open, onClose, dataFrom, type }: OwnsProps) => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [openSnackbar, setOpenSnackbar] = useState<CommonErrorModalProps>({ open: false });
  const [bottom, setBottom] = useState(false);
  const { sellerAgreementDoc, isLoading, legalAgreementDoc } = useSelector(
    (state) => state.userManagement
  );
  const { initUserInfo } = useAuth();
  const dispatch = useDispatch();
  const scrollRef = useRef();

  useEffect(() => {
    dispatch(UserManagementApi.getLegalConditions());
  }, []);

  const acceptAndClose = async () => {
    // handle server error
    const action = await dispatch(UserManagementApi.updateCompanyAgreement({}));
    if (UserManagementApi.updateCompanyAgreement.fulfilled.match(action)) {
      initUserInfo();
    }
    if (UserManagementApi.updateCompanyAgreement.rejected.match(action)) {
      setOpenSnackbar({ open: true, message: action.payload?.error?.message, type: 'error' });
    }
  };
  // TODO disable to scroll to button
  const downloadAgreement = async () => {
    let filterParam = '?type=seller_agreement';
    const response = await dispatch(FileApi.downloadAgreement(filterParam));
    if (FileApi.downloadAgreement.fulfilled.match(response)) {
      console.log(response.payload);
      const a = document.createElement('a') as HTMLAnchorElement;
      a.href = window.URL.createObjectURL(response.payload);
      a.setAttribute('download', 'seller_agreement.pdf');
      document.body.appendChild(a);
      console.log(a);
      console.log();
      a.click();
      a.remove();
    }
    if (FileApi.downloadAgreement.rejected.match(response)) {
      setOpenSnackbar({ open: true, message: response?.payload?.error?.message, type: 'error' });
    }
  };

  const handleScroll = (e: any) => {
    if (!bottom) {
      const bottom =
        Math.floor(e.target.scrollTop + e.target.offsetHeight + 10) >= e.target.scrollHeight;
      setBottom(bottom);
    }
  };

  return (
    <BootstrapDialog open={open} onClose={() => onClose()} maxWidth="sm">
      <CommonBootstrapDialogTitle title={'All requirements are completed!'} onClose={onClose} />

      <DialogContent>
        {openSnackbar.open && (
          <CustomSnackbar
            open={openSnackbar.open}
            severityType={openSnackbar.type}
            message={openSnackbar.message as string}
            header={openSnackbar.message_header}
            onClose={() => {
              setOpenSnackbar({ open: false, message: undefined, message_header: undefined });
            }}
          />
        )}

        <Typography variant="subtitle2" fontWeight={(theme) => theme.typography.fontWeightBold}>
          Please review, accept and submit the Seller agreement below. We will review your
          application and let you know via email within 24 hours If you are authorized to start
          selling on the Company Name.
        </Typography>
        {!!legalAgreementDoc?.agreement && (
          <Box
            ref={scrollRef}
            onScroll={handleScroll}
            sx={{
              maxHeight: '45vh',
              height: '100%',
              fontSize: '12px',
              display: 'inline-block',
              overflow: 'auto',
              fontWeight: 200,
            }}
            dangerouslySetInnerHTML={{ __html: legalAgreementDoc.agreement }}
          />
        )}
        {isLoading && (
          <Box sx={{ display: 'flex' }}>
            <CircularProgress />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Stack direction={'row'} alignItems="center" justifyContent={'center'}>
          {type !== 'from_docLibrary' && (
            <Button variant="contained" onClick={acceptAndClose} disabled={!bottom}>
              {dataFrom ? 'Approved' : 'Approve & Submit'}
            </Button>
          )}

          <Button variant="text" onClick={() => onClose()}>
            Close
          </Button>
          <Button variant="outlined" endIcon={<GetAppOutlinedIcon />} onClick={downloadAgreement}>
            Download PDF
          </Button>
          {/* <Button variant="contained">
            <PrintIcon />
          </Button> */}
        </Stack>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default LegalAgreementModal;
