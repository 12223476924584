import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { ReactNode } from 'react';

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
  height?: string;
}
export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialog-paper': {
    borderRadius: '10px',
  },
}));

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, height, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, paddingX: 3 }} {...other} component="div">
      <Typography
        sx={{ height: height, marginRight: 4 }}
        variant={'subtitle1'}
        fontWeight={(theme) => theme.typography.fontWeightBold}
        fontSize="14px"
      >
        {children}
      </Typography>

      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
interface OwnsProps {
  onClose: () => void;
  title?: string | ReactNode;
}

const CommonBootstrapDialogTitle = ({ title, onClose }: OwnsProps) => {
  return (
    <BootstrapDialogTitle
      id="customized-dialog-title"
      onClose={onClose}
      height={!!title ? undefined : '20px'}
    >
      {title}
    </BootstrapDialogTitle>
  );
};

export default CommonBootstrapDialogTitle;
