import useAuth from 'hooks/useAuth';
import LoginPage from 'pages/auth/LoginPage';
import { ReactNode, useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { PATH_AUTH } from 'routes/paths';
import { useIdleTimer } from 'react-idle-timer';

interface OwnProps {
  children: ReactNode;
}
export default function AuthGuard({ children }: OwnProps) {
  const { isAuthenticated, isMissingBasicSteps, registrationStepsData, logout } = useAuth();
  const { pathname } = useLocation();

  useIdleTimer({
    onIdle: () => logout(),
    timeout: 900000,
    throttle: 500,
  });

  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);
  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <LoginPage />;
  }
  if (isMissingBasicSteps) {
    return (
      <Navigate
        to={PATH_AUTH.register}
        state={
          (Array.isArray(registrationStepsData) &&
            registrationStepsData.find((f) => f.step_number <= 7 && !f.is_completed)
              ?.step_number) ||
          0
        }
      />
    );
  }
  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
