import { createSlice } from '@reduxjs/toolkit';
import { SettingsState } from '../settings/settings.type';
import { SettingsApi } from 'api';

const initialState: SettingsState = {
  isLoading: false,
  isCompanyTypeListLoading: false,
  isIndustryData: false,
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setIsLoading: (state) => {
      state.isLoading = true;
    },
    reset: (state) => {
      state.isLoading = false; //TODO
    },
    setSettingsError: (state, action) => {
      state.settingsError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(SettingsApi.getNaicsList.pending, (state) => {
        state.isLoading = true;
        state.naicsList = undefined;
      })
      .addCase(SettingsApi.getNaicsList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.naicsList = action.payload;
      })
      .addCase(SettingsApi.getNaicsList.rejected, (state, action) => {
        state.isLoading = false;
        state.settingsError = action.payload;
      })
      .addCase(SettingsApi.getInvoiceTradingList.pending, (state) => {
        state.isLoading = true;
        state.invoiceTradingList = undefined;
      })
      .addCase(SettingsApi.getInvoiceTradingList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.invoiceTradingList = action.payload;
      })
      .addCase(SettingsApi.getInvoiceTradingList.rejected, (state, action) => {
        state.isLoading = false;
        state.settingsError = action.payload;
      })
      .addCase(SettingsApi.getSICCodeList.pending, (state) => {
        state.isLoading = true;
        state.sicList = undefined;
      })
      .addCase(SettingsApi.getSICCodeList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.sicList = action.payload;
      })
      .addCase(SettingsApi.getSICCodeList.rejected, (state, action) => {
        state.isLoading = false;
        state.settingsError = action.payload;
      })
      .addCase(SettingsApi.getMCCList.pending, (state) => {
        state.isLoading = true;
        state.mCCListData = undefined;
      })
      .addCase(SettingsApi.getMCCList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.mCCListData = action.payload;
      })
      .addCase(SettingsApi.getMCCList.rejected, (state, action) => {
        state.isLoading = false;
        state.settingsError = action.payload;
      })
      .addCase(SettingsApi.getIndustry.pending, (state) => {
        state.isLoading = true;
        state.industryList = undefined;
      })
      .addCase(SettingsApi.getIndustry.fulfilled, (state, action) => {
        state.isLoading = false;
        state.industryList = action.payload;
      })
      .addCase(SettingsApi.getIndustry.rejected, (state, action) => {
        state.isLoading = false;
        state.settingsError = action.payload;
      })
      .addCase(SettingsApi.getCompanyTypeList.pending, (state) => {
        state.isLoading = true;
        state.companyTypeList = undefined;
      })
      .addCase(SettingsApi.getCompanyTypeList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.companyTypeList = action.payload;
      })
      .addCase(SettingsApi.getCompanyTypeList.rejected, (state, action) => {
        state.isLoading = false;
        state.settingsError = action.payload;
      })
      .addCase(SettingsApi.getOrganizationList.pending, (state) => {
        state.isLoading = true;
        state.organizationList = undefined;
      })
      .addCase(SettingsApi.getOrganizationList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.organizationList = action.payload;
      })
      .addCase(SettingsApi.getOrganizationList.rejected, (state, action) => {
        state.isLoading = false;
        state.settingsError = action.payload;
      })
      .addCase(SettingsApi.getUserInvitation.pending, (state) => {
        state.isLoading = true;
        state.userInvitationData = undefined;
      })
      .addCase(SettingsApi.getUserInvitation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userInvitationData = action.payload;
      })
      .addCase(SettingsApi.getUserInvitation.rejected, (state, action) => {
        state.isLoading = false;
        state.settingsError = action.payload;
      })
      .addCase(SettingsApi.getCompanyInvitation.pending, (state) => {
        state.isLoading = true;
        state.companyInvitationData = undefined;
      })
      .addCase(SettingsApi.getCompanyInvitation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.companyInvitationData = action.payload;
      })
      .addCase(SettingsApi.getCompanyInvitation.rejected, (state, action) => {
        state.isLoading = false;
        state.settingsError = action.payload;
      })
      .addCase(SettingsApi.getApproverTypeList.pending, (state) => {
        state.isLoading = true;
        state.approverTypeList = undefined;
      })
      .addCase(SettingsApi.getApproverTypeList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.approverTypeList = action.payload;
      })
      .addCase(SettingsApi.getApproverTypeList.rejected, (state, action) => {
        state.isLoading = false;
        state.settingsError = action.payload;
      })
      .addCase(SettingsApi.getDocumentRequests.pending, (state) => {
        state.isLoading = true;
        state.documentRequestsData = undefined;
      })
      .addCase(SettingsApi.getDocumentRequests.fulfilled, (state, action) => {
        state.isLoading = false;
        state.documentRequestsData = action.payload;
      })
      .addCase(SettingsApi.getDocumentRequests.rejected, (state, action) => {
        state.isLoading = false;
        state.settingsError = action.payload;
      })
      .addCase(SettingsApi.getDocumentTypes.pending, (state) => {
        state.isLoading = true;
        state.documentTypesData = undefined;
      })
      .addCase(SettingsApi.getDocumentTypes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.documentTypesData = action.payload;
      })
      .addCase(SettingsApi.getDocumentTypes.rejected, (state, action) => {
        state.isLoading = false;
        state.settingsError = action.payload;
      })
      .addCase(SettingsApi.getFinancialProfile.pending, (state) => {
        state.isLoading = true;
        state.financialProfileData = undefined;
      })
      .addCase(SettingsApi.getFinancialProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.financialProfileData = action.payload;
      })
      .addCase(SettingsApi.getFinancialProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.settingsError = action.payload;
      })
      .addCase(SettingsApi.updateFinancialProfile.pending, (state) => {
        state.isLoading = true;
        state.financialProfileData = undefined;
      })
      .addCase(SettingsApi.updateFinancialProfile.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(SettingsApi.updateFinancialProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.settingsError = action.payload;
      })
      .addCase(SettingsApi.getAllLibraryDocuments.pending, (state) => {
        state.isLoading = true;
        state.libraryDocs = undefined;
      })
      .addCase(SettingsApi.getAllLibraryDocuments.fulfilled, (state, action) => {
        state.isLoading = false;
        state.libraryDocs = action.payload;
      })
      .addCase(SettingsApi.getAllLibraryDocuments.rejected, (state, action) => {
        state.isLoading = false;
        state.settingsError = action.payload;
      });
  },
});

export const { setIsLoading, reset, setSettingsError } = settingsSlice.actions;

export default settingsSlice.reducer;
