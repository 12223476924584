import { useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import {
  Input,
  Slide,
  Button,
  InputAdornment,
  ClickAwayListener,
  Stack,
  useTheme,
} from '@mui/material';

// components
import Iconify from 'components/Iconify';
import IconButton from '@mui/material/IconButton';
import { useDispatch } from 'redux/store';
import { CommonApi } from 'api';
import useAuth from 'hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { DASHBOARD_PAGES } from 'routes/paths';
import ConfirmationModal from 'components/Models/ConfirmationModal';
import { getNextGridSortDirection } from '@mui/x-data-grid/hooks/features/sorting/gridSortingUtils';
import { CommonErrorModalProps } from 'redux/common/common.type';
import CustomSnackbar from 'components/CustomSnackbar';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 54;
const APPBAR_DESKTOP = 54;

const SearchbarStyle = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  height: APPBAR_MOBILE,
  padding: theme.spacing(0, 3),
  [theme.breakpoints.up('md')]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

interface ErrorProps {
  message_header?: string;
  message?: string;
  open: boolean;
}

export default function Searchbar() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [openMessage, setOpenMessage] = useState<ErrorProps>({ open: false });
  const [openSnackbar, setOpenSnackbar] = useState<CommonErrorModalProps>({ open: false });
  const [value, setValue] = useState<string>('');
  const { role } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getDirectLink = (screen?: string, v?: string) => {
    if (role === 'funder') {
      if (screen?.toLowerCase() === 'history') {
        return {
          url: DASHBOARD_PAGES.history,
          state: { searchText: value },
        };
      } else if (screen?.toLowerCase() === 'dashboard') {
        return {
          url: DASHBOARD_PAGES.funderDashboard,
          state: { searchText: value },
        };
      } else if (screen?.toLowerCase() === 'repayment') {
        return {
          url: DASHBOARD_PAGES.repayment,
          state: { searchText: value },
        };
      } else if (screen?.toLowerCase() === 'active') {
        return {
          url: DASHBOARD_PAGES.active,
          state: { searchText: value },
        };
      }
      //else if (screen?.toLowerCase() === 'approvals') {
      //   return {
      //     url: DASHBOARD_PAGES.approvals,          //TODO need to add Approvals router
      //     state: { searchText: value },
      //   };
      // }
      else if (screen?.toLowerCase() === 'contacts_seller') {
        return {
          url: DASHBOARD_PAGES.contacts,
          state: { searchText: value, tabName: 'seller' },
        };
      } else if (screen?.toLowerCase() === 'contacts_buyer') {
        return {
          url: DASHBOARD_PAGES.contacts,
          state: { searchText: value, tabName: 'buyer' },
        };
      }
    } else if (role === 'seller' || role === 'buyer') {
      if (screen?.toLowerCase() === 'history') {
        return {
          url: DASHBOARD_PAGES.history,
          state: { searchText: value },
        };
      } else if (
        screen?.toLowerCase() === 'dashboard' ||
        screen?.toLowerCase() === 'sell_invoices'
      ) {
        return {
          url: DASHBOARD_PAGES.marketplace,
          state: { searchText: value },
        };
      } else if (screen?.toLowerCase() === 'repayment') {
        return {
          url: DASHBOARD_PAGES.repayment,
          state: { searchText: value },
        };
      } else if (screen?.toLowerCase() === 'contacts') {
        return {
          url: DASHBOARD_PAGES.contacts,
          state: { searchText: value, user_type: 'seller' },
        };
      } else if (screen?.toLowerCase() === 'contacts_buyer') {
        return {
          url: DASHBOARD_PAGES.contacts,
          state: { searchText: value, user_type: 'buyer' },
        };
      } else if (screen?.toLowerCase() === 'sell_invoices_in_offer') {
        return {
          url: DASHBOARD_PAGES.marketplace,
          state: { searchText: value, tabName: 'eligible_tab' },
        };
      } else if (screen?.toLowerCase() === 'sell_invoices_queued') {
        return {
          url: DASHBOARD_PAGES.marketplace,
          state: { searchText: value, tabName: 'queue_tab' },
        };
      } else if (screen?.toLowerCase() === 'sell_invoices_need_verification') {
        return {
          url: DASHBOARD_PAGES.marketplace,
          state: { searchText: value, tabName: 'needs_verification_tab' },
        };
      } else if (screen?.toLowerCase() === 'sell_invoices_ineligible') {
        return {
          url: DASHBOARD_PAGES.marketplace,
          state: { searchText: value, tabName: 'ineligible_tab' },
        };
      }
    }
  };

  const handleSearch = async () => {
    const action = await dispatch(CommonApi.getGlobalSearchValue(`?q=${value}`));
    if (CommonApi.getGlobalSearchValue.fulfilled.match(action)) {
      // TODO close and navigate or setSTate, collect all screens and page names
      const searchScreen = action.payload?.screen;
      let obj = getDirectLink(searchScreen, value);
      if (obj && obj.url) {
        navigate(obj.url, {
          state: obj?.state,
        });
      }
    }
    if (CommonApi.getGlobalSearchValue.rejected.match(action)) {
      if (action.payload?.error) {
        setOpenSnackbar({
          open: true,
          message: action?.payload?.error?.message,
          type: 'error',
        });
      }
      handleClose();
      // TODO navigate or setSTate, collect all screens and page names
    }
  };

  return (
    <>
      <ConfirmationModal
        open={openMessage.open}
        onClose={() => {
          setOpenMessage({ message: undefined, message_header: undefined, open: false });
        }}
        message={openMessage.message}
      />
      <ClickAwayListener onClickAway={handleClose}>
        <Stack direction="row-reverse" alignItems="center">
          <IconButton
            onClick={handleOpen}
            color="inherit"
            sx={{
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            <Iconify
              icon={'eva:search-fill'}
              width={26}
              height={26}
              sx={{
                color: 'text.active',
                cursor: 'pointer',
              }}
            />
          </IconButton>

          <Slide
            direction="left"
            in={open}
            timeout={{
              exit: 0.2,
            }}
            mountOnEnter
            unmountOnExit
          >
            <div>
              <Input
                autoFocus
                fullWidth
                disableUnderline
                value={value}
                placeholder="What you are looking for"
                sx={{
                  mr: 0.5,
                  fontWeight: 'fontWeightBold',
                  boxShadow: (theme) => theme.shadows[2],
                  borderRadius: '20px',
                  padding: '5px 15px',
                  transition: '0.5s ease all',
                  fontSize: '12px',
                }}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSearch();
                  }
                }}
              />
            </div>
          </Slide>
          {openSnackbar?.open && (
            <CustomSnackbar
              open={openSnackbar?.open}
              severityType={openSnackbar?.type}
              message={openSnackbar?.message as string}
              header={openSnackbar?.message_header}
              onClose={() => {
                setOpenSnackbar({ open: false, message: undefined, message_header: undefined });
              }}
            />
          )}
        </Stack>
      </ClickAwayListener>
    </>
  );
}
