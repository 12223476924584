import React from 'react';
import { FieldProps } from 'formik';
import Select, { SelectProps } from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

interface OwnProps {
  maxWidth?: number;
  fontSize?: string;
  marginBottom?: string;
  marginTop?: string;
  minWidth?: string;
}

const FormikSelect = ({
  children,
  label,
  id,
  fontSize,
  marginBottom,
  maxWidth,
  minWidth,
  marginTop,
  ...props
}: OwnProps & SelectProps & FieldProps) => {
  const { name, value } = props.field;
  const { setFieldValue } = props.form;

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        minWidth: 400,
      },
    },
  };

  return (
    <FormControl
      fullWidth
      sx={{
        '.MuiInputLabel-root': {
          fontSize: '14px',
          left: '-13px',
        },
        '.selectDrop': {
          height: '37px',
          marginTop: '0px',
        },
      }}
    >
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        {...props}
        name={name}
        displayEmpty
        value={value}
        variant={'standard'}
        className="selectDrop"
        onChange={(e) => {
          console.log('DDD', name, e.target.value);
          setFieldValue(name, e.target.value);
        }}
        sx={{
          fontSize: { fontSize },
          marginBottom: { marginBottom },
          marginTop: `${marginTop} !important`,
          minWidth: { minWidth },
          '.MuiSelect-select': {
            fontSize: '14px',
            '&:focus': {
              background: 'transparent',
            },
          },
        }}
        // input={<OutlinedInput />}
        // MenuProps={MenuProps}
        // id="input-select"
      >
        {children}
      </Select>
    </FormControl>
  );
};

export default FormikSelect;
