import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, IconButton } from '@mui/material';
// routes
import { PATH_AUTH } from 'routes/paths';

// hooks
import useAuth from 'hooks/useAuth';
import CustomAvatar from 'components/CustomAvatar';
import MenuPopover from 'components/MenuPopover';
import IconButtonAnimate from 'components/IconButtonAnimate';

export default function AccountPopover() {
  const navigate = useNavigate();

  const { logout, userData } = useAuth();

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = () => {
    try {
      logout();
      navigate(PATH_AUTH.login, { replace: true });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {/* <Stack direction="row" onClick={handleOpen} sx={{ alignItems: 'center' }}> */}
      {/* <IconButtonAnimate
          size="small"
          sx={{
            p: 0,
            ...(open && {
              '&:before': {
                zIndex: 1,
                content: "''",
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                position: 'absolute',
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
              },
            }),
          }}
        >
          <CustomAvatar
            sx={{ backgroundColor: 'transparent', color: (theme) => theme.palette.text.secondary }}
          />
        </IconButtonAnimate> */}

      {/* </Stack> */}
      <IconButton onClick={handleOpen} color="inherit">
        <PersonIcon />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
          },
        }}
      >
        <Box sx={{ margin: 0, padding: '10px 15px', textAlign: 'center' }}>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {userData?.user_details?.full_name}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={handleLogout}
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            padding: '10px 15px',
            borderRadius: 0,
            '&:hover': {
              color: (theme) => theme.palette.primary.contrastText,
              transition: '0.3s ease-in',
              backgroundColor: (theme) => theme.palette.primary.main,
            },
          }}
        >
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
