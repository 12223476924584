import { createContext, ReactNode, useCallback } from 'react';
import { useSelector, useDispatch } from 'redux/store';
import { reset as resetLogin, setLoginError } from 'redux/slices/login';
import {
  AuthContextData,
  LoginAndVerifyPayload,
  KeyInvitationValidationPayload,
} from 'redux/login/login.type';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { LoginApi } from 'api';
import { LevelAccess } from 'redux/common/common.type';
import { InvitedUserBasicSignUpPayload } from '../redux/login/login.type';

// ----------------------------------------------------------------------
// TODO type user type
const AuthContext = createContext<AuthContextData | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const { ...state } = useSelector((state) => state.login);
  const dispatch = useDispatch();

  const initUserInfo = () => {
    // TODO test only, seller/funder to change LoginApi.getUserDetailsSeller or getUserDetails
    dispatch(LoginApi.getRegistrationSteps());
    dispatch(LoginApi.getUserDetails());
    // dispatch(LoginApi.getUserDetailsSeller());
    // dispatch(LoginApi.getCustomerConfig()); //TODO check if we need it
    // dispatch(LoginApi.getCompanyProfile()); // TODO review this one
  };

  const verifyLoginOTP = async (payload: LoginAndVerifyPayload) => {
    const resultAction = await dispatch(
      LoginApi.verifyOTP({
        email: payload.email,
        password: payload.password,
        type: payload.type,
        otp: payload.otp,
      })
    );
    if (LoginApi.verifyOTP.fulfilled.match(resultAction)) {
      initUserInfo();
    }
  };

  const logout = () => {
    // handle logout errors
    dispatch(LoginApi.logout());
    dispatch(resetLogin());
  };
  const handleVerifyUserEmailInvitationToken = async (token: string) => {
    dispatch(LoginApi.verifyUserEmailInvitationToken(`?token=${token}`));
  };
  const keyInvitationValidation = async (payload: KeyInvitationValidationPayload) => {
    dispatch(LoginApi.keyInvitationValidation(payload));
  };
  const invitedUserBasicSignUp = async (payload: InvitedUserBasicSignUpPayload) => {
    dispatch(LoginApi.invitedUserBasicSignUp(payload));
  };

  const getDefaultRole = (roles: string[]) => {
    if (roles && (roles.includes('buyer') || roles.includes('seller'))) {
      return 'seller';
    }
    return 'funder';
  };
  const getRole = (roles: string[]) => {
    if (roles.includes('buyer') || roles.includes('seller')) {
      return 'seller';
    }
    return roles[0];
  };
  // TODO check if require
  // let isInvoiceUploadRequired = !!state.tenantDetailData?.receivables
  //   ? state.tenantDetailData?.receivables.filter((ele) => ele.key === 'invoice').length > 0
  //   : false;

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'auth',
        language: state.userData?.user_details?.application_language || 'en',
        recaptcha_enabled: state.tenantDetailData?.recaptcha_enabled,
        signup_verification_enabled: state.tenantDetailData?.signup_verification_enabled,
        invoice_holdback_enabled: !!state.userData?.company_details?.invoice_holdback_enabled,
        can_access_surf_poc: state.tenantDetailData?.can_access_surf_poc,
        captcha_public_key_v3: state.tenantDetailData?.captcha_public_key_v3,
        captcha_public_key: state.tenantDetailData?.captcha_public_key,
        // isInvoiceUploadRequired,

        verifyLoginOTP,
        logout,
        initUserInfo,
        handleVerifyUserEmailInvitationToken,
        keyInvitationValidation,
        invitedUserBasicSignUp,
        access_level:
          LevelAccess[
            state.userData?.user_details?.access_level.toUpperCase() as keyof typeof LevelAccess
          ],
        appName: state.tenantDetailData?.app_name,
        currency: state.userData?.user_details?.currency,
        isUnlimitedAccessAndVerified:
          !state.userData?.company_details?.limited_access &&
          state.userData?.company_details?.verification_status === 'approved',
        isUserAllowedToAccess:
          state.isAuthenticated &&
          LevelAccess[
            state.userData?.user_details?.access_level.toUpperCase() as keyof typeof LevelAccess
          ] === LevelAccess.ADMINISTRATOR,
        role: state.userData?.user_details ? getRole(state.userData.user_details?.role) : '',
        defaultRole: state.userData?.company_details
          ? getDefaultRole(state.userData?.company_details?.role)
          : undefined,
        sellerFunderLimitedAccess:
          state?.userData?.user_details?.role &&
          ['funder', 'seller', 'buyer'].includes(state.userData?.user_details?.role[0]) &&
          state.userData?.company_details?.limited_access,
        kycVerified: state.userData?.user_details.wu_kyc,
        country:
          state.userData?.user_details?.country_code ||
          state.userData?.company_details?.country_code,
        supportEmail: state.tenantDetailData?.support_email || '',
        countryCode: state.userData?.user_details?.country_code || '',
        ach_debit_allowed: state.userData?.user_details?.ach_debit_allowed,
        ofac_verification: state.userData?.user_details?.ofac_verification,
        cis_approval: state.userData?.user_details?.cis_approval,
        invoice_holdback_prorate_enabled:
          state.userData?.company_details?.invoice_holdback_prorate_enabled,
        is_bank_verification_required: state.userData?.user_details?.is_bank_verification_required,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
