import { Dialog, DialogTitle, styled } from '@mui/material';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { ReactNode } from 'react';
import CommonBootstrapDialogTitle from 'components/CommonBootstrapDialogTitle';
import { BootstrapDialog } from 'components/CommonBootstrapDialogTitle';

interface OwnProps {
  message?: string | ReactNode;
  onClose: (e: any) => void;
  open: boolean;
  title?: string;
  isHideActions?: boolean;
  children?: ReactNode;
  agreeButtonTitle?: string;
  cancelButtonTitle?: string;
}

const ConfirmationModal = ({
  open,
  onClose,
  message,
  title,
  isHideActions,
  children,
  agreeButtonTitle,
  cancelButtonTitle,
}: OwnProps) => {
  const handleClose = (type: string) => {
    onClose(type);
  };
  return (
    <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <CommonBootstrapDialogTitle title={title} onClose={() => handleClose('close')} />

      <DialogContent dividers>
        {typeof message === 'string' ? (
          <Typography variant="subtitle2">{message}</Typography>
        ) : (
          message
        )}
        {children}
      </DialogContent>
      {!isHideActions && (
        <DialogActions>
          <Button
            variant="text"
            onClick={() => handleClose('cancel')}
            sx={{
              minHeight: '40px',
              minWidth: '92px',
              fontWeight: (theme) => theme.typography.fontWeightBold,
            }}
          >
            {cancelButtonTitle}
          </Button>
          <Button
            variant="contained"
            onClick={() => handleClose('yes')}
            sx={{
              minHeight: '40px',
              minWidth: '92px',
              fontWeight: (theme) => theme.typography.fontWeightBold,
            }}
          >
            {agreeButtonTitle}
          </Button>
        </DialogActions>
      )}
    </BootstrapDialog>
  );
};

export default ConfirmationModal;
