import { createSlice } from '@reduxjs/toolkit';
import { SurfScoreState } from '../surfScore/surfScore.type';
import { SurfScoreApi } from 'api';

const initialState: SurfScoreState = {
  isLoading: false,
};

const surfScoreSlice = createSlice({
  name: 'surfScore',
  initialState,
  reducers: {
    setIsLoading: (state) => {
      state.isLoading = true;
    },
    setSurfScoreError: (state, action) => {
      state.surfScoreError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(SurfScoreApi.getSurfScoreDistribution.pending, (state) => {
        state.isLoading = true;
        state.surfScoreDistribution = undefined;
      })
      .addCase(SurfScoreApi.getSurfScoreDistribution.fulfilled, (state, action) => {
        state.isLoading = true;
        state.surfScoreDistribution = action.payload;
      })
      .addCase(SurfScoreApi.getSurfScoreDistribution.rejected, (state, action) => {
        state.isLoading = false;
        state.surfScoreError = action.payload;
      })
      .addCase(SurfScoreApi.getSurfScoreSmartScoreList.pending, (state) => {
        state.isLoading = true;
        state.surfScoreSmartScoreList = undefined;
      })
      .addCase(SurfScoreApi.getSurfScoreSmartScoreList.fulfilled, (state, action) => {
        state.isLoading = true;
        state.surfScoreSmartScoreList = action.payload;
      })
      .addCase(SurfScoreApi.getSurfScoreSmartScoreList.rejected, (state, action) => {
        state.isLoading = false;
        state.surfScoreError = action.payload;
      })
      .addCase(SurfScoreApi.getSurfScoreVerificationData.pending, (state) => {
        state.isLoading = true;
        state.surfScoreVerificationData = undefined;
      })
      .addCase(SurfScoreApi.getSurfScoreVerificationData.fulfilled, (state, action) => {
        state.isLoading = true;
        state.surfScoreVerificationData = action.payload;
      })
      .addCase(SurfScoreApi.getSurfScoreVerificationData.rejected, (state, action) => {
        state.isLoading = false;
        state.surfScoreError = action.payload;
      })
      .addCase(SurfScoreApi.getSurfScoreMapData.pending, (state) => {
        state.isLoading = true;
        state.surfScoreMapData = undefined;
      })
      .addCase(SurfScoreApi.getSurfScoreMapData.fulfilled, (state, action) => {
        state.isLoading = true;
        state.surfScoreMapData = action.payload;
      })
      .addCase(SurfScoreApi.getSurfScoreMapData.rejected, (state, action) => {
        state.isLoading = false;
        state.surfScoreError = action.payload;
      })
      .addCase(SurfScoreApi.getBubbleData.pending, (state) => {
        state.isLoading = true;
        state.bubbleData = undefined;
      })
      .addCase(SurfScoreApi.getBubbleData.fulfilled, (state, action) => {
        state.isLoading = true;
        state.bubbleData = action.payload;
      })
      .addCase(SurfScoreApi.getBubbleData.rejected, (state, action) => {
        state.isLoading = false;
        state.surfScoreError = action.payload;
      });
  },
});

export const { setIsLoading, setSurfScoreError } = surfScoreSlice.actions;

export default surfScoreSlice.reducer;
