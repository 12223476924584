import { createSlice } from '@reduxjs/toolkit';

import { WalletApi } from 'api';
import { WalletState } from '../wallet/wallet.type';

const initialState: WalletState = {
  isLoading: false,
};

const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setIsLoading: (state) => {
      state.isLoading = true;
    },
    setWalletError: (state, action) => {
      state.walletError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(WalletApi.getWallets.pending, (state) => {
        state.isLoading = true;
        state.walletData = undefined;
      })
      .addCase(WalletApi.getWallets.fulfilled, (state, action) => {
        state.isLoading = false;
        state.walletData = action.payload;
      })
      .addCase(WalletApi.getWallets.rejected, (state, action) => {
        state.isLoading = false;
        state.walletError = action.payload;
      });
  },
});

export const { setIsLoading, setWalletError } = walletSlice.actions;

export default walletSlice.reducer;
