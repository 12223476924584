import { useEffect, useState, ReactNode } from 'react';
import TextField from '@mui/material/TextField';

interface OwnProps {
  numInputs: number;
  onSubmit: (v: number) => void;
  separator: ReactNode;
}

const InputOTP = ({ numInputs, onSubmit, separator }: OwnProps) => {
  const [values, setValues] = useState<object>(
    Array.from(Array(numInputs).keys()).reduce((a, v) => ({ ...a, [v]: '' }), {})
  );

  useEffect(() => {
    const tmp = Object.keys(values)
      .filter((f) => !!values[f as keyof typeof values])
      .map((m) => values[m as keyof typeof values]);
    if (tmp.length === numInputs) {
      onSubmit(Number(tmp.join('')));
    }
  }, [values]);

  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    k: string
  ) => {
    const v = e.target.value.trim();
    setValues((prev) => ({ ...prev, [k]: v }));

    let firstElm = 0;
    let found: boolean = false;

    while (!found && firstElm !== Number(k) && firstElm < numInputs) {
      const previousElementSibling = document.getElementById(
        `otp-id-${firstElm}`
      ) as HTMLInputElement | null;
      if (previousElementSibling && !previousElementSibling.value) {
        found = true;
        previousElementSibling.focus();
      }
      firstElm = firstElm + 1;
    }
    firstElm = firstElm + 1;
    while (!found && firstElm < numInputs) {
      const nextElementSibling = document.getElementById(
        `otp-id-${firstElm}`
      ) as HTMLInputElement | null;
      if (nextElementSibling && !nextElementSibling.value) {
        found = true;
        nextElementSibling.focus();
      }
      firstElm = firstElm + 1;
    }
  };

  return (
    <div style={{ display: 'inline-flex', alignItems: 'center' }}>
      {Object.keys(values).map((k, index) => (
        <div key={k}>
          <div style={{ display: 'inline-flex', alignItems: 'center' }}>
            <TextField
              required
              variant="outlined"
              value={values[index as keyof typeof values]}
              onChange={(e) => handleOnChange(e, k)}
              inputProps={{
                maxWidth: 1,
                maxLength: 1,
                id: `otp-id-${k}`,
              }}
              size="small"
            />
            {index < numInputs - 1 && separator}
          </div>
        </div>
      ))}
    </div>
  );
};

export default InputOTP;
