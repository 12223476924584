import RepaymentIcon from '@mui/icons-material/Receipt';
import HistoryIcon from '@mui/icons-material/AccessTime';
import DashboardIcon from '@mui/icons-material/Article';
import SettingsIcon from '@mui/icons-material/Settings';
import ParameterIcon from '@mui/icons-material/Tune';
import OverviewIcon from '@mui/icons-material/Dashboard';
import ActivePlaceholderIcon from '@mui/icons-material/ViewTimeline';
import SurfIcon from '@mui/icons-material/FormatListBulleted';
import StoreIcon from '@mui/icons-material/Store';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PasswordIcon from '@mui/icons-material/Password';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import TopicIcon from '@mui/icons-material/Topic';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import FinancialIcon from '@mui/icons-material/AccountBalanceWallet';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ListIcon from '@mui/icons-material/List';
import MarketplaceIcon from '@mui/icons-material/Home';
import ContactsIcon from '@mui/icons-material/AccountBox';

import {
  Exchange_Logo,
  Exchange_Logo_small,
  Citi_SideBar_logo,
  Citi_SideBar_logo_small,
  Angelsden_Logo,
  Angelsden_Logo_small,
  Facebook_Logo,
  Facebook_Logo_small,
  COD_Logo,
  COD_Logo_small,
} from 'assets';

export const getIconSmall = {
  'Avalon Core': {
    logo: Exchange_Logo,
    small: Exchange_Logo_small,
  },
  City: {
    logo: Citi_SideBar_logo,
    small: Citi_SideBar_logo_small,
  },
  Angelsden: {
    logo: Angelsden_Logo,
    small: Angelsden_Logo_small,
  },
  FB: {
    logo: Facebook_Logo,
    small: Facebook_Logo_small,
  },
  COD: {
    logo: COD_Logo,
    small: COD_Logo_small,
  },
};
export const path = (root: string, subLink: string) => `${root}/${subLink}`;

const ROOT_AUTH = '/auth';
const ROOT_LAYOUT = '/dashboard';

export const PATH_AUTH = {
  root: ROOT_AUTH,
  login: path(ROOT_AUTH, 'login'),
  register: path(ROOT_AUTH, 'sign-up'),
  forgot: path(ROOT_AUTH, 'forgot-password'),
  invite: path(ROOT_AUTH, 'invite-landing'),
  postPhoneSetup: path(ROOT_AUTH, 'invite-phone-setup'),
  completeRegTerms: path(ROOT_AUTH, 'invite-accept-reg-terms'),
  acceptSellerTerms: path(ROOT_AUTH, 'invite-accept-seller-terms'),
  successLanderPage: path(ROOT_AUTH, 'signup-success-lander'),
};

// TODO check how many levels we need here
export const DASHBOARD_PAGES = {
  root: ROOT_LAYOUT,
  marketplace: path(ROOT_LAYOUT, 'marketplace'),
  dashboard: path(ROOT_LAYOUT, 'dashboard'),
  funderDashboard: path(ROOT_LAYOUT, 'overview'),
  repayment: path(ROOT_LAYOUT, 'repayment'),
  history: path(ROOT_LAYOUT, 'history'),
  contacts: path(ROOT_LAYOUT, 'contacts'),
  parameters: path(ROOT_LAYOUT, 'parameters'),
  surfReport: path(ROOT_LAYOUT, 'surf-report'),
  settings: path(ROOT_LAYOUT, 'settings'),
  active: path(ROOT_LAYOUT, 'active'),
  password: path(ROOT_LAYOUT, 'password'),
};

export const SETTINGS_PAGES = {
  root: ROOT_LAYOUT,
  companyProfile: path(ROOT_LAYOUT, 'settings/company-profile'),
  userProfile: path(ROOT_LAYOUT, 'settings/user-profile'),
  password: path(ROOT_LAYOUT, 'settings/password'),
  accountingSystem: path(ROOT_LAYOUT, 'settings/accounting-system'),
  accountingSystemCreateInvoice: path(ROOT_LAYOUT, 'settings/accounting-system/create-invoice'),
  documentLibrary: path(ROOT_LAYOUT, 'settings/document-library'),
  bankingInformation: path(ROOT_LAYOUT, 'settings/banking-information'),
  financialProfile: path(ROOT_LAYOUT, 'settings/financial-profile'),
  admin: path(ROOT_LAYOUT, 'settings/admin'),
};

export const dashboardLabels = [
  {
    path: DASHBOARD_PAGES.marketplace,
    label: 'Marketplace',
    icon: <MarketplaceIcon />,
    tenant: ['City', 'Avalon Core', 'EG', 'FB', 'Angelsden', 'COD'],
    roles: ['seller'],
  },
  {
    path: DASHBOARD_PAGES.dashboard,
    label: 'Dashboard',
    icon: <DashboardIcon />,
    tenant: ['City', 'Avalon Core', 'EG', 'FB', 'Angelsden', 'COD'],
    roles: ['seller'],
  },
  {
    path: DASHBOARD_PAGES.funderDashboard,
    label: 'Dashboard',
    icon: <OverviewIcon />,
    tenant: ['City', 'Avalon Core', 'EG', 'FB', 'Angelsden', 'COD'],
    roles: ['funder'],
  },
  {
    path: DASHBOARD_PAGES.active,
    label: 'Active',
    icon: <ActivePlaceholderIcon />,
    tenant: ['City', 'Avalon Core', 'EG', 'FB', 'Angelsden', 'COD'],
    roles: ['funder'],
  },
  {
    path: DASHBOARD_PAGES.repayment,
    label: 'Repayment',
    icon: <RepaymentIcon />,
    tenant: ['City', 'Avalon Core', 'EG', 'FB', 'Angelsden', 'COD'],
    roles: ['seller', 'funder'],
  },
  {
    path: DASHBOARD_PAGES.history,
    label: 'History',
    icon: <HistoryIcon />,
    marginBottom: 1,
    marginTop: 0,
    tenant: ['City', 'Avalon Core', 'EG', 'FB', 'Angelsden', 'COD'],
    roles: ['seller', 'funder'],
  },
  {
    path: DASHBOARD_PAGES.parameters,
    label: 'Parameters',
    icon: <ParameterIcon />,
    marginBottom: 1,
    marginTop: 0,
    tenant: ['City', 'Avalon Core', 'EG', 'FB', 'Angelsden', 'COD'],
    roles: ['funder'],
  },
  {
    path: DASHBOARD_PAGES.contacts,
    label: 'Contacts',
    icon: <ContactsIcon />,
    tenant: ['City', 'Avalon Core', 'EG', 'FB', 'Angelsden', 'COD'],
    roles: ['seller', 'funder'],
  },

  {
    path: DASHBOARD_PAGES.settings,
    label: 'settings',
    icon: <SettingsIcon />,
    tenant: ['City', 'Avalon Core', 'EG', 'FB', 'Angelsden', 'COD'],
    roles: ['seller', 'funder', 'buyer'],
    isSubMenu: true,
    subMenu: [
      {
        path: SETTINGS_PAGES.companyProfile,
        label: 'Company Profile',
        icon: <StoreIcon />,
        marginBottom: 1,
        marginTop: 0,
        tenant: ['City', 'Avalon Core', 'EG', 'FB', 'Angelsden', 'COD'],
        roles: ['seller', 'funder', 'buyer'],
      },
      {
        path: SETTINGS_PAGES.userProfile,
        label: 'User Profile',
        icon: <AccountCircleIcon />,
        marginBottom: 1,
        marginTop: 0,
        tenant: ['City', 'Avalon Core', 'EG', 'FB', 'Angelsden', 'COD'],
        roles: ['seller', 'funder', 'buyer'],
      },
      {
        path: SETTINGS_PAGES.password,
        label: 'Password',
        icon: <PasswordIcon />,
        marginBottom: 1,
        marginTop: 0,
        tenant: ['City', 'Avalon Core', 'EG', 'FB', 'Angelsden', 'COD'],
        roles: ['seller', 'funder', 'buyer'],
      },
      {
        path: SETTINGS_PAGES.accountingSystem,
        label: 'Accounting System',
        icon: <PointOfSaleIcon />,
        marginBottom: 1,
        marginTop: 0,
        tenant: ['City', 'Avalon Core', 'EG', 'FB', 'Angelsden', 'COD'],
        roles: ['seller'],
      },
      {
        path: SETTINGS_PAGES.documentLibrary,
        label: 'Document Library',
        icon: <TopicIcon />,
        marginBottom: 1,
        marginTop: 0,
        tenant: ['City', 'Avalon Core', 'EG', 'FB', 'Angelsden', 'COD'],
        roles: ['seller'],
      },
      {
        path: SETTINGS_PAGES.bankingInformation,
        label: 'Banking Information',
        icon: <AccountBalanceIcon />,
        marginBottom: 1,
        marginTop: 0,
        tenant: ['City', 'Avalon Core', 'EG', 'FB', 'Angelsden', 'COD'],
        roles: ['seller', 'funder', 'buyer'],
      },
      {
        path: SETTINGS_PAGES.financialProfile,
        label: 'Financial Profile',
        icon: <FinancialIcon />,
        marginBottom: 1,
        marginTop: 0,
        tenant: ['City', 'Avalon Core', 'EG', 'FB', 'Angelsden', 'COD'],
        roles: ['seller'],
      },
      {
        path: SETTINGS_PAGES.admin,
        label: 'Admin',
        icon: <AdminPanelSettingsIcon />,
        marginBottom: 1,
        marginTop: 0,
        tenant: ['City', 'Avalon Core', 'EG', 'FB', 'Angelsden', 'COD'],
        roles: ['seller', 'funder', 'buyer'],
      },
    ],
  },
  {
    path: DASHBOARD_PAGES.surfReport,
    label: 'SuRF Report',
    icon: <SurfIcon />,
    marginBottom: 1,
    marginTop: 0,
    tenant: ['City'],
    roles: ['funder'],
  },
] as MenuItemData[];

export interface SettingsSubMenu {
  path: string;
  label: string;
  icon: React.ReactNode;
  tenant: string[];
  roles: string[];
  marginBottom?: number;
  marginTop?: number;
}

export interface MenuItemData {
  path: string;
  label: string;
  icon: React.ReactNode;
  tenant: string[];
  roles: string[];
  marginBottom?: number;
  marginTop?: number;
  isSubMenu: boolean;
  subMenu: SettingsSubMenu[];
}
