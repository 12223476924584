import { Grid, Typography, Link, Button } from '@mui/material';
import { Form, Formik, Field, ErrorMessage } from 'formik';
import FormikTextField from 'components/formik/FormikTextField';
import * as Yup from 'yup';
import { EmailValidatePostPayload } from 'redux/userManagement/userManagement.type';
import LoadingButton from '@mui/lab/LoadingButton';

interface OwnProps {
  title: string;
  description?: any;
  policy?: string;
  onSubmit: (value: EmailValidatePostPayload) => void;
  dataPolicyLink?: string;
  init: EmailValidatePostPayload;
  isLoading: boolean;
}
const ValidationSchema = Yup.object().shape({
  email_address: Yup.string().email('Invalid email').required('This field is required'),
  verifyEmail: Yup.string()
    .oneOf([Yup.ref('email_address'), null], 'Confirm email must match')
    .required('This field is required.'),
});
const EmailAddressRegStep = ({
  title,
  description,
  policy,
  onSubmit,
  dataPolicyLink,
  init,
  isLoading,
}: OwnProps) => {
  return (
    <>
      {title && <Typography variant="h6">{title}</Typography>}
      {description}
      <Formik
        initialValues={init}
        validationSchema={ValidationSchema}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          onSubmit(values);
        }}
      >
        {(props) => (
          <Form autoComplete="off" onSubmit={props.handleSubmit}>
            <Grid container sx={{ marginTop: 3 }}>
              <Grid item xs={12}>
                <Field
                  name={`email_address`}
                  type="email"
                  label={
                    <span style={{ color: 'textSecondary', fontSize: '15px' }}>
                      Company Email Address
                    </span>
                  }
                  component={FormikTextField}
                  fontSize={'13px'}
                  marginBottom={4}
                  autoComplete={'off'}
                />
                <ErrorMessage
                  name="email_address"
                  component="div"
                  render={(msg) => (
                    <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                      {msg}
                    </Typography>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name={`verifyEmail`}
                  label={
                    <span style={{ color: 'textSecondary', fontSize: '15px' }}>
                      Verify Company Email Address
                    </span>
                  }
                  component={FormikTextField}
                  type="email"
                  fontSize={'13px'}
                  autoComplete={'off'}
                />
                <ErrorMessage
                  name="verifyEmail"
                  component="div"
                  render={(msg) => (
                    <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                      {msg}
                    </Typography>
                  )}
                />
              </Grid>
              {policy && (
                <Grid item xs={12} sx={{ marginBottom: 2, marginTop: 2, fontSize: '12px' }}>
                  <Typography variant="body1" sx={{ fontSize: '12px' }}>
                    {policy}{' '}
                    <Link sx={{ fontSize: '12px' }} href={dataPolicyLink} target="_blank">
                      Data Policy
                    </Link>
                  </Typography>
                </Grid>
              )}
              <Grid item sx={{ marginTop: 2, fontSize: '12px' }} xs={12}>
                <LoadingButton loading={isLoading} type="submit" variant="contained">
                  Next
                </LoadingButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default EmailAddressRegStep;
