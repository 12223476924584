import { createAsyncThunk } from '@reduxjs/toolkit';
import { HistoryFilterData, HistoryData, TransactionData } from 'redux/history/history.type';
import axios, { handleServerErrors } from 'utils/axios.config';
import { ServerErrors } from 'redux/common/common.type';

const getHistoryFilterData = createAsyncThunk<
  HistoryFilterData,
  string,
  { rejectValue: ServerErrors }
>('getHistoryDataByFilter', async (params, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<HistoryFilterData>(
      `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/history/filters/${params}`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

const getHistoryList = createAsyncThunk<HistoryData, string, { rejectValue: ServerErrors }>(
  'getHistoryList',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<HistoryData>(
        `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/history/list/${params}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

const getTransactionList = createAsyncThunk<TransactionData, string, { rejectValue: ServerErrors }>(
  'getTransactionList',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<TransactionData>(
        `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/history/transaction_list/${params}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

const getTransactionFilter = createAsyncThunk<
  HistoryFilterData,
  string,
  { rejectValue: ServerErrors }
>('getTransactionFilter', async (params, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<HistoryFilterData>(
      `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/history/transaction_filter/${params}`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});
// TODO review

export default {
  getHistoryFilterData,
  getHistoryList,
  getTransactionList,
  getTransactionFilter,
};
