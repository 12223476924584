import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { handleServerErrors } from 'utils/axios.config';
import {
  EditNotificationPayload,
  UserNotificationData,
} from 'redux/userNotification/userNotification.type';
import { ServerErrors } from 'redux/common/common.type';

const getNotification = createAsyncThunk<
  UserNotificationData,
  string,
  { rejectValue: ServerErrors }
>('getNotification', async (params, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<UserNotificationData>(
      `${process.env.REACT_APP_SERVER_URL}invoice-exchange/notification/list/${params}`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

const getWarningSuccessNotifications = createAsyncThunk<
  UserNotificationData,
  string,
  { rejectValue: ServerErrors }
>('getWarningSuccessNotifications', async (params, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<UserNotificationData>(
      `${process.env.REACT_APP_SERVER_URL}invoice-exchange/notification/list/${params}`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

const getNextNotification = createAsyncThunk<
  UserNotificationData,
  string,
  { rejectValue: ServerErrors }
>('getNextNotification', async (next, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<UserNotificationData>(next);
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

const editAllNotifications = createAsyncThunk<any, void, { rejectValue: ServerErrors }>(
  'editAllNotifications',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.put<any>(
        `${process.env.REACT_APP_SERVER_URL}invoice-exchange/notification/edit/`,
        { type: 'read_all' }
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);
const editNotificationById = createAsyncThunk<
  any,
  EditNotificationPayload,
  { rejectValue: ServerErrors }
>('editNotificationById', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await axios.put<any>(
      `${process.env.REACT_APP_SERVER_URL}invoice-exchange/notification/edit/`,
      payload
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

export default {
  getNotification,
  getNextNotification,
  editAllNotifications,
  editNotificationById,
  getWarningSuccessNotifications,
};
