import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
const LoadingProgress = () => {
  return (
    <Container
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        height: '100vh',
        alignItems: 'center',
      }}
    >
      <Box>
        <CircularProgress color="primary" size={40} thickness={4} />
      </Box>
    </Container>
  );
};

export default LoadingProgress;
