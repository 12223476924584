import Grid from '@mui/material/Grid';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { BasicSignupPayload } from 'redux/login/login.type';
import Typography from '@mui/material/Typography';
import FormikTextField from 'components/formik/FormikTextField';
import * as Yup from 'yup';
import { Button } from '@mui/material';

import { NAME_VALIDATION } from 'utils/inputValidation';
import LoadingButton from '@mui/lab/LoadingButton';

interface OwnProps {
  onSubmit: (values: Partial<BasicSignupPayload>) => void;
  init: Partial<BasicSignupPayload>;
  isLoading: boolean;
}
const ValidationSchema = Yup.object().shape({
  first_name: Yup.string().required('This field is required.'),
  last_name: Yup.string().required('This field is required.'),
});

const UserNameRegStep = ({ onSubmit, init, isLoading }: OwnProps) => {
  return (
    <Formik
      initialValues={init}
      validationSchema={ValidationSchema}
      onSubmit={(values, actions) => {
        actions.setSubmitting(false);
        onSubmit(values);
      }}
    >
      {(props) => (
        <Form autoComplete="off" onSubmit={props.handleSubmit}>
          <Grid container sx={{ marginTop: 3 }}>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                fontSize={'16px'}
                fontWeight={(theme) => theme.typography.fontWeightBold}
                marginBottom={'30px'}
              >
                What is your name ?
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Field
                type={'text'}
                label={<span style={{ color: 'textSecondary', fontSize: '15px' }}>First Name</span>}
                fontSize={'13px'}
                name={`first_name`}
                component={FormikTextField}
                autoComplete={'off'}
                marginBottom={4}
              />
              <ErrorMessage
                name="first_name"
                component="div"
                render={(msg) => (
                  <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                    {msg}
                  </Typography>
                )}
              />
              {/* TODO If i make Last Name empty And go Back and press Next Button First Name Stores in last Name */}
              <Field
                type={'text'}
                label={<span style={{ color: 'textSecondary', fontSize: '15px' }}>Last Name</span>}
                fontSize={'13px'}
                name={`last_name`}
                autoComplete={'off'}
                component={FormikTextField}
              />
              <ErrorMessage
                name="last_name"
                component="div"
                render={(msg) => (
                  <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                    {msg}
                  </Typography>
                )}
              />
            </Grid>
            <Grid item sx={{ marginTop: 2 }} xs={12}>
              <LoadingButton loading={isLoading} type="submit" variant="contained">
                Next
              </LoadingButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default UserNameRegStep;
