// TODO review it
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AuctionFunderList } from 'redux/buyInvoice/buyInvoice.type';
import { ResponseSuccess, ServerErrors } from 'redux/common/common.type';
import { BuyerDetails } from 'redux/invoice/invoice.type';
import axios, { handleServerErrors } from 'utils/axios.config';

// TODO review it, check response
const getInvoiceStatusList = createAsyncThunk<any, string, { rejectValue: ServerErrors }>(
  'getInvoiceStatusList',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<any>(
        `${process.env.REACT_APP_SERVER_URL}invoices/buy_invoice/status/list/${params}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

const updateBuyerDetailsById = createAsyncThunk<
  any,
  { id: number; payload: Partial<BuyerDetails> },
  { rejectValue: ServerErrors }
>('updateBuyerDetailsById', async (query, { rejectWithValue }) => {
  try {
    const { data } = await axios.put<any>(
      `${process.env.REACT_APP_SERVER_URL}invoices/invoice/buyer_details/?buyer_id=${query.id}`,
      query.payload
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

//TODO review it, check the payload
const updateFunderInvoiceApproval = createAsyncThunk<any, any, { rejectValue: ServerErrors }>(
  'updateFunderInvoiceApproval',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.put<any>(
        `${process.env.REACT_APP_SERVER_URL}invoices/buy_invoices/funder_invoice_approval/`,
        { ...payload }
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);
// TODO review it, check response, and payload
const rejectBasket = createAsyncThunk<any, any, { rejectValue: ServerErrors }>(
  'rejectBasket',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.put<any>(
        `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/auction/reject/`,
        { ...payload }
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO review it, check response, and payload
const createFunderBidNow = createAsyncThunk<any, any, { rejectValue: ServerErrors }>(
  'createFunderBidNow',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.put<any>(
        `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/auction/bid/create/`,
        { ...payload }
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO review it, check response
const getFunderList = createAsyncThunk<AuctionFunderList, void, { rejectValue: ServerErrors }>(
  'getFunderList',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<AuctionFunderList>(
        `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/auction/funders/`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

export default {
  getFunderList,
  createFunderBidNow,
  rejectBasket,
  updateFunderInvoiceApproval,
  getInvoiceStatusList,
  updateBuyerDetailsById,
};
