import { useState, useCallback } from 'react';

import { styled, useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import Card from '@mui/material/Card';
import EmailIcon from '@mui/icons-material/EmailSharp';
// routes
import { PATH_AUTH } from 'routes/paths';
// hooks
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
// components
import Page from 'components/Page';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import FormikTextField from 'components/formik/FormikTextField';
import * as Yup from 'yup';
import Link from '@mui/material/Link';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FacebookLoginSection from './FacebookLoginSection';
import useMediaQuery from '@mui/material/useMediaQuery';
import { authProps, LoginPropsData } from './loginConfig';
import Typography from '@mui/material/Typography';
import useAuth from 'hooks/useAuth';
import { UserManagementApi } from 'api';
import { useDispatch } from 'redux/store';
import { CommonErrorModalProps } from 'redux/common/common.type';
import CustomSnackbar from 'components/CustomSnackbar';

interface ImageProps {
  image?: string;
}
interface EmailProps {
  email: string;
  g_recaptcha_response?: string;
}

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
}));

const ValidationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
});

const ForgotPassword = () => {
  const [currentUserProps, setCurrentUserProps] = useState<LoginPropsData | undefined>(
    authProps.filter((f) => f.name === process.env.REACT_APP_TENANT_NAME)[0]
  );
  const [openSnackbar, setOpenSnackbar] = useState<CommonErrorModalProps>({ open: false });
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { recaptcha_enabled, captcha_public_key_v3 } = useAuth();
  const dispatch = useDispatch();

  // Create an event handler so you can call the verification on button click event or form submit
  const handleResetPasswordWithRecaptcha = useCallback(
    async (payload: EmailProps) => {
      if (recaptcha_enabled) {
        if (captcha_public_key_v3 && executeRecaptcha) {
          const token = await executeRecaptcha('login');
          if (token) {
            const data = {
              email: payload.email,
              g_recaptcha_response: token,
            };
            dispatch(UserManagementApi.resetPassword(data));
            // TODO confirm dialog
          } else {
            console.log('TODO response token not found');
          }
        } else {
          setOpenSnackbar({
            open: true,
            message_header: 'Server error',
            message: 'Server error, Please contact system administration',
            type: 'error',
          });
        }
      } else {
        const response = await dispatch(UserManagementApi.resetPassword(payload));
        if (UserManagementApi.resetPassword.fulfilled.match(response)) {
          response.payload?.success &&
            setOpenSnackbar({
              open: true,
              message: response.payload?.success?.message,
              message_header: response.payload?.success?.message_header,
              type: 'error',
            });
        }
        if (UserManagementApi.resetPassword.rejected.match(response)) {
          response.payload?.error &&
            setOpenSnackbar({
              open: true,
              message: response.payload?.error?.message,
              message_header: response.payload?.error?.message_header,
              type: 'error',
            });
        }
      }
    },
    [executeRecaptcha]
  );

  // TODO
  const initialValues = { email: '' } as EmailProps;

  const isMobile = useMediaQuery('(max-width:400px)', { noSsr: true });
  const isDesktop = !useMediaQuery((t: any) => t.breakpoints.between('xs', 'sm'));

  return (
    <Page title="Forgot password">
      {openSnackbar.open && (
        <CustomSnackbar
          open={openSnackbar.open}
          severityType={openSnackbar.type}
          message={openSnackbar.message as string}
          header={openSnackbar.message_header}
          onClose={() => {
            setOpenSnackbar({ open: false, message: undefined, message_header: undefined });
          }}
        />
      )}
      <Grid container spacing={2} sx={{ height: '100vh' }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            backgroundColor: (theme) => theme.palette.customColors?.appBackground,
            width: '100%',
          }}
        >
          <div style={{ maxWidth: isMobile ? '340px' : '440px' }}>
            <div
              style={{
                marginBottom: '16px',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              <img src={currentUserProps?.icon} alt="icon" />
            </div>
            <Card sx={{ padding: 4, borderRadius: '10px' }}>
              <Grid item>
                <Typography
                  variant="h5"
                  sx={{ textAlign: 'center', fontWeight: 700, marginTop: 2, marginBottom: 2 }}
                >
                  Forgot Password
                </Typography>
              </Grid>
              <Formik
                initialValues={initialValues}
                validationSchema={ValidationSchema}
                onSubmit={(values, actions) => {
                  actions.setSubmitting(false);
                  handleResetPasswordWithRecaptcha(values);
                }}
              >
                {(props) => (
                  <Form autoComplete="off" onSubmit={props.handleSubmit}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Field
                          size="medium"
                          name={`email`}
                          variant="standard"
                          placeholder="Company email address"
                          sx={{ backgroundColor: '#fff' }}
                          autoComplete={'off'}
                          component={FormikTextField}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <IconButton aria-label="email" edge="start">
                                  <EmailIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          render={(msg) => (
                            <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                              {msg}
                            </Typography>
                          )}
                        />
                      </Grid>
                      <Grid item sx={{ marginTop: 5, marginBottom: 2 }} xs={12}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            alignItems: 'flex-end',
                            padding: '0 45px',
                            margin: '15px 0 0',
                          }}
                        >
                          <Button type="submit" variant="contained">
                            Submit
                          </Button>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            alignItems: 'flex-end',
                            padding: '0 45px',
                            margin: '15px 0 0',
                          }}
                        >
                          <StyledLink variant="subtitle1" href={PATH_AUTH.login}>
                            Log in Instead
                          </StyledLink>
                        </div>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Card>
          </div>
        </Grid>
      </Grid>
    </Page>
  );
};

export default ForgotPassword;
