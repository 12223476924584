import { createSlice } from '@reduxjs/toolkit';
import { RepaymentState } from 'redux/repayment/repayment.type';
import { RepaymentApi } from 'api';

const initialState: RepaymentState = {
  isLoading: false,
};

const repaymentSlice = createSlice({
  name: 'repayment',
  initialState,
  reducers: {
    reset: (state) => {
      state = {
        isLoading: false,
        repaymentDetail: undefined,
        repaymentError: undefined,
      };
    },
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload;
    },

    setRepaymentError: (state, { payload }) => {
      state.repaymentError = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(RepaymentApi.getRepaymentDetails.pending, (state, action) => {
        state.isLoading = true;
        state.repaymentDetail = undefined;
      })
      .addCase(RepaymentApi.getRepaymentDetails.fulfilled, (state, action) => {
        state.repaymentDetail = action.payload;
        state.isLoading = false;
      })
      .addCase(RepaymentApi.getRepaymentDetails.rejected, (state, action) => {
        state.repaymentError = action.payload;
        state.isLoading = false;
      })
      .addCase(RepaymentApi.getSelectAllInvoiceData.pending, (state, action) => {
        state.isLoading = true;
        state.selectAllInvoiceData = undefined;
      })
      .addCase(RepaymentApi.getSelectAllInvoiceData.fulfilled, (state, action) => {
        state.selectAllInvoiceData = action.payload;
        state.isLoading = false;
      })
      .addCase(RepaymentApi.getSelectAllInvoiceData.rejected, (state, action) => {
        state.repaymentError = action.payload;
        state.isLoading = false;
      });
  },
});

export const { setIsLoading, setRepaymentError, reset } = repaymentSlice.actions;

export default repaymentSlice.reducer;
