import { createSlice } from '@reduxjs/toolkit';
import { HistoryState } from '../history/history.type';
import { HistoryApi } from 'api';

const initialState: HistoryState = {
  isLoading: false,
};

const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    setIsLoading: (state) => {
      state.isLoading = true;
    },
    reset: (state) => {
      state.isLoading = false;
    },
    setHistoryError: (state, action) => {
      state.historyError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(HistoryApi.getHistoryFilterData.pending, (state) => {
        state.isLoading = true;
        state.historyFilterData = undefined;
      })
      .addCase(HistoryApi.getHistoryFilterData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.historyFilterData = action.payload;
      })
      .addCase(HistoryApi.getHistoryFilterData.rejected, (state, action) => {
        state.isLoading = false;
        state.historyError = action.payload;
      })
      .addCase(HistoryApi.getTransactionFilter.pending, (state) => {
        state.isLoading = true;
        state.transactionFilter = undefined;
      })
      .addCase(HistoryApi.getTransactionFilter.fulfilled, (state, action) => {
        state.isLoading = false;
        state.transactionFilter = action.payload;
      })
      .addCase(HistoryApi.getTransactionFilter.rejected, (state, action) => {
        state.isLoading = false;
        state.historyError = action.payload;
      })
      .addCase(HistoryApi.getHistoryList.pending, (state) => {
        state.isLoading = true;
        state.historyListData = undefined;
      })
      .addCase(HistoryApi.getHistoryList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.historyListData = action.payload;
      })
      .addCase(HistoryApi.getHistoryList.rejected, (state, action) => {
        state.isLoading = false;
        state.historyError = action.payload;
      })
      .addCase(HistoryApi.getTransactionList.pending, (state) => {
        state.isLoading = true;
        state.transactionList = undefined;
      })
      .addCase(HistoryApi.getTransactionList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.transactionList = action.payload;
      })
      .addCase(HistoryApi.getTransactionList.rejected, (state, action) => {
        state.isLoading = false;
        state.historyError = action.payload;
      });
  },
});

export const { setIsLoading, reset, setHistoryError } = historySlice.actions;

export default historySlice.reducer;
