import { createAsyncThunk } from '@reduxjs/toolkit';
import { ServerErrors } from 'redux/common/common.type';
import {
  SurfScoreDistribution,
  SurfScoreMapData,
  SurfScoreSmartScoreList,
  SurfScoreVerificationData,
  BubbleData,
  CompanyData,
  CompanyDataPayload,
  GenerateSurfScorePayload,
  GenerateSurfScoreResponseSuccess,
  ResponseSuccess,
  SurfScoreVerificationResultData,
} from 'redux/surfScore/surfScore.type';
import axios, { handleServerErrors } from 'utils/axios.config';

const getSurfScoreDistribution = createAsyncThunk<
  SurfScoreDistribution,
  void,
  { rejectValue: ServerErrors }
>('getSurfScoreDistribution', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<SurfScoreDistribution>(
      `${process.env.REACT_APP_SERVER_URL}smartscore/surfscore/distribution/`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

const getSurfScoreSmartScoreList = createAsyncThunk<
  SurfScoreSmartScoreList,
  string,
  { rejectValue: ServerErrors }
>('getSurfScoreSmartScoreList', async (params, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<SurfScoreSmartScoreList>(
      `${process.env.REACT_APP_SERVER_URL}smartscore/surfscore/list/${params}`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

const getSurfScoreVerificationData = createAsyncThunk<
  SurfScoreVerificationData,
  string,
  { rejectValue: ServerErrors }
>('getSurfScoreVerificationData', async (params, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<SurfScoreVerificationData>(
      `${process.env.REACT_APP_SERVER_URL}smartscore/surfscore/needs_verification/${params}`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

const getBubbleData = createAsyncThunk<BubbleData, void, { rejectValue: ServerErrors }>(
  'getBubbleData',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<BubbleData>(
        `${process.env.REACT_APP_SERVER_URL}smartscore/surfscore/bubble/`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);
const getSurfScoreMapData = createAsyncThunk<
  SurfScoreMapData[],
  void,
  { rejectValue: ServerErrors }
>('getSurfScoreMapData', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<SurfScoreMapData[]>(
      `${process.env.REACT_APP_SERVER_URL}smartscore/surfscore/map/`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

const getCompanyDetails = createAsyncThunk<
  CompanyData,
  number,
  { rejectValue: ServerErrors }
>('getCompanyDetails', async (params, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<CompanyData>(
      `${process.env.REACT_APP_BASE_URL}/smartscore/smartscore/details/?global_company_id=${params}`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

const generateSurfScore = createAsyncThunk<
  GenerateSurfScoreResponseSuccess, 
  GenerateSurfScorePayload, 
  { rejectValue: ServerErrors }
  >('generateSurfScore', async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post<GenerateSurfScoreResponseSuccess>(
        `${process.env.REACT_APP_BASE_URL}smartscore/generate-surfscore/`,
        payload
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

const generateSurfScoreIndividual = createAsyncThunk<
  GenerateSurfScoreResponseSuccess, 
  SurfScoreVerificationResultData[], 
  { rejectValue: ServerErrors }
  >('generateSurfScoreIndividual', async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post<GenerateSurfScoreResponseSuccess>(
        `${process.env.REACT_APP_BASE_URL}smartscore/generate-surfscore/`,
        payload
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

const uploadIndividualCompany = createAsyncThunk<
  ResponseSuccess,
  FormData,
  { rejectValue: ServerErrors }
>('uploadIndividualCompany', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await axios.post<ResponseSuccess>(
      `${process.env.REACT_APP_BASE_URL}smartscore/generate-surfscore/mass-upload/`,
      payload
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

export default {
  getSurfScoreDistribution,
  getSurfScoreSmartScoreList,
  getSurfScoreVerificationData,
  getSurfScoreMapData,
  getBubbleData,
  getCompanyDetails,
  generateSurfScore,
  uploadIndividualCompany,
  generateSurfScoreIndividual,
};
