import { createAsyncThunk } from '@reduxjs/toolkit';
import { ServerErrors, ResponseSuccess, CommonSuccessResponse } from 'redux/common/common.type';
import {
  UserProfileData,
  CreatePasswordPayload,
  CreatePhoneNumberPayload,
  CreateSecurityQuestionPayload,
  ProfileFieldsData,
  SendOTPPayload,
  VideoTutorialData,
  EmailValidatePostPayload,
  CompanyValidationPayload,
  ChangePasswordPayload,
  SecurityQuestionsListData,
  TermsAndConditions,
  SellerAgreementRes,
  CompanyProfileData,
} from 'redux/userManagement/userManagement.type';
import axios, { handleServerErrors } from 'utils/axios.config';

const getUserProfile = createAsyncThunk<UserProfileData, void, { rejectValue: ServerErrors }>(
  'getUserProfile',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<UserProfileData>(
        `${process.env.REACT_APP_SERVER_URL}usermgmt/user_profile/`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

const updateUserProfile = createAsyncThunk<
  CommonSuccessResponse,
  FormData,
  { rejectValue: ServerErrors }
>('updateUserProfile', async (payload, { rejectWithValue }) => {
  try {
    const data = await fetch(`${process.env.REACT_APP_SERVER_URL}usermgmt/user_profile/`, {
      method: 'PUT',
      body: payload,
      credentials: 'include',
      headers: {
        apikey: `${process.env.REACT_APP_SERVER_APIKEY}`,
      },
    }).then((res) => res.json());

    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

const sessionSecurityPing = createAsyncThunk<number, void, { rejectValue: ServerErrors }>(
  'sessionSecurityPing',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<number>(
        `${process.env.REACT_APP_SERVER_URL}session_security/ping/`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

const getProfileFields = createAsyncThunk<ProfileFieldsData, string, { rejectValue: ServerErrors }>(
  'getProfileFields',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<ProfileFieldsData>(
        `${process.env.REACT_APP_SERVER_URL}usermgmt/profile-fields/${params}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

const getVideoTutorial = createAsyncThunk<VideoTutorialData, void, { rejectValue: ServerErrors }>(
  'getVideoTutorial',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<VideoTutorialData>(
        `${process.env.REACT_APP_SERVER_URL}usermgmt/video_tutorial/`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO review it
const getRequestProfileApproval = createAsyncThunk<any, void, { rejectValue: ServerErrors }>(
  'getRequestProfileApproval',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<any>(
        `${process.env.REACT_APP_SERVER_URL}usermgmt/request_profile_approval/`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

const validateEmailPost = createAsyncThunk<
  CommonSuccessResponse,
  EmailValidatePostPayload,
  { rejectValue: ServerErrors }
>('validateEmailPost', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await axios.post<CommonSuccessResponse>(
      `${process.env.REACT_APP_SERVER_URL}usermgmt/validate_registrant/email/`,
      { ...payload },
      {
        headers: {
          'Content-type': 'application/json',
        },
      }
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

const validateCompanyNamePost = createAsyncThunk<
  any,
  CompanyValidationPayload,
  { rejectValue: ServerErrors }
>('ValidateCompanyNamePost', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await axios.post<any>(
      `${process.env.REACT_APP_SERVER_URL}usermgmt/validate_company/`,
      { ...payload }
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

// TODO review it,
const createUserPassword = createAsyncThunk<
  any,
  CreatePasswordPayload,
  { rejectValue: ServerErrors }
>('createUserPassword', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await axios.post<any>(
      `${process.env.REACT_APP_SERVER_URL}invoiceexchange/user_password/`,
      { ...payload }
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

// TODO review it,
const createSecurityQuestion = createAsyncThunk<
  any,
  CreateSecurityQuestionPayload,
  { rejectValue: ServerErrors }
>('createSecurityQuestion', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await axios.post<any>(
      `${process.env.REACT_APP_SERVER_URL}invoiceexchange/security_question/`,
      payload
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

// TODO review it,
const requestOTP = createAsyncThunk<any, SendOTPPayload, { rejectValue: ServerErrors }>(
  'sendOTP',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post<any>(`${process.env.REACT_APP_SERVER_URL}usermgmt/otp/`, {
        ...payload,
      });
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO review it,
const createPhoneNumber = createAsyncThunk<
  any,
  CreatePhoneNumberPayload,
  { rejectValue: ServerErrors }
>('createPhoneNumber', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await axios.post<any>(
      `${process.env.REACT_APP_SERVER_URL}invoiceexchange/phone_number/`,
      {
        ...payload,
      }
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

// TODO, not used anymore, review it, check payload
const getTermAndConditions = createAsyncThunk<
  TermsAndConditions,
  void,
  { rejectValue: ServerErrors }
>('getTermAndConditions', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<TermsAndConditions>(
      `${process.env.REACT_APP_SERVER_URL}appui/terms/`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});
// TODO
const changePassword = createAsyncThunk<
  CommonSuccessResponse,
  ChangePasswordPayload,
  { rejectValue: ServerErrors }
>('changePassword', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await axios.put<CommonSuccessResponse>(
      `${process.env.REACT_APP_SERVER_URL}usermgmt/change_password/`,
      payload
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

const getSecurityQuestionsList = createAsyncThunk<
  SecurityQuestionsListData,
  void,
  { rejectValue: ServerErrors }
>('getSecurityQuestionsList', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<SecurityQuestionsListData>(
      `${process.env.REACT_APP_SERVER_URL}usermgmt/security_questions_list/`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

// TODO review
const saveFeedBack = createAsyncThunk<any, any, { rejectValue: ServerErrors }>(
  'saveFeedBack',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post<any>(
        `${process.env.REACT_APP_SERVER_URL}usermgmt/save_feedback_options/`,
        payload
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO review
const verifyCompany = createAsyncThunk<any, any, { rejectValue: ServerErrors }>(
  'verifyCompany',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post<any>(
        `${process.env.REACT_APP_SERVER_URL}invoiceexchange/verify-kompany/`,
        payload
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO review
const getExchangeTokenVerify = createAsyncThunk<any, string, { rejectValue: ServerErrors }>(
  'getExchangeTokenVerify',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<any>(
        `${process.env.REACT_APP_SERVER_URL}invoiceexchange/verify/${params}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO
const resendEmailVerification = createAsyncThunk<any, any, { rejectValue: ServerErrors }>(
  'resendEmailVerification',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post<any>(
        `${process.env.REACT_APP_SERVER_URL}invoiceexchange/resend_verification/`,
        payload
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO review, consider to include mbe
const resetPassword = createAsyncThunk<
  ResponseSuccess,
  {
    email: string;
    g_recaptcha_response?: string;
  },
  { rejectValue: ServerErrors }
>('resetPassword', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await axios.post<any>(
      `${process.env.REACT_APP_SERVER_URL}usermgmt/reset-password/`,
      payload
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

const getFeedbackOptions = createAsyncThunk<any, void, { rejectValue: ServerErrors }>(
  'getFeedbackOptions',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<any>(
        `${process.env.REACT_APP_SERVER_URL}usermgmt/feedback_options/`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

const getVerifyResetPassword = createAsyncThunk<any, string, { rejectValue: ServerErrors }>(
  'getVerifyResetPassword',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<any>(
        `${process.env.REACT_APP_SERVER_URL}usermgmt/verify-reset-password/${params}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO review,
const saveResetPassword = createAsyncThunk<any, any, { rejectValue: ServerErrors }>(
  'saveResetPassword',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post<any>(
        `${process.env.REACT_APP_SERVER_URL}usermgmt/save-reset-password/`,
        payload
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

const getLegalConditions = createAsyncThunk<any, void, { rejectValue: ServerErrors }>(
  'getLegalConditions',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<any>(`${process.env.REACT_APP_SERVER_URL}appui/agreement/`);
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO review,
const getSellerFinancialProfileDetails = createAsyncThunk<
  any,
  string,
  { rejectValue: ServerErrors }
>('getSellerFinancialProfileDetails', async (params, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<any>(
      `${process.env.REACT_APP_SERVER_URL}usermgmt/seller_financial_profile/${params}`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

const updateCompanyAgreement = createAsyncThunk<
  CommonSuccessResponse,
  any,
  { rejectValue: ServerErrors }
>('updateCompanyAgreement', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await axios.put<CommonSuccessResponse>(
      `${process.env.REACT_APP_SERVER_URL}/usermgmt/company_agreement/`,
      payload
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

// not used till now anywhere
const getSellerAgreement = createAsyncThunk<
  SellerAgreementRes,
  string,
  { rejectValue: ServerErrors }
>('getSellerAgreement', async (params, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<SellerAgreementRes>(
      `${process.env.REACT_APP_SERVER_URL}usermgmt/download_document/${params}`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

const getCompanyProfile = createAsyncThunk<CompanyProfileData, void, { rejectValue: ServerErrors }>(
  'getCompanyProfile',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<CompanyProfileData>(
        `${process.env.REACT_APP_SERVER_URL}usermgmt/company_profile/`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

const updateCompanyProfile = createAsyncThunk<
  any,
  { file: File; body: string },
  { rejectValue: ServerErrors }
>('updateCompanyProfile', async (payload, { rejectWithValue }) => {
  try {
    const bodyFormData = new FormData();
    if (payload.file) {
      bodyFormData.append('logo', URL.createObjectURL(payload.file));
    }
    bodyFormData.append('data', payload.body);

    const data = await fetch(`${process.env.REACT_APP_SERVER_URL}usermgmt/company_profile/`, {
      method: 'PUT',
      body: bodyFormData,
      credentials: 'include',
      headers: {
        apikey: `${process.env.REACT_APP_SERVER_APIKEY}`,
      },
    }).then((res) => res.json());

    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

export default {
  getSellerAgreement,
  getFeedbackOptions,
  resetPassword,
  getVerifyResetPassword,
  saveResetPassword,
  getLegalConditions,
  getSellerFinancialProfileDetails,
  sessionSecurityPing,
  getUserProfile,
  updateUserProfile,
  getVideoTutorial,
  getProfileFields,
  requestOTP,
  createPhoneNumber,
  createSecurityQuestion,
  createUserPassword,
  changePassword,
  updateCompanyAgreement,
  getRequestProfileApproval,
  validateEmailPost,
  validateCompanyNamePost,
  getSecurityQuestionsList,
  getTermAndConditions,
  getExchangeTokenVerify,
  verifyCompany,
  saveFeedBack,
  resendEmailVerification,
  updateCompanyProfile,
  getCompanyProfile,
};
