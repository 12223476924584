import { Box, IconButton, styled } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { ReactNode } from 'react';
import CommonBootstrapDialogTitle, { BootstrapDialog } from 'components/CommonBootstrapDialogTitle';

interface OwnProps {
  message?: string | ReactNode;
  children?: ReactNode;
  onClose: () => void;
  handleAction?: () => void;
  open: boolean;
  title?: string;
  type?: string;
  showCloseIcon?: boolean;
}

const DialogCustom = styled('div')(({ theme }) => ({
  padding: '8px',
  borderTop: '4px solid',
}));

const CommonMessageModal = ({
  open,
  onClose,
  message,
  children,
  type,
  title,
  showCloseIcon,
  handleAction,
}: OwnProps) => {
  const handleClose = () => {
    onClose();
  };
  return (
    <BootstrapDialog open={open} onClose={handleClose} maxWidth={'xs'} fullWidth>
      <CommonBootstrapDialogTitle title={title} onClose={onClose} />
      <DialogContent>
        <Typography variant="subtitle2">{message}</Typography>{' '}
      </DialogContent>
      {children && (
        <DialogActions>
          <Button
            variant={type === ('success' || 'error') ? 'outlined' : 'contained'}
            color={type === 'success' ? 'success' : type === 'error' ? 'error' : 'primary'}
            onClick={handleAction}
            sx={{
              minHeight: '40px',
              minWidth: '92px',
              fontWeight: (theme) => theme.typography.fontWeightBold,
            }}
          >
            {children}
          </Button>
        </DialogActions>
      )}
    </BootstrapDialog>
  );
};

export default CommonMessageModal;
