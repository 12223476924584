import { createAsyncThunk } from '@reduxjs/toolkit';

import { ServerErrors } from 'redux/common/common.type';
import axios, { handleServerErrors } from 'utils/axios.config';

// TODO review it,
const saveAuction = createAsyncThunk<any, any, { rejectValue: ServerErrors }>(
  'saveAuction',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post<any>(
        `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/auction/post/`,
        { ...payload }
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO review it,
const changeInvoiceStatus = createAsyncThunk<any, any, { rejectValue: ServerErrors }>(
  'changeInvoiceStatus',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.put<any>(
        `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/auction/change-status/`,
        payload
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO review it,
const funderWithdrawBid = createAsyncThunk<any, any, { rejectValue: ServerErrors }>(
  'funderWithdrawBid',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.put<any>(
        `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/auction/bid/withdraw/`,
        payload
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO review it,
const postRequestDocuments = createAsyncThunk<any, any, { rejectValue: ServerErrors }>(
  'postRequestDocuments',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post<any>(
        `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/auction/request-docs/`,
        payload
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);
// TODO review it,
const getBidDetails = createAsyncThunk<any, string, { rejectValue: ServerErrors }>(
  'getBidDetails',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<any>(
        `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/auction/bid/details/${params}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO review it,
// START: FUNDING APIs
const saveRepayDue = createAsyncThunk<any, any, { rejectValue: ServerErrors }>(
  'saveRepayDue',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post<any>(
        `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/auction/save_repay_due/`,
        payload
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO review it,
const startFundProcess = createAsyncThunk<any, any, { rejectValue: ServerErrors }>(
  'startFundProcess',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post<any>(
        `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/auction/start_fund_process/`,
        payload
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO review it,
const completeFunding = createAsyncThunk<any, any, { rejectValue: ServerErrors }>(
  'completeFunding',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post<any>(
        `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/auction/fund_complete/`,
        payload
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO review it,
const getIRRData = createAsyncThunk<any, string, { rejectValue: ServerErrors }>(
  'getIRRData',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<any>(
        `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/auction/calculate-irr/${params}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO review it,
const updateViewAuction = createAsyncThunk<any, any, { rejectValue: ServerErrors }>(
  'updateViewAuction',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.put<any>(
        `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/auction/status-viewed/`,
        payload
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);
export default {
  updateViewAuction,
  getIRRData,
  completeFunding,
  startFundProcess,
  saveRepayDue,
  getBidDetails,
  postRequestDocuments,
  funderWithdrawBid,
  changeInvoiceStatus,
  saveAuction,
};
