import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ActiveInvoiceData,
  ActiveInvoiceFilterData,
  setActivityStatusData,
} from 'redux/active/active.type';
import { ServerErrors } from 'redux/common/common.type';
import axios, { handleServerErrors } from 'utils/axios.config';

const getActiveInvoiceList = createAsyncThunk<
  ActiveInvoiceData,
  string,
  { rejectValue: ServerErrors }
>('getActiveInvoiceList', async (params, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<ActiveInvoiceData>(
      `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/invoice_visibility/list/${params}`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

const getActiveInvoiceFilterList = createAsyncThunk<
  ActiveInvoiceFilterData,
  void,
  { rejectValue: ServerErrors }
>('getActiveInvoiceFilterList', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<ActiveInvoiceFilterData>(
      `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/invoice_visibility/list/filter/`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

const setActivityStatus = createAsyncThunk<
  any,
  setActivityStatusData,
  { rejectValue: ServerErrors }
>('changeInvoiceStatus', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await axios.put<setActivityStatusData>(
      `${process.env.REACT_APP_SERVER_URL}approvals/approval_actions/`,
      payload
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

export default {
  getActiveInvoiceList,
  getActiveInvoiceFilterList,
  setActivityStatus,
};
