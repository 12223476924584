import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

interface OwnProps {
  severityType?: AlertColor;
  message?: string;
  header?: string;
  open: boolean;
  onClose: () => void;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
// TODO review
export default function CustomSnackbar({ severityType, message, header, open, onClose }: OwnProps) {
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    onClose();
  };

  return (
    <Stack spacing={2} sx={{ maxWidth: '400px' }}>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Alert onClose={handleClose} severity={severityType} sx={{ maxWidth: '400px' }}>
          {header && <AlertTitle>{header}</AlertTitle>}
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
