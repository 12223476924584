import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { handleServerErrors } from 'utils/axios.config';
import { ServerErrors } from '../redux/common/common.type';
import { ConfigData } from '../redux/config/config.type';

const getTenantConfig = createAsyncThunk<ConfigData, void, { rejectValue: ServerErrors }>(
  'getTenantConfiguration',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<ConfigData>('/config/avalon_config.json');
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);
export default {
  getTenantConfig,
};
