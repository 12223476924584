import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent/CardContent';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { BankInformationData } from 'redux/bank/bank.type';
import CardHeader from '@mui/material/CardHeader';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { useState } from 'react';

export interface OwnProps {
  data?: BankInformationData;
  index: number;
  role: string;
  bankOptions?: string[];
  ach_debit_allowed?: boolean;
  onMenuChange: (path: string) => void;
  handleDeleteAccount: (path: string) => void;
}

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  minHeight: '200px',
  padding: 3,
}));
const StyledBankingCard = styled(CardContent)(({ theme }) => ({
  minWidth: 275,
  background: theme.palette.common.white,
  borderRadius: '6px',
  '&.active': {
    borderTop: `7px solid ${theme.palette.primary.main}`,
  },
}));

const BankItemView = ({
  data,
  role,
  index,
  bankOptions,
  ach_debit_allowed,
  onMenuChange,
  handleDeleteAccount,
}: OwnProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuSelected = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string,
    id?: number
  ) => {
    //  return this.http.get(`/api/payments/payment_account/make_default/?account_id=` + accountId + `&default_type=debit&verification_status=` + action);
    if (newValue === 'Default for Funding') {
      onMenuChange(`?account_id= ${id}`);
      // return this.http.get(`/api/payments/payment_account/make_default/?account_id=` + accountId);
    } else if (newValue === 'Make Default for Repayment') {
      onMenuChange(`?account_id=${id}&default_type=debit&verification_status=accept`);
    } else if (newValue === 'Delete') {
      // return this.http.delete(`/api/payments/delete/payment_account/?account_id=` + id);
      handleDeleteAccount(`?account_id=${id}`);
    }
  };

  const checkAccountStatus = () =>
    (data?.deposit_status === 'verified' || data?.deposit_status === 'not_required') &&
    data?.bank_verification_status === '';

  return (
    <Grid item xs={12} sm={12} md={6} lg={6}>
      <StyledBankingCard className={data?.is_default ? 'active' : ''}>
        <CardHeader
          action={
            <>
              <IconButton aria-label="settings" onClick={handleClick}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {!data?.is_default &&
                  (data?.deposit_status === 'verified' ||
                    data?.deposit_status === null ||
                    data?.deposit_status === 'not_required') && (
                    <MenuItem
                      onClick={(e) => {
                        handleMenuSelected(e, 'Default for Funding', data?.id);
                      }}
                    >
                      Make Default for Funding
                    </MenuItem>
                  )}

                {checkAccountStatus() &&
                  !data?.default_pay &&
                  role === 'seller' &&
                  bankOptions?.includes('automatic') &&
                  ach_debit_allowed && (
                    <MenuItem
                      onClick={(e) => {
                        handleMenuSelected(e, 'Make Default for Repayment', data?.id);
                      }}
                    >
                      Make Default for Repayment
                    </MenuItem>
                  )}
                <MenuItem
                  onClick={(e) => {
                    handleMenuSelected(e, 'Delete', data?.id);
                  }}
                >
                  Delete
                </MenuItem>
              </Menu>
            </>
          }
          subheader={
            <Stack direction="row">
              <Typography
                variant="body2"
                fontWeight={(theme) => theme.typography.fontWeightBold}
                paddingRight={'10px'}
              >
                {data?.deposit_status === 'pending' || data?.deposit_status === 'failed'
                  ? 'N/A'
                  : data?.account_number}
              </Typography>
              {index === 0 && (
                <>
                  {data?.is_default && (
                    <Typography variant="body2" paddingRight={'10px'}>
                      Default for Funding
                    </Typography>
                  )}
                  {role === 'seller' &&
                    bankOptions?.includes('automatic') &&
                    ach_debit_allowed &&
                    data?.default_pay &&
                    data?.bank_verification_status === 'completed' && (
                      <Typography variant="body2"> Default for Debit Repayment</Typography>
                    )}
                </>
              )}
            </Stack>
          }
          subheaderTypographyProps={{ fontSize: '15px' }}
        />
        <Divider orientation="horizontal" flexItem />
        <StyledCardContent>
          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography variant="body2">Type</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} textAlign="right">
              <Typography variant="body2">
                {data?.sub_type === 'savings' ? 'Saving' : 'Checking'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography variant="body2">Routing Number</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} textAlign="right">
              <Typography variant="body2">
                {data?.deposit_status === 'pending' || data?.deposit_status === 'failed'
                  ? 'N/A'
                  : data?.routing_number}
              </Typography>
            </Grid>
          </Grid>
        </StyledCardContent>
      </StyledBankingCard>
    </Grid>
  );
};
export default BankItemView;
