import { createSlice } from '@reduxjs/toolkit';
import { NotificationState } from '../notification/notification.type';

// initial state
export const initialState: NotificationState = {};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    notification: (state, { payload }) => {
      state.notificationData = payload;
    },
  },
});

export const { notification } = notificationSlice.actions;

export default notificationSlice.reducer;
