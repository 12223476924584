import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import LoginCheck from '../security/LoginCheck';
import useAuth from 'hooks/useAuth';
import AuthGuard from 'security/AuthGuard';
import RoleCheck from 'security/RoleCheck';
import LoginPage from 'pages/auth/LoginPage';

import DashboardLayout from 'layouts/DashboardLayout';
import { DASHBOARD_PAGES, PATH_AUTH } from './paths';
import Container from '@mui/material/Container';
import DocumentLibraryPage from 'pages/settings/DocumentLibraryPage';
import BankingInformation from 'pages/settings/Banking/BankingInformationPage';
import FinancialProfilePage from 'pages/settings/FinancialProfilePage';
import AdminPage from 'pages/settings/AdminPage';
import SignUpPage from 'pages/auth/signUp/SignUpPage';
import ForgotPassword from 'pages/auth/ForgotPassword';
import Page500 from 'pages/Page500';
import NotFound from 'pages/Page404';
import LoadingProgress from 'components/LoadingProgress';

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? (
    <Suspense fallback={<LoadingProgress />}>
      <Component {...props} />
    </Suspense>
  ) : (
    <></>
  );
};

const FunderDashboardPage = Loadable(lazy(() => import('pages/funderDashboard/FunderDashboardPage')));
const HistoryPage = Loadable(lazy(() => import('pages/HistoryPage')));
const ContactsPage = Loadable(lazy(() => import('pages/ContactsPage')));
const RepaymentPage = Loadable(lazy(() => import('pages/Repayment/RepaymentPage')));
const ParametersPage = Loadable(lazy(() => import('pages/ParametersPage')));
const SuRFReportPage = Loadable(lazy(() => import('pages/SuRFReportPage')));
const UserProfilePage = Loadable(lazy(() => import('pages/settings/UserProfilePage')));
const CompanyProfilePage = Loadable(lazy(() => import('pages/settings/CompanyProfilePage')));
const AccountingSystemPage = Loadable(lazy(() => import('pages/settings/AccountingSystemPage')));
const PasswordPage = Loadable(lazy(() => import('pages/settings/PasswordPage')));
const ActivePage = Loadable(lazy(() => import('pages/ActivePage')));
const MarketplacePage = Loadable(lazy(() => import('pages/marketplace/MarketplacePage')));
const DashboardPage = Loadable(lazy(() => import('pages/DashboardPage')));
const CreateReceivablesForm = Loadable(lazy(() => import('components/CreateReceivablesForm')));
const SuccessLanderPage = Loadable(lazy(() => import('pages/auth/signUp/SuccessLander')));

// review following pages / delete
const PostAcceptSellerTermsPage = Loadable(
  lazy(() => import('pages/invitations/PostAcceptSellerTermsPage'))
);
const PostRegistrationAcceptTermsPage = Loadable(
  lazy(() => import('pages/invitations/PostRegistrationAcceptTermsPage'))
);
const PostSetupPhonePage = Loadable(lazy(() => import('pages/invitations/PostSetupPhonePage')));

const Router = () => {
  return useRoutes([
    {
      path: '/auth/login/',
      element: (
        <LoginCheck>
          <LoginPage />
        </LoginCheck>
      ),
    },
    {
      path: '/auth/login?',
      element: (
        <LoginCheck>
          <LoginPage />
        </LoginCheck>
      ),
    },
    {
      path: '/auth/sign-up',
      element: (
        <LoginCheck>
          <SignUpPage />
        </LoginCheck>
      ),
    },
    {
      path: '/auth/invite-landing/:key',
      element: <SignUpPage />,
    },
    {
      path: '/auth/user-invite/:token',
      element: <SignUpPage />,
    },
    {
      path: '/auth/signup-success-lander',
      element: <SuccessLanderPage />,
    },

    // {
    //   path: '/invite-phone-setup',
    //   element: (
    //     <AuthGuard>
    //       <PostSetupPhonePage />
    //     </AuthGuard>
    //   ),
    // },
    // {
    //   path: '/invite-accept-reg-terms',
    //   element: (
    //     <AuthGuard>
    //       <PostRegistrationAcceptTermsPage />
    //     </AuthGuard>
    //   ),
    // },
    // {
    //   path: '/invite-accept-seller-terms',
    //   element: (
    //     <AuthGuard>
    //       <PostAcceptSellerTermsPage />
    //     </AuthGuard>
    //   ),
    // },
    {
      //TODO why
      path: '/auth/buyer-terms-sign-off/:key',
      element: (
        <LoginCheck>
          <SignUpPage />
        </LoginCheck>
      ),
    },

    {
      path: '/auth/forgot-password',
      element: (
        <LoginCheck>
          <ForgotPassword />
        </LoginCheck>
      ),
    },
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'marketplace',
          element: (
            <RoleCheck
              accessibleRoles={['seller']}
              allowedTenants={['Avalon Core', 'City', 'COD', 'FB', 'Angelsden', 'EG']}
            >
              <MarketplacePage />
            </RoleCheck>
          ),
        },
        {
          path: 'marketplace?',
          element: (
            <RoleCheck
              accessibleRoles={['seller']}
              allowedTenants={['Avalon Core', 'City', 'COD', 'FB', 'Angelsden', 'EG']}
            >
              <MarketplacePage />
            </RoleCheck>
          ),
        },
        {
          path: 'dashboard',
          element: (
            <RoleCheck
              accessibleRoles={['seller']}
              allowedTenants={['Avalon Core', 'City', 'COD', 'FB', 'Angelsden', 'EG']}
            >
              <DashboardPage />
            </RoleCheck>
          ),
        },
        {
          path: '/dashboard',
          element: (
            <RoleCheck
              accessibleRoles={['seller']}
              allowedTenants={['Avalon Core', 'City', 'COD', 'FB', 'Angelsden', 'EG']}
            >
              <DashboardPage />
            </RoleCheck>
          ),
        },
        {
          path: 'marketplace/invoice/list/:invoice_id/:redirect_url',
          element: (
            <RoleCheck
              accessibleRoles={['seller']}
              allowedTenants={['Avalon Core', 'City', 'COD', 'FB', 'Angelsden', 'EG']}
            >
              <MarketplacePage />
            </RoleCheck>
          ),
        },
        {
          path: 'marketplace/invoice/list/:invoice_id1',
          element: (
            <RoleCheck
              accessibleRoles={['seller']}
              allowedTenants={['Avalon Core', 'City', 'COD', 'FB', 'Angelsden', 'EG']}
            >
              <MarketplacePage />
            </RoleCheck>
          ),
        },
        {
          path: 'marketplace/sell-invoice/:searchText/:invoiceType',
          element: (
            <RoleCheck
              accessibleRoles={['seller']}
              allowedTenants={['Avalon Core', 'City', 'COD', 'FB', 'Angelsden', 'EG']}
            >
              <MarketplacePage />
            </RoleCheck>
          ),
        },
        {
          path: 'marketplace/buy-invoice/:searchText1/:invoiceType1',
          element: (
            <RoleCheck
              accessibleRoles={['seller']}
              allowedTenants={['Avalon Core', 'City', 'COD', 'FB', 'Angelsden', 'EG']}
            >
              <MarketplacePage />
            </RoleCheck>
          ),
        },
        {
          path: 'overview',
          element: (
            <RoleCheck
              accessibleRoles={['funder']}
              allowedTenants={['Avalon Core', 'City', 'COD', 'FB', 'Angelsden', 'EG']}
            >
              <FunderDashboardPage />
            </RoleCheck>
          ),
        },
        {
          path: 'repayment',
          element: (
            <RoleCheck
              accessibleRoles={['funder', 'seller']}
              allowedTenants={['Avalon Core', 'City', 'COD', 'FB', 'Angelsden', 'EG']}
            >
              <RepaymentPage />
            </RoleCheck>
          ),
        },
        {
          path: 'repayment/v2/repayment/list/:invoiceSearch/:invoiceTypeRepayment',
          element: (
            <RoleCheck
              accessibleRoles={['funder', 'seller']}
              allowedTenants={['Avalon Core', 'City', 'COD', 'FB', 'Angelsden', 'EG']}
            >
              <RepaymentPage />
            </RoleCheck>
          ),
        },
        {
          path: 'history',
          element: (
            <RoleCheck
              accessibleRoles={['funder', 'seller']}
              allowedTenants={['Avalon Core', 'City', 'COD', 'FB', 'Angelsden', 'EG']}
            >
              <HistoryPage />
            </RoleCheck>
          ),
        },
        {
          path: 'history/history/:invoiceSearchHistory',
          element: (
            <RoleCheck
              accessibleRoles={['funder', 'seller']}
              allowedTenants={['Avalon Core', 'City', 'COD', 'FB', 'Angelsden', 'EG']}
            >
              <HistoryPage />
            </RoleCheck>
          ),
        },
        {
          path: 'contacts',
          element: (
            <RoleCheck
              accessibleRoles={['funder', 'seller']}
              allowedTenants={['Avalon Core', 'City', 'COD', 'FB', 'Angelsden', 'EG']}
            >
              <ContactsPage />
            </RoleCheck>
          ),
        },
        {
          path: 'parameters',
          element: (
            <RoleCheck
              accessibleRoles={['funder']}
              allowedTenants={['Avalon Core', 'City', 'COD', 'FB', 'Angelsden', 'EG']}
            >
              <ParametersPage />
            </RoleCheck>
          ),
        },
        {
          path: 'surf-report',
          element: (
            <RoleCheck accessibleRoles={['funder']} allowedTenants={['City']}>
              <SuRFReportPage />
            </RoleCheck>
          ),
        },
        {
          path: 'active',
          element: (
            <RoleCheck
              accessibleRoles={['funder']}
              allowedTenants={['Avalon Core', 'City', 'COD', 'FB']}
            >
              <ActivePage />
            </RoleCheck>
          ),
        },
        {
          path: 'settings',
          children: [
            { element: <Navigate to="/dashboard/settings/company-profile" replace />, index: true },
            {
              path: 'company-profile',
              element: (
                <RoleCheck
                  accessibleRoles={['funder', 'seller']}
                  allowedTenants={['Avalon Core', 'City', 'COD', 'FB', 'Angelsden', 'EG']}
                >
                  <CompanyProfilePage />
                </RoleCheck>
              ),
            },
            {
              path: 'user-profile',
              element: (
                <RoleCheck
                  accessibleRoles={['funder', 'seller']}
                  allowedTenants={['Avalon Core', 'City', 'COD', 'FB', 'Angelsden', 'EG']}
                >
                  <UserProfilePage />
                </RoleCheck>
              ),
            },
            {
              path: 'password',
              element: (
                <RoleCheck
                  accessibleRoles={['funder', 'seller']}
                  allowedTenants={['Avalon Core', 'City', 'COD', 'FB', 'Angelsden', 'EG']}
                >
                  <PasswordPage />
                </RoleCheck>
              ),
            },
            {
              path: 'accounting-system',
              element: (
                <RoleCheck
                  accessibleRoles={['seller']}
                  allowedTenants={['Avalon Core', 'City', 'COD', 'FB', 'Angelsden', 'EG']}
                >
                  <AccountingSystemPage />
                </RoleCheck>
              ),
            },
            {
              path: 'accounting-system/create-invoice',
              element: (
                <RoleCheck
                  accessibleRoles={['seller']}
                  allowedTenants={['Avalon Core', 'City', 'COD', 'FB', 'Angelsden', 'EG']}
                >
                  <CreateReceivablesForm />
                </RoleCheck>
              ),
            },
            {
              path: 'document-library',
              element: (
                <RoleCheck
                  accessibleRoles={['seller']}
                  allowedTenants={['Avalon Core', 'City', 'COD', 'FB', 'Angelsden', 'EG']}
                >
                  <DocumentLibraryPage />{' '}
                </RoleCheck>
              ),
            },
            {
              path: 'document-library/v2/account/document-library/:defaultState',
              element: (
                <RoleCheck
                  accessibleRoles={['seller']}
                  allowedTenants={['Avalon Core', 'City', 'COD', 'FB', 'Angelsden', 'EG']}
                >
                  <DocumentLibraryPage />{' '}
                </RoleCheck>
              ),
            },
            {
              path: 'banking-information',
              element: (
                <RoleCheck
                  accessibleRoles={['seller', 'funder']}
                  allowedTenants={['Avalon Core', 'City', 'COD', 'FB', 'Angelsden', 'EG']}
                >
                  <BankingInformation />
                </RoleCheck>
              ),
            },
            {
              path: 'financial-profile',
              element: (
                <RoleCheck
                  accessibleRoles={['seller']}
                  allowedTenants={['Avalon Core', 'City', 'COD', 'FB', 'Angelsden', 'EG']}
                >
                  <FinancialProfilePage />
                </RoleCheck>
              ),
            },
            {
              path: 'admin',
              element: (
                <RoleCheck
                  accessibleRoles={['seller', 'funder']}
                  allowedTenants={['Avalon Core', 'City', 'COD', 'FB', 'Angelsden', 'EG']}
                >
                  <AdminPage />
                </RoleCheck>
              ),
            },
          ],
        },
      ],
    },

    { path: '/', element: <Navigate to={PATH_AUTH.login} replace /> },
    { path: '*', element: <Navigate to="/404" replace /> },
    { path: '500', element: <Page500 /> },
    { path: '404', element: <NotFound /> },
  ]);
};
export default Router;
