import { Container, Grid, Typography, Button } from '@mui/material';

interface OwnProps {
  handleNext: () => void;
  appName: string;
}
const InviteLandingView = ({ handleNext, appName }: OwnProps) => {
  return (
    <Container>
      <Grid container rowGap={3}>
        <Grid item>
          <Typography variant="h4" fontWeight={(theme) => theme.typography.fontWeightBold}>
            Hello.
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="body2"
            fontSize={'14px'}
            fontWeight={(theme) => theme.typography.fontWeightMedium}
          >
            Welcome to the {appName}.
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="body2"
            fontSize={'14px'}
            fontWeight={(theme) => theme.typography.fontWeightMedium}
          >
            This is a program that allows companies to get cash advances on their invoices. Crowdz
            created this program to help small business owner improve their cashflow.
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="body2"
            fontSize={'14px'}
            fontWeight={(theme) => theme.typography.fontWeightMedium}
          >
            The Crowdz Administrator has invited you to the InvoiceExchange. Click below to
            register.
          </Typography>
        </Grid>
        <Grid item sx={{ marginTop: 2 }} xs={12}>
          <Button type="submit" variant="contained" onClick={handleNext}>
            Next
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};
export default InviteLandingView;
