import { createSlice } from '@reduxjs/toolkit';
import { MarketplaceState } from '../marketplace/marketplace.type';
import { MarketplaceApi } from 'api';

const initialState: MarketplaceState = {
  isLoading: false,
};

const marketplaceSlice = createSlice({
  name: 'marketplace',
  initialState,
  reducers: {
    setIsLoading: (state) => {
      state.isLoading = true;
    },
    reset: (state) => {
      state.isLoading = false;
    },
    setMarketplaceError: (state, action) => {
      state.marketplaceError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(MarketplaceApi.getBuyerList.pending, (state) => {
        state.isLoading = true;
        state.buyerList = undefined;
      })
      .addCase(MarketplaceApi.getBuyerList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.buyerList = action.payload;
      })
      .addCase(MarketplaceApi.getBuyerList.rejected, (state, action) => {
        state.isLoading = false;
        state.marketplaceError = action.payload;
      })
      .addCase(MarketplaceApi.getSingleBuyerInvoiceList.pending, (state) => {
        state.isLoading = true;
        state.singleBuyerInvoiceList = undefined;
      })
      // .addCase(MarketplaceApi.getSingleBuyerInvoiceList.fulfilled, (state, action) => {
      //   state.isLoading = false;
      //   state.singleBuyerInvoiceList = action.payload;
      // })
      .addCase(MarketplaceApi.getSingleBuyerInvoiceList.rejected, (state, action) => {
        state.isLoading = false;
        state.marketplaceError = action.payload;
      });
  },
});

export const { setIsLoading, reset, setMarketplaceError } = marketplaceSlice.actions;

export default marketplaceSlice.reducer;
