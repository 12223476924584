import { createSlice } from '@reduxjs/toolkit';
import { ConfigState } from '../config/config.type';
import { ConfigApi } from 'api';

const initialState: ConfigState = {
  isLoading: false,
};

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    reset: (state) => {
      state.configData = undefined;
      state.configError = undefined;
      state.isLoading = false;
    },
    setIsLoading: (state) => {
      state.isLoading = true;
    },
    setConfigError: (state, action) => {
      state.configError = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(ConfigApi.getTenantConfig.pending, (state) => ({
        ...state,
        isLoading: true,
        configData: undefined,
        configError: undefined,
      }))
      .addCase(ConfigApi.getTenantConfig.fulfilled, (state, action) => ({
        ...state,
        isLoading: false,
        configData: action.payload,
        configError: undefined,
      }))
      .addCase(ConfigApi.getTenantConfig.rejected, (state, action) => ({
        ...state,
        isLoading: false,
        configError: { ...action.payload, serviceName: action.type },
      }));
  },
});

export const { setIsLoading, reset, setConfigError } = configSlice.actions;

export default configSlice.reducer;
