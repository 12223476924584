import { Stack, Card, Typography, styled, Grid, Button, Avatar } from '@mui/material';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import ManuallyAddBankAccount from 'components/Models/ManuallyAddBankAccountModal';
import { useState, useEffect } from 'react';
import HttpsIcon from '@mui/icons-material/Https';
import BankingListView from './BankItemView';
import FormikTextField from 'components/formik/FormikTextField';
import { usePlaidLink } from 'react-plaid-link';
import { BankApi, UserManagementApi, UserNotificationApi } from 'api';
import { useDispatch, useSelector } from 'redux/store';
import useAuth from 'hooks/useAuth';
import Notifications from 'components/Notifications';
import CommonMessageModal from 'components/Models/CommonMessageModal';
import { CommonErrorModalProps } from 'redux/common/common.type';
import CustomSnackbar from 'components/CustomSnackbar';
import * as Yup from 'yup';
import { BankInformationData } from 'redux/bank/bank.type';
import { BANk_PATTERN_VALIDATION, OnlyNum_VALIDATION } from '../../../utils/inputValidation';

const StyledAvtar = styled(Avatar)(({ theme }) => ({
  maxWidth: '100px',
  width: '100%',
  height: '100px',
  background: 'transparent',
  color: theme.palette.text.secondary,
  boxShadow: theme.shadows[2],
}));

const initialValues = {
  company_recip_name: '',
  bank_name: '',
  acc_type: '',
  account_number: '',
  routing_number: '',
};
const ValidationSchema = Yup.object().shape({
  company_recip_name: Yup.string().required('This field is required.'),
  bank_name: Yup.string()
    .required('This field is required.')
    .matches(BANk_PATTERN_VALIDATION, 'Invalid Bank Name'),
  account_number: Yup.number().required('This field is required.'),
  acc_type: Yup.string().required('This field is required.'),
  routing_number: Yup.number().required('This field is required.'),
});

const BankingInformation = () => {
  const [warningMessage, setWarningMessage] = useState<string>();
  const [openCommonModal, setOpenCommonModal] = useState<CommonErrorModalProps>({ open: false });
  const [openSnackbar, setOpenSnackbar] = useState<CommonErrorModalProps>({ open: false });
  const [isManualBanking, setIsManualBanking] = useState(false);
  const [accounts, setAccounts] = useState<BankInformationData[]>();

  const dispatch = useDispatch();
  const {
    tenantDetailData,
    role,
    ach_debit_allowed,
    ofac_verification,
    cis_approval,
    is_bank_verification_required,
  } = useAuth();
  const { bankOptions, plaidConfig } = useSelector((state) => state.bank);
  const { profileFieldsData } = useSelector((state) => state.userManagement);
  const { userData } = useAuth();

  const [labelFields, setLabelFields] = useState({
    accountField: profileFieldsData?.country?.find((p) => p.field_name === 'account_number'),
    routingField: profileFieldsData?.country?.find((p) => p.field_name === 'routing_number'),
  });

  const updatePlaidToken = async (data: any) => {
    const action = await dispatch(BankApi.savePlaidToken(data));
    if (BankApi.savePlaidToken.fulfilled.match(action)) {
      action.payload?.success &&
        setOpenSnackbar({ open: true, message: action.payload.success.message, type: 'success' });
      init();
    }

    if (BankApi.savePlaidToken.rejected.match(action)) {
      action.payload?.error &&
        setOpenSnackbar({ open: true, message: action.payload?.error?.message, type: 'error' });
    }
  };

  const saveEventAudit = (dataBody: any) => {
    dispatch(BankApi.saveEventAudit(dataBody));
  };

  const { open, ready, error } = usePlaidLink({
    token: plaidConfig?.plaid_public_key || '',
    publicKey: plaidConfig?.plaid_public_key || '',
    clientName: tenantDetailData?.app_name || '',
    env: plaidConfig?.plaid_env || '',
    product: ['auth'],
    webhook: plaidConfig?.plaid_webhook_url || '',
    onSuccess: (public_token: string, metadata: any) => {
      const data = {
        transaction_type: 'funding',
        public_token: public_token,
        accounts: metadata?.accounts,
        institution: metadata?.institution,
      };
      updatePlaidToken(data);
    },
    onExit: (err: any, metadata: any) => {
      const dataBody = {
        error: err,
        metadata: metadata,
      };
      saveEventAudit(dataBody);
    },
    onEvent: (eventName: any, metadata: any) => {
      const dataBody = {
        eventName: eventName,
        metadata: metadata,
        status: undefined,
      } as any;
      if (eventName === 'ERROR' || eventName === 'FAIL_OAUTH') {
        dataBody.status = 'error';
      }
      saveEventAudit(dataBody);
    },
  });
  const getWalletAccounts = async () => {
    let walletListResponse = await dispatch(BankApi.getConnectedBankAccountList(''));
    if (BankApi.getConnectedBankAccountList.fulfilled.match(walletListResponse)) {
      setAccounts(walletListResponse?.payload);
    }
    if (BankApi.getConnectedBankAccountList.rejected.match(walletListResponse)) {
      setAccounts(undefined);
      walletListResponse.payload?.error &&
        setOpenSnackbar({
          open: true,
          message: walletListResponse?.payload?.error?.message,
          type: 'error',
        });
    }
  };
  const init = () => {
    getWalletAccounts();
    dispatch(BankApi.getWesternUnionBankingOptions());
    dispatch(UserNotificationApi.getNotification(`?limit=${0}&offset=${5}`));
    let params = '?country_code=' + userData?.user_details?.country_code;
    dispatch(UserManagementApi.getProfileFields(params));
  };
  useEffect(() => {
    dispatch(BankApi.getPlaidConfiguration());
    init();
  }, []);

  useEffect(() => {
    if (role === 'seller' && !ach_debit_allowed) {
      setWarningMessage(
        `Below account(s) will be used to receive funding, only one account is required but you can add more. Please select your bank account to receive funding.`
      );
    } else if (role === 'funder') {
      setWarningMessage(
        `Below account(s) will be used for funding, only one account is required but you can add more. Please select your bank account for funding.`
      );
    }
  }, []);

  const handleConnectAutomaticBank = () => {
    if (cis_approval !== 'approve') {
      setOpenCommonModal({
        open: true,
        message: (
          <Typography>
            {
              'Thank you for completing the registration process.  It’s currently under review.  Within the next 2-3 business days, you’ll receive an email from us.'
            }{' '}
            <small>
              If you have any questions in the meantime, reach out to{' '}
              {tenantDetailData?.support_email}
            </small>
          </Typography>
        ),
      });
    } else {
      open();
    }
  };

  const handleSetDefaultAccount = async (path: string) => {
    const action = await dispatch(BankApi.setDefaultAccount(path));
    if (BankApi.setDefaultAccount.fulfilled.match(action)) {
      action.payload?.success &&
        setOpenSnackbar({ open: true, message: action.payload?.success?.message, type: 'success' });
      init();
    }
    if (BankApi.setDefaultAccount.rejected.match(action)) {
      action.payload?.error &&
        setOpenSnackbar({ open: true, message: action.payload?.error?.message, type: 'error' });
    }
  };

  const handleDeleteAccount = async (path: string) => {
    const action = await dispatch(BankApi.deleteAccountDetails(path));
    if (BankApi.deleteAccountDetails.fulfilled.match(action)) {
      init();
      action.payload?.success &&
        setOpenSnackbar({ open: true, message: action.payload?.success?.message, type: 'success' });
    }
    if (BankApi.deleteAccountDetails.rejected.match(action)) {
      action.payload?.error &&
        setOpenSnackbar({ open: true, message: action.payload?.error?.message, type: 'error' });
    }
  };
  return (
    <>
      {openSnackbar.open && (
        <CustomSnackbar
          open={openSnackbar.open}
          severityType={openSnackbar.type}
          message={openSnackbar.message as string}
          header={openSnackbar.message_header}
          onClose={() => {
            setOpenSnackbar({ open: false, message: undefined, message_header: undefined });
          }}
        />
      )}
      {openCommonModal.open && (
        <CommonMessageModal
          open={openCommonModal.open}
          message={openCommonModal.message}
          onClose={() => {
            setOpenCommonModal({ open: false, message: undefined });
          }}
        />
      )}
      {
        !!warningMessage && (
          <Notifications
            type={'information'}
            title={'Note'}
            message={warningMessage}
            handleCloseNotification={() => setWarningMessage(undefined)}
          />
        )
        // <CustomSnackbar
        //   open={true}
        //   severityType="info"
        //   message={warningMessage}
        //   header="Note"
        //   onClose={() => {
        //     setWarningMessage(undefined);
        //   }}
        // />
      }
      <Stack
        direction="row"
        justifyContent={'space-between'}
        alignItems={'center'}
        marginBottom={2}
      >
        <Typography variant="h6" fontWeight={(theme) => theme.typography.fontWeightBold} mb={2}>
          Bank Account
        </Typography>
        {isManualBanking && (
          <ManuallyAddBankAccount
            open={isManualBanking}
            onClose={() => {
              setIsManualBanking(false);
            }}
            labelFields={labelFields}
            handleConfirmSuccessOrError={(m) => {
              setOpenCommonModal({
                open: true,
                message: <Typography>{m}</Typography>,
              });
            }}
          />
        )}

        {bankOptions?.includes('manual') && !!accounts && accounts?.length > 0 && (
          <Button
            endIcon={<HttpsIcon />}
            variant="contained"
            onClick={() => {
              setIsManualBanking(true);
            }}
          >
            Manually Add Bank Account
          </Button>
        )}
      </Stack>
      {accounts?.length === 0 && bankOptions?.includes('automatic') && (
        <Card
          sx={{
            maxWidth: '450px',
            width: '100%',
            padding: '50px 100px',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            margin: '0 auto',
            borderRadisu: '12px',
          }}
        >
          <Stack direction="row" marginBottom={3} justifyContent="center">
            <StyledAvtar>
              <HttpsIcon />
            </StyledAvtar>
            <StyledAvtar sx={{ marginLeft: '-20px' }}>
              <HttpsIcon />
            </StyledAvtar>
          </Stack>
          <Stack>
            <Button
              endIcon={<HttpsIcon />}
              variant="contained"
              onClick={handleConnectAutomaticBank}
            >
              Add Bank Account
            </Button>
          </Stack>
        </Card>
      )}

      <Grid container columnSpacing={4} rowGap={4}>
        {Array.isArray(accounts) &&
          accounts?.map((data, i) => (
            <BankingListView
              bankOptions={bankOptions}
              data={data}
              key={i}
              index={i}
              role={role}
              ach_debit_allowed={ach_debit_allowed}
              onMenuChange={handleSetDefaultAccount}
              handleDeleteAccount={handleDeleteAccount}
            />
          ))}
      </Grid>

      {accounts?.length === 0 && bankOptions?.includes('manual') && (
        <>
          <Typography
            variant="subtitle1"
            fontWeight={(theme) => theme.typography.fontWeightBold}
            marginY={3}
          >
            Enter your bank account information.
          </Typography>
          <Formik
            initialValues={initialValues}
            validationSchema={ValidationSchema}
            onSubmit={(values) => {
              console.log(values); //TODO need to integrate
              setIsManualBanking(true);
            }}
          >
            <Form>
              <Grid container rowGap={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Field
                    name="company_recip_name"
                    type="text"
                    component={FormikTextField}
                    label="Company Recipient Name*"
                  ></Field>
                  <ErrorMessage
                    name="company_recip_name"
                    component="div"
                    render={(msg) => (
                      <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                        {msg}
                      </Typography>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Field
                    name="bank_name"
                    type="text"
                    component={FormikTextField}
                    label="Bank Name*"
                  ></Field>
                  <ErrorMessage
                    name="bank_name"
                    component="div"
                    render={(msg) => (
                      <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                        {msg}
                      </Typography>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Field
                    name="account_number"
                    type="text"
                    component={FormikTextField}
                    label={
                      labelFields?.accountField?.label
                        ? labelFields?.accountField?.label + '*'
                        : 'Account Number*'
                    }
                    InputProps={{
                      pattern: labelFields?.accountField?.regex
                        ? labelFields?.accountField?.regex
                        : OnlyNum_VALIDATION,
                    }}
                  ></Field>
                  <ErrorMessage
                    name="account_number"
                    component="div"
                    render={(msg) => (
                      <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                        {msg}
                      </Typography>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Field
                    name="acc_type"
                    type="text"
                    component={FormikTextField}
                    label="Type of Account*"
                  ></Field>
                  <ErrorMessage
                    name="acc_type"
                    component="div"
                    render={(msg) => (
                      <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                        {msg}
                      </Typography>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Field
                    name="routing_number"
                    type="text"
                    component={FormikTextField}
                    label={
                      labelFields?.routingField?.label
                        ? labelFields?.routingField?.label + '*'
                        : 'Routing Number*'
                    }
                    InputProps={{
                      pattern: labelFields?.routingField?.regex
                        ? labelFields?.routingField?.regex
                        : OnlyNum_VALIDATION,
                    }}
                  ></Field>
                  <ErrorMessage
                    name="routing_number"
                    component="div"
                    render={(msg) => (
                      <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                        {msg}
                      </Typography>
                    )}
                  />
                </Grid>
              </Grid>
              <Button
                endIcon={<HttpsIcon />}
                variant="contained"
                type="submit"
                sx={{ marginTop: 3 }}
              >
                Add Bank Account
              </Button>
            </Form>
          </Formik>
        </>
      )}
    </>
  );
};

export default BankingInformation;
