import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { alpha, styled } from '@mui/material/styles';
import { ReactNode } from 'react';
interface OwnsProps {
  children?: any;
  title: ReactNode | string;
}
const CustomTooltip = ({ children, title }: OwnsProps) => {
  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 320,
      padding: 15,
      color: theme.palette.text.secondary,
      backgroundColor: theme.palette.primary.contrastText,
      margin: '0 !important',
      boxShadow: `${alpha(theme.palette.grey[400], 0.25)} 0 0 0 0.1rem`,
      '.MuiTypography-caption': {
        lineHeight: 1,
      },
      '.MuiTooltip-arrow': {
        '&::before': {
          color: theme.palette.primary.contrastText,
        },
      },
    },
  }));
  return (
    <HtmlTooltip arrow title={title}>
      {children}
    </HtmlTooltip>
  );
};
export default CustomTooltip;
