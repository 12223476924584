import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Grid } from '@mui/material';

// hooks
import useSettings from 'hooks/useSettings';
import useResponsive from 'hooks/useResponsive';
import useCollapseDrawer from 'hooks/useCollapseDrawer';

//
import Sidebar from './sidebar/Sidebar';
import { HEADER, NAVBAR } from 'commonTypes/settings';

type MainStyleProps = {
  collapseClick: boolean;
};

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})<MainStyleProps>(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  marginTop: '10px',
  paddingLeft: 50,
  paddingRight: 50,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    // paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
  [theme.breakpoints.down('lg')]: {
    paddingLeft: 25,
    paddingRight: 25,
  },
  table: {
    th: {
      fontSize: '12px',
    },
    td: {
      fontSize: '12px',
      border: 'none',
    },
  },
}));

// ----------------------------------------------------------------------

const DashboardLayout = () => {
  const { collapseClick, isCollapse } = useCollapseDrawer();

  const { themeLayout } = useSettings();

  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);

  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      <Sidebar />

      <MainStyle collapseClick={collapseClick}>
        <Outlet />
      </MainStyle>
    </Box>
  );
};

export default DashboardLayout;
