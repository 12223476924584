import { Grid, Typography, Link, Button, Box, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PATH_AUTH } from 'routes/paths';
import { SamplePDF } from 'assets';

// Add server update
const InviteAcceptTerms = () => {
  const navigate = useNavigate();

  return (
    <Grid container rowGap={3}>
      <Grid item xs={12}>
        <Typography
          variant="body2"
          fontSize={'16px'}
          fontWeight={(theme) => theme.typography.fontWeightBold}
        >
          Finally , accept our Terms and conditions.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            height: '420px',
          }}
        >
          <iframe
            src={SamplePDF}
            style={{
              border: 0,
              height: '100%',
            }}
          ></iframe>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" fontSize={'13px'}>
          Applying our service will not affect your credit score.
        </Typography>
      </Grid>
      <Grid item sx={{ marginTop: 2 }} xs={12} md={12} lg={12} xl={12} textAlign="center">
        <Stack display={'inline-flex'} marginRight={4}>
          <Button
            variant={'contained'}
            onClick={() => {
              navigate(PATH_AUTH.login);
            }}
          >
            Accept Lets Get Started
          </Button>
          <Button
            variant={'text'}
            sx={{ color: (theme) => theme.palette.grey[800], marginTop: '15px' }}
            onClick={() => {
              navigate(PATH_AUTH.login);
            }}
          >
            I decline , don't register me
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};
export default InviteAcceptTerms;
