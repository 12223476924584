export const VALIDATE_PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,16})/;
export const NAME_VALIDATION = /^[a-zA-Z]+ [a-zA-Z]+$/;
export const BANk_PATTERN_VALIDATION = /^[a-zA-Z0-9 \s]+$/;
export const INPUT_EMAIL_VALIDATION = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const BUSINESS_EMAIL_VALIDATION = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
export const COMPANY_EMAIL_VALIDATION = /([\w\.\-_]+)?\w+@[\w-_]+(\.\w+){1,}/;
export const CITY_VALIDATION = /^[a-zA-Z-\s]+$/;
export const BENEFICIAL_OWNER_NAME_VALIDATION = /[A-Za-z]+$/;
export const BENEFICIAL_PER_OWNED_VALIDATION = /\d{0,2}(\.\d{1,8})? *%?$/;
export const COMPANY_ABN_VALIDATION = /[0-9]{11}$/;
export const USERPROFILE_NAME_VALIDATION = /^[a-zA-Z](?:[ '\a-zA-Z]*[a-zA-Z])?$/;
export const USERPROFILE_CITY_VALIDATION = /^[a-zA-Z\-\.\s]+$/;
export const USERPROFILE_POSTALCODE_VALIDATION = /^[A-Z0-9_ ]{4,8}$/;
export const USERPROFILE_SSN_VALIDATION = /[0-9]{9}/;
export const WebURLPATTERN_VALIDATION =
  /((https?|ftp|smtp|http)?:\/\/(?:www\.|(?!www))[a-zA-Z][a-zA-Z-]+[a-zA-Z]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z]+\.[^\s]{2,})/;
export const OnlyNum_VALIDATION = /^[0-9]*$/;
export const PERCENT_OWNED_VALIDATION = /(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,8})?$)/;
export const safeNumberOrDecimal = (v: any) => {
  if (typeof v === 'number') return v;
  if (typeof v === 'string' && /^[0-9,]*$/g.test(v)) return parseFloat(v);
  return 0.0;
};
