import { createSlice } from '@reduxjs/toolkit';
import { OverviewState } from '../overview/overview.type';
import { OverviewApi } from 'api';

const initialState: OverviewState = {
  isLoading: false,
};
// TODO add error handling
const dashboardSlice = createSlice({
  name: 'overview',
  initialState,
  reducers: {
    reset: (state) => {
      state = {
        isLoading: false,
        // walletsPaymentData: undefined,
        dashboardMatrixData: undefined,
        overviewError: undefined,
        funderDashboardExportCSVData: undefined,
      };
    },
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setOverviewError: (state, action) => {
      state.overviewError = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(OverviewApi.getDashboardMatrix.fulfilled, (state, action) => {
      state.dashboardMatrixData = action.payload;
    });
    // .addCase(OverviewApi.getWalletsPayment.fulfilled, (state, action) => {
    //   state.walletsPaymentData = action.payload;
    // })
  },
});

export const { setIsLoading, setOverviewError, reset } = dashboardSlice.actions;

export default dashboardSlice.reducer;
