import { format, parseISO } from 'date-fns';
import { CurrencyFilter } from 'redux/invoice/invoice.type';
import { NotificationResultData } from '../redux/userNotification/userNotification.type';

export const formateDate = (date?: any, type = '') => {
  try {
    if (type === 'json') {
      console.log(format(date, 'yyyy-MM-dd'));
      if (!!date) return format(date, 'yyyy-MM-dd');
    } else {
      if (!!date) return format(date, 'dd MMM yyyy');
    }
  } catch (e) {
    return '';
  }

  return '';
};

export const formatNumberByCurrency = (currency: string, value: number | string) => {
  try {
    return new Intl.NumberFormat(currency || 'usd', { minimumFractionDigits: 2 }).format(
      Number(value)
    );
  } catch (e) {
    return value;
  }
};

export const getAge = (param: string) => {
  let today = new Date();
  let birthDate = new Date(param);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const checkMaxMinLength = (isd_code: string, type: string) => {
  if (isd_code === '+1') {
    return '14';
  } else if (isd_code === '+61') {
    return type === 'min' ? '11' : '12';
  } else {
    return '10';
  }
  return '';
};

export const checkPatternAccordingToIsdCode = (isd_code: string) => {
  if (isd_code === '+1') {
    return '^[-()0-9 ]{14,14}';
  } else if (isd_code === '+61') {
    return '^[0-9 ]{11,12}$';
  } else {
    return '^[0-9 ]{10,10}';
  }
  return '';
};

export const staticMonths = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const getListOfMonthOfYear = () => {
  let arrMonthsYearList = [];
  const date = new Date();
  for (let i = 0; i < 13; i++) {
    let nextDate = new Date(date.getFullYear(), date.getMonth() + i, 1);
    let nextMon = nextDate.getMonth();
    let nextYear = nextDate.getFullYear();
    arrMonthsYearList.push({ month: staticMonths[nextMon], year: nextYear });
  }
  return arrMonthsYearList;
};

export const copyText = async (text: string) => {
  if ('clipboard' in navigator) {
    await navigator.clipboard.writeText(text);
  } else {
    document.execCommand('copy', true, text);
  }
};

export const toUtcDate = (date: string | number | Date) => {
  const dateObj = new Date(date);
  return new Date(dateObj.getUTCFullYear(), dateObj.getUTCMonth(), dateObj.getUTCDate());
};

export const getWarnings = (warnings: NotificationResultData[], type: string) => {
  return Array.isArray(warnings) && warnings.length > 0
    ? warnings.filter((f) => f.receivable_type === type && f.unread).splice(0, 2)
    : [];
};

export const getLinkFromWarning = (message: string) => {
  const tmp = {
    message,
    link: '',
  };
  const index = message.indexOf('<a>');
  if (index !== -1) {
    tmp.message = message.substring(0, index - 1);
    tmp.link = message.substring(index).replace('<a>', '').replace('</a>', '');
  }
  return tmp;
};

export const getDefaultCurrency = (c: CurrencyFilter[]) => {
  const defaultC = c.find((p) => p.is_default);
  if (defaultC) {
    return defaultC;
  }
  const firstC = c.find((p) => p.id || p.display_name !== 'All');
  if (firstC) return firstC;
};

export const getFilter = (values: string[], invoiceFilter?: CurrencyFilter[]) =>
!!invoiceFilter && values?.length > 0 && !values.includes('All')
  ? `&currency=${invoiceFilter
      .filter((f) => values.includes(f.display_name))
      .map((p) => p.id)
      .join(',')
      .trim()}`
  : '';
