import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Divider from '@mui/material/Divider/Divider';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import ReactPlayer from 'react-player';
// import { FBTestimonial } from 'assets'; //TODO

interface OwnProps {
  info?: string;
  isMobile?: boolean;
}
const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
}));

const FacebookLoginSection = ({ info, isMobile }: OwnProps) => {
  return (
    <>
      <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
      <Typography variant="body1" sx={{ textAlign: 'center' }}>
        {info}
      </Typography>
      {/* <ReactPlayer
        url={FBTestimonial}
        playing={false}
        controls={true}
        // loop={true}
        muted={false}
        playsinline={true}
        style={{ padding: 0, margin: 0 }}
        width={isMobile ? 340 : 440}
      /> */}
      <div style={{ paddingTop: 5, alignContent: 'center', width: '100%' }}>
        <div style={{ display: 'flex' }}>
          <StyledLink target="_blank" href="https://www.facebook.com/business/invoice-fast-track">
            Visit out website
          </StyledLink>
          <div>
            <ArrowRightIcon />
          </div>
        </div>
      </div>
    </>
  );
};

export default FacebookLoginSection;
