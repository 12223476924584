import { VisibilityOff, Visibility } from '@mui/icons-material';
import { InputAdornment, Typography, Grid, Button, IconButton } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { CreatePasswordPayload } from 'redux/userManagement/userManagement.type';
import * as Yup from 'yup';
import FormikTextField from 'components/formik/FormikTextField';
import { VALIDATE_PASSWORD } from 'utils/inputValidation';
import { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';

interface OwnProps {
  onSubmit: (values: CreatePasswordPayload) => void;
  username: string;
  init: CreatePasswordPayload;
  isLoading: boolean;
}

// TODO add Crowdz password policy
const ValidationSchema = Yup.object().shape({
  password1: Yup.string()
    .required('This field is required.')
    .matches(
      VALIDATE_PASSWORD,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
    ),
  password2: Yup.string()
    .oneOf([Yup.ref('password1'), null], 'Confirm password must match')
    .required('This field is required.'),
});

const PasswordRegStep = ({ onSubmit, username, init, isLoading }: OwnProps) => {
  const [showPassword, setShowPassword] = useState({
    password1: false,
    password2: false,
  });
  return (
    <Formik
      initialValues={init}
      validationSchema={ValidationSchema}
      onSubmit={(values, actions) => {
        actions.setSubmitting(false);
        onSubmit(values);
      }}
    >
      {(props) => (
        <Form autoComplete="off">
          <Grid container sx={{ marginTop: 3 }}>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                fontSize={'16px'}
                fontWeight={(theme) => theme.typography.fontWeightBold}
                marginBottom={2}
              >
                Now let's set up a secure password to access your account
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" fontSize={'12px'}>
                P.S. Your username will be {username}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Field
                label={<span style={{ color: 'textSecondary', fontSize: '15px' }}>Password</span>}
                fontSize={'13px'}
                name={`password1`}
                component={FormikTextField}
                autoComplete={'off'}
                marginBottom={4}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setShowPassword((prevState) => ({
                            ...prevState,
                            password1: !showPassword?.password1,
                          }))
                        }
                        edge="end"
                      >
                        {showPassword?.password1 ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                type={showPassword?.password1 ? 'text' : 'password'}
              />
              <ErrorMessage
                name="password1"
                component="div"
                render={(msg) => (
                  <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                    {msg}
                  </Typography>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              {/* TODO Confirm Password comes  prefilled Even We have just come first time. */}
              <Field
                label={
                  <span style={{ color: 'textSecondary', fontSize: '15px' }}>Confirm Password</span>
                }
                type={showPassword?.password2 ? 'text' : 'password'}
                fontSize={'13px'}
                name={`password2`}
                component={FormikTextField}
                autoComplete={'off'}
                marginBottom={4}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setShowPassword((prevState) => ({
                            ...prevState,
                            password2: !showPassword?.password2,
                          }))
                        }
                        edge="end"
                      >
                        {showPassword?.password2 ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <ErrorMessage
                name="password2"
                component="div"
                render={(msg) => (
                  <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                    {msg}
                  </Typography>
                )}
              />
            </Grid>
            <Grid item sx={{ marginTop: 2 }} xs={12}>
              <LoadingButton loading={isLoading} type="submit" variant="contained">
                Next
              </LoadingButton>
            </Grid>
            {/* <Grid item xs={12}>TODO display error 
              <ErrorMessage name="password1" />
            </Grid> */}
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default PasswordRegStep;
