import { Dialog, IconButton, styled } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import DialogActions from '@mui/material/DialogActions';
import { ReactNode } from 'react';
import CommonBootstrapDialogTitle, { BootstrapDialog } from 'components/CommonBootstrapDialogTitle';

interface OwnsProps {
  open: boolean;
  onClose: () => void;
  message?: ReactNode | string;
  title?: string;
  actionButtons?: ReactNode;
  children?: ReactNode;
}

const InformationModal = ({
  open,
  onClose,
  message,
  title,
  actionButtons,
  children,
}: OwnsProps) => {
  const handleClose = () => {
    onClose();
  };
  return (
    <BootstrapDialog open={open} onClose={handleClose} maxWidth={'sm'}>
      <CommonBootstrapDialogTitle title={title} onClose={handleClose} />

      <DialogContent sx={{ paddingX: '10px' }}>
        <Typography variant="subtitle2">{message}</Typography> {children}
      </DialogContent>
      {actionButtons && <DialogActions>{actionButtons}</DialogActions>}
    </BootstrapDialog>
  );
};

export default InformationModal;
