import { Grid, Typography, Link, Button, Stack } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

interface OwnProps {
  updateUserLevel: () => void;
  termsAndConditions?: string;
  privacyPolicy?: string;
  isLoading: boolean;
}
const TermConditionRegStep = ({
  updateUserLevel,
  termsAndConditions = '',
  privacyPolicy = '',
  isLoading,
}: OwnProps) => {
  return (
    <Grid container rowGap={3}>
      <Grid item xs={12}>
        <Typography
          variant="body2"
          fontSize={'16px'}
          fontWeight={(theme) => theme.typography.fontWeightBold}
        >
          By continuing in the marketplace, you agree to our
          <Link
            variant="body2"
            fontSize={'16px'}
            sx={{
              textDecoration: 'none',
              color: (theme) => theme.palette.primary.main,
              paddingLeft: '5px',
            }}
            href={termsAndConditions}
            target="_blank"
          >
            Terms and Conditions.
          </Link>
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography
          variant="body2"
          fontSize={'16px'}
          fontWeight={(theme) => theme.typography.fontWeightBold}
        >
          View our
          <Link
            variant="body2"
            fontSize={'16px'}
            sx={{
              textDecoration: 'none',
              color: (theme) => theme.palette.primary.main,
              paddingLeft: '5px',
            }}
            href={privacyPolicy}
            target="_blank"
          >
            Privacy Policy.
          </Link>
        </Typography>
      </Grid>
      <Grid item sx={{ marginTop: 2 }} xs={12} md={12} lg={12} xl={12} alignItems="center">
        <LoadingButton
          loading={isLoading}
          variant={'contained'}
          onClick={() => {
            updateUserLevel();
          }}
        >
          {'Go To Marketplace'}
        </LoadingButton>
      </Grid>
    </Grid>
  );
};
export default TermConditionRegStep;
