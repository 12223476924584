import {
  Stack,
  Typography,
  Grid,
  FormControl,
  styled,
  SelectChangeEvent,
  Input,
  Select,
  MenuItem,
  Button,
  TextField,
  FormControlLabel,
  Link,
  RadioGroup,
  Radio,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import SettingsBreadCrumbs from 'components/breadCrumbs/SettingsBreadCrumbs';
import { ErrorMessage, Field, Form, Formik, useFormik } from 'formik';
import { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

import { UserListJson } from 'redux/settings/settings.type';
import { PATH_AUTH } from 'routes/paths';
import useAuth from 'hooks/useAuth';
import { useDispatch, useSelector } from 'redux/store';
import { SettingsApi } from 'api';
import Chip from '@mui/material/Chip';
import InputAdornment from '@mui/material/InputAdornment';
import { UserInvitation } from '../../redux/settings/settings.type';
import { CommonErrorModalProps } from 'redux/common/common.type';
import CommonMessageModal from 'components/Models/CommonMessageModal';
import CustomSnackbar from 'components/CustomSnackbar';
import Page from 'components/Page';
import { FileUploader } from 'react-drag-drop-files';
import * as Yup from 'yup';
import { BUSINESS_EMAIL_VALIDATION } from '../../utils/inputValidation';
import FormikTextField from 'components/formik/FormikTextField';
import FormikSelect from 'components/formik/FormikSelect';

const StyledTypo = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  marginBottom: 1,
  color: theme.palette.grey[600],
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  '.MuiSelect-standard': {
    height: '31px !important',
    marginTop: '10px',
    backgroundColor: theme.palette.common.white,
    borderRadius: '5px',
    padding: '5px 10px',
    fontSize: '13px',
    boxShadow: theme.shadows[0],
    '&:focus': { backgroundColor: theme.palette.common.white },
  },
}));

const StyledInput = styled(Input)(({ theme }) => ({
  '.MuiInput-input': {
    height: '32px',
    backgroundColor: theme.palette.common.white,
    marginTop: '10px',
    borderRadius: '5px',
    padding: '5px 10px',
    fontSize: '13px',
    boxShadow: theme.shadows[0],
    '&.Mui-disabled': {
      backgroundColor: theme.palette.grey[200],
    },
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '.MuiInputBase-root': {
    height: '37px',
  },
  '.MuiInputLabel-root': {
    fontSize: '14px ',
  },
  '.MuiInputBase-input': {
    fontSize: '14px ',
  },
}));

const ValidationSchema = Yup.object().shape({
  first_name: Yup.string().required('First name required'),
  last_name: Yup.string().required('Last name required'),
  email: Yup.string()
    .email()
    .required('Email required')
    .matches(BUSINESS_EMAIL_VALIDATION, 'Invalid Email.'),
  access_level: Yup.string().required('Access Level required'),
});

const AdminPage = () => {
  const navigate = useNavigate();
  const { userData, tenantDetailData } = useAuth();
  const dispatch = useDispatch();
  const { userInvitationData } = useSelector((state) => state.settings);
  const [openSnackbar, setOpenSnackbar] = useState<CommonErrorModalProps>({ open: false });

  useEffect(() => {
    dispatch(SettingsApi.getUserInvitation(`?limit=10&offset=0`));
  }, []);

  const breadCrumbs = { name: 'Settings/Admin', path: '#' };
  // https://exchange-uat.crowdz.io/api/multi_user_access/user_invitation/?limit=10&offset=0
  // https://exchange-uat.crowdz.io/api/usermgmt/organization_list/
  // https://exchange-uat.crowdz.io/api/multi_user_access/company_invitation/?limit=10&offset=0
  // https://exchange-uat.crowdz.io/api/usermgmt/approver_type_list/

  // POST https://exchange-uat.crowdz.io/api/multi_user_access/user_invitation/
  // payload
  // {"personal_message":"Message test ","cc_myself":"True",
  // "user_list_json": [{ "first_name": "Mukhtar", "last_name": "Test",
  // "email": "hecibe9248@usharer.com", "access_level": "administrator",
  // "personal_message": "Message test ", "cc_myself": "True"
  // }], "company_name": "Qantas Airways Limited", "new_company_invitation": false,
  // "role_id": []
  // }
  const handlePostInvitation = async (values: UserListJson) => {
    const payload = {
      personal_message: values.email,
      cc_myself: values.cc_myself,
      user_list_json: [
        {
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          access_level: values.access_level,
          personal_message: values.email,
          cc_myself: values.cc_myself,
        },
      ],
      company_name: values.company_name,
      new_company_invitation: false,
      role_id: [],
    };
    //navigate(`${PATH_AUTH.invite}/id=1`)}
    const action = await dispatch(SettingsApi.sendUserInvitation(payload));
    if (SettingsApi.sendUserInvitation.fulfilled.match(action)) {
      action.payload?.success &&
        setOpenSnackbar({
          open: true,
          message: action?.payload?.success?.message,
          type: 'success',
        });
    }
    if (SettingsApi.sendUserInvitation.rejected.match(action)) {
      action.payload?.error &&
        setOpenSnackbar({ open: true, message: action?.payload?.error?.message, type: 'error' });
    }
  };

  const handleAccessLevelChanges = async (p: UserInvitation, newValue: string) => {
    if (newValue === 'remove_user') {
      const action = await dispatch(SettingsApi.deleteUserInvitation(`?email=${p.email}`));
      if (SettingsApi.deleteUserInvitation.fulfilled.match(action)) {
        action.payload?.success &&
          setOpenSnackbar({
            open: true,
            message: action?.payload?.success?.message,
            type: 'success',
          });
      }
      if (SettingsApi.deleteUserInvitation.rejected.match(action)) {
        action.payload?.error &&
          setOpenSnackbar({ open: true, message: action?.payload?.error?.message, type: 'error' });
      }
    } else {
      const action = await dispatch(
        SettingsApi.changeUserAccess({ email: p.email, access_level: newValue })
      );
      if (SettingsApi.changeUserAccess.fulfilled.match(action)) {
        action.payload?.success &&
          setOpenSnackbar({
            open: true,
            message: action?.payload?.success?.message,
            type: 'success',
          });
      }
      if (SettingsApi.changeUserAccess.rejected.match(action)) {
        action.payload?.error &&
          setOpenSnackbar({ open: true, message: action?.payload?.error?.message, type: 'error' });
      }
    }
  };
  return (
    <Page title="Admin">
      <SettingsBreadCrumbs data={breadCrumbs} />
      <Formik
        initialValues={
          {
            company_name: userData?.company_details.name,
            cc_myself: 'False',
          } as UserListJson
        }
        validationSchema={ValidationSchema}
        enableReinitialize={true}
        onSubmit={(values) => {
          handlePostInvitation(values);
        }}
      >
        {(formProps) => (
          <Form>
            <Grid container columnSpacing={3} rowGap={2} >
              <Grid item xs={12}>
                <Typography variant="body2" fontWeight={(theme) => theme.typography.fontWeightBold}>
                  Invite Users
                </Typography>
              </Grid>
              {tenantDetailData?.invite_approver && userData?.company_details?.is_tenant_owner && (
                <Grid item xs={12}>
                  Do you want to Invite Approver?
                  <RadioGroup row aria-labelledby=" Do you want to Invite Approver?">
                    <FormControlLabel control={<Radio />} label="Yes" />
                    <FormControlLabel control={<Radio />} label="No" />
                  </RadioGroup>
                </Grid>
              )}

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Stack>
                  <Field
                    component={FormikTextField}
                    label="First Name*"
                    name={`first_name`}
                    fontSize={'14px'}
                  />
                  <ErrorMessage
                    name="first_name"
                    component="div"
                    render={(msg) => (
                      <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                        {msg}
                      </Typography>
                    )}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Stack>
                  <Field
                    component={FormikTextField}
                    label="Last Name*"
                    name={`last_name`}
                    fontSize={'14px'}
                  />
                  <ErrorMessage
                    name="last_name"
                    component="div"
                    render={(msg) => (
                      <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                        {msg}
                      </Typography>
                    )}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Stack>
                  <Field
                    component={FormikTextField}
                    label="Business Email*"
                    name={`email`}
                    fontSize={'14px'}
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    render={(msg) => (
                      <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                        {msg}
                      </Typography>
                    )}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Stack>
                  <Field
                    component={FormikTextField}
                    label="Company*"
                    name={`company_name`}
                    fontSize={'14px'}
                    disabled={!!formProps.values.company_name}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Stack>
                  <Field
                    component={FormikSelect}
                    label="Select Access Level*"
                    name={`access_level`}
                    fontSize={'14px'}
                    marginTop="20px"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="administrator">Administrator</MenuItem>
                    <MenuItem value="view_only">View Only</MenuItem>
                  </Field>
                  <ErrorMessage
                    name="access_level"
                    component="div"
                    render={(msg) => (
                      <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                        {msg}
                      </Typography>
                    )}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Stack>
                  <StyledTypo variant="body2">Invitation Message</StyledTypo>
                  <Stack direction={'row'}>
                    <TextField
                      fullWidth
                      id="outlined-multiline-static"
                      multiline
                      rows={4}
                      placeholder="Add Personal message"
                      value={formProps.values.personal_message || ''}
                      onChange={(e) => {
                        formProps.setFieldValue('personal_message', e.target.value);
                      }}
                    />
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
            <FormControlLabel
              sx={{ width: '100%' }}
              control={
                <Checkbox
                  icon={<RadioButtonUncheckedIcon />}
                  checkedIcon={<RadioButtonCheckedIcon />}
                  checked={/true/i.test(formProps.values.cc_myself)}
                  value={formProps.values.cc_myself}
                  onChange={(e) => {
                    formProps.setFieldValue(
                      'cc_myself',
                      e.target.value === 'False' ? 'True' : 'False'
                    );
                  }}
                />
              }
              label="CC Myself"
            />
            <Button variant="contained" type="submit">
              Send Invitation
            </Button>
          </Form>
        )}
      </Formik>

      {!!userInvitationData && Array.isArray(userInvitationData) && (
        <>
          <Grid container columnSpacing={3} rowGap={2} marginTop="20px">
            <Grid item xs={12}>
              <Typography variant="body2" fontWeight={(theme) => theme.typography.fontWeightBold}>
                Manage Users
              </Typography>
            </Grid>
            {userInvitationData.map((p) => (
              <Fragment key={p.id}>
                <Grid item xs={12} sm={12} md={6} lg={3}>
                  <Stack>
                    <StyledTypo variant="body2">First Name,Last Name</StyledTypo>
                    <StyledInput
                      disableUnderline={true}
                      value={`${p.first_name} ${p.last_name} 
                      
                      `}
                      readOnly
                      endAdornment={
                        <InputAdornment position="start">
                          <Chip label="pending" color="success" />
                        </InputAdornment>
                      }
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3}>
                  <Stack>
                    <StyledTypo variant="body2">Company</StyledTypo>
                    <StyledInput disableUnderline={true} value={p.company_name} readOnly />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3}>
                  <Stack>
                    <StyledTypo variant="body2">Email Address</StyledTypo>
                    <StyledInput disableUnderline={true} value={p.email} readOnly />
                  </Stack>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={3}>
                  <Stack>
                    <StyledTypo variant="body2">Role</StyledTypo>
                    <FormControl variant="standard">
                      <StyledSelect
                        disableUnderline={true}
                        value={p.access_level}
                        onChange={(e) => {
                          handleAccessLevelChanges(p, e.target.value as string);
                        }}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="administrator">Administrator</MenuItem>
                        <MenuItem value="view_only">View Only</MenuItem>
                        <MenuItem value="remove_user">Remove User</MenuItem>
                      </StyledSelect>
                    </FormControl>
                  </Stack>
                </Grid>
              </Fragment>
            ))}
          </Grid>
        </>
      )}

      {openSnackbar.open && (
        <CustomSnackbar
          open={openSnackbar.open}
          severityType={openSnackbar.type}
          message={openSnackbar.message as string}
          header={openSnackbar.message_header}
          onClose={() => {
            setOpenSnackbar({ open: false, message: undefined, message_header: undefined });
          }}
        />
      )}
    </Page>
  );
};
export default AdminPage;
