import { createAsyncThunk } from '@reduxjs/toolkit';
import { ServerErrors } from 'redux/common/common.type';
import {
  DashboardMatrix,
  FunderDashboardDetailsExportPayload,
  HTMLBlob,
} from 'redux/overview/overview.type';
import axios, { handleServerErrors } from 'utils/axios.config';

const funderDashboardDetailsExport = createAsyncThunk<
  HTMLBlob,
  FunderDashboardDetailsExportPayload,
  { rejectValue: ServerErrors }
>('funderDashboardDetailsExport', async (payload, { rejectWithValue }) => {
  try {
    const { currency, recType } = payload;
    const { data } = await axios.get<HTMLBlob>(
      `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/funder_dashboard_details_export/?currency_type=${currency}&receivable_type=${recType}`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

const getDashboardMatrix = createAsyncThunk<
  DashboardMatrix[],
  string,
  { rejectValue: ServerErrors }
>('getDashboardMatrix', async (rType, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<DashboardMatrix[]>(
      `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/dashboard_matrix/?receivable_type=${rType}`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

export default {
  funderDashboardDetailsExport,
  getDashboardMatrix,
};
