import { AlertColor } from '@mui/lab/Alert';
import { InvoiceResultData } from 'redux/invoice/invoice.type';
export interface CommonState {
  isLoading: boolean;
  supportedCurrenciesData?: SupportedCurrencies[];
  invoiceCurrenciesData?: SupportedCurrencies[];
  recurrCurrenciesData?: SupportedCurrencies[];
  allCurrenciesData?: AllCurrencies[];
  blogData?: BlogResponse;
  tilesInvoiceData?: TilesData;
  tilesSaasData?: TilesData;
  commonError?: ServerErrors;
  isBlogLoading: boolean;
  isCurrencyLoading: boolean;
  isInvoiceCurrencyLoading: boolean;
  isRecCurrencyLoading: boolean;
  isTilesInvoiceLoading: boolean;
  isTilesSaasLoading: boolean;
  isCountryListLoading: boolean;
  countryListData?: CountryListData;
  isStateListLoading: boolean;
  stateList?: StateData;
  multipleCompanyAddressData?: MultipleCompanyAddress[];
}
export interface VisitedPagePayload {
  page_name: string;
}

export interface VisitedPageData {
  message: string;
  message_code: string;
  message_header: string;
}

export interface GrossProfitData {
  header: string;
  amount: GrossProfitAmount[];
}

export interface GrossProfitAmount {
  type: string;
  label: string;
  count: number;
}
export interface QuickActionData {
  header: string;
  actions: any[];
}

export interface ActionNeededData {
  invoices: any[];
  auctions: any[];
}
export interface CountryListResultData {
  id: number;
  numeric_code: string;
  alpha2_code: string;
  alpha3_code: string;
  country_name: string;
  isd_code: string;
  country_flag: string;
  is_supported: boolean;
  currency_code_alpha_3: string;
  currency_symbol: string;
}
export interface CountryListData {
  count: number;
  next: string | null;
  previous: string | null;
  results: CountryListResultData[];
}
export interface AllCurrencies {
  currency_alpha_3: string;
  currency_name: string;
  currency_numeric: string;
}
export interface StateDataResult {
  region_name: string;
  full_region_code: string;
  region_code: string;
}
export interface StateData {
  count: number;
  next: string | null;
  previous: string | null;
  results: StateDataResult[];
}

export interface SupportedCurrencies {
  id: number;
  alpha_3_code: string;
  currency_symbol: string;
  is_default: boolean;
  flagIcon?: string;
}

export interface ServerErrors {
  status?: number;
  error?: ErrorSuccessDetail;
  severity?: ServerErrorSeverity;
  serviceName?: string;
  detail?: string;
  isLogOut?: boolean;
}

export interface ResponseSuccess {
  status: number;
  success: ErrorSuccessDetail;
}
export interface ErrorSuccessDetail {
  message_header?: string;
  message?: string;
  message_code?: string;
}

export enum ServerErrorSeverity {
  HIGH,
  MEDIUM,
  LOW,
}

export interface Tiles {
  type: string;
  label: string;
  filter_type: string;
  count: number;
  total_amount?: string;
  avg_amount?: string;
  avg_percentage?: string;
  value?: number;
  points?: number;
  all_time_high?: number;
  all_time_low?: number;
}
export interface TilesData {
  header: string;
  tiles: Tiles[];
}

export interface DocumentsData {
  doc_id: string;
  name: string;
  label: string;
}

export interface InvoicesDueData {
  label: string;
  count: number;
  total_amount: string;
}

export interface SellerProfileDetailsData {
  email: string;
  company_name: string;
  first_name: string;
  last_name: string;
  street_1: string;
  street_2: string;
  city: string;
  state: string;
  country: string;
  zipcode: string;
  phone: string;
  date_joined: string;
  url: string;
  title: string;
  ucc_search: string;
  surf_score: number;
  documents: DocumentsData;
  tiles_data: Tiles;
  due_invoices: InvoicesDueData;
  gross_profit_data: {
    gross_profit: number;
    avg_profit: number;
  };
  irr_data: {
    avg_return: number;
    avg_apr: number;
    ann_irr: number;
  };
  current_exposure: number;
  sticky_note_details: {
    id: number;
    description: string;
  };
  corp_search_report: any;
  ucc_details_report: any;
  ucc_summary_report: any;
  ucc_status: string;
}

export enum Role {
  SELLER = 'seller',
  BUYER = 'buyer',
  FUNDER = 'funder',
}

export enum LevelAccess {
  ADMINISTRATOR = 'administrator',
  VIEW_ONLY = 'view_only',
}

export enum VerificationTypes {
  SMS = 'sms',
  CALL = 'call',
  EMAIL = 'email',
}
export enum UserStatus {
  ADVANCED_SIGNUP_COMPLETED = 3,
  SECURE_PASSWORD_COMPLETED = 4,
  ACCEPT_TERMS_COMPLETED = 5,

  BASIC_SIGNUP_COMPLETED = 1,
  SET_COUNTRY_STATE = 2,
  SET_ACCOUNT_PASSWORD = 3,
  SECURITY_QUESTION_ANSWER = 4,
  SET_PHONE_NUMBER = 5,
  ROLE_UPDATION_COMPLETED = 6,
  COMPLETE_YOUR_REGISTRATION = 7,

  // Invited User STEPS
  INVITE_SET_PHONE_NUMBER = 4,
  INVITE_SECURITY_QUESTION_ANSWER = 3,
}

export interface MultipleCompanyAddress {
  business_address: string;
  city: string;
  company: number;
  country: string;
  country_code: string;
  customer_directory: null;
  data_type: string;
  id: number;
  name: string;
  state: string;
  state_code: string;
  zip_code: string;
  entity_name: string;
  entity_type: string;
  incorporation_state: string;
}

export interface CommonSuccessResponse {
  success: CommonSuccessData;
  status: number;
}

export interface CommonSuccessData {
  message_code: string;
  message_header: string;
  message: string;
}

export interface UpdateFlagsData {
  seller_id: number;
  seller_paused: boolean;
}

export interface SearchResponse {
  screen?: string;
}

export interface BlogDetail {
  archived: boolean;
  archivedAt: number;
  archivedInDashboard: boolean;
  attachedStylesheets: any[];
  authorName: string;
  blogAuthorId: string;
  categoryId: number;
  contentGroupId: string;
  contentTypeCategory: number;
  created: string;
  createdById: string;
  currentState: string;
  currentlyPublished: boolean;
  deletedAt: string;
  domain: string;
  enableGoogleAmpOutputOverride: boolean;
  featuredImage: string;
  featuredImageAltText: string;
  htmlTitle: string;
  id: string;
  language: string;
  layoutSections: any;
  metaDescription: string;
  name: string;
  postBody: string;
  postSummary: string;
  publicAccessRules: any[];
  publicAccessRulesEnabled: boolean;
  publishDate: string;
  publishImmediately: boolean;
  rssBody: string;
  rssSummary: string;
  slug: string;
  state: string;
  tagIds: number[];
  translations: any;
  updated: string;
  updatedById: string;
  url: string;
  useFeaturedImage: boolean;
  widgetContainers: any;
  widgets: any;
}

export interface BlogResponse {
  response: BlogDetail[];
}

export interface CustomerAddress {
  type: string | null;
  line1: string | null;
  line2: string | null;
  city: string;
  region: string;
  country: string;
  postalCode: string;
}

export interface CountryObject {
  id: string | number | null;
  isd_code: string | number | null;
  numeric_code: string;
  alpha2_code: string;
  alpha3_code: string;
  country_name: string;
  country_flag: string;
  is_supported: boolean;
  currency_code_alpha_3: string;
  currency_symbol: string;
}

export interface Contacts {
  id: number;
  first_name: string;
  last_name: string | null;
  email: string;
  address_1: string;
  address_2: string;
  city: string;
  state: string;
  country: string;
  zipcode: string;
  phone_number: string;
  country_isd_code: string;
  country_obj: CountryObject;
}
export interface CreateDirectoryResponseData {
  id: number;
  Customer_address: CustomerAddress[];
  city: string;
  state: string;
  country: string;
  zipcode: string;
  email: string;
  phone: string;
  address_1: string;
  address_2: string | null;
  type: string;
  country_code: string;
  state_code: string;
  contacts: Contacts[];
  country_isd_code: string;
  buyer_dnb_status: string;
  buyer_eular_status: string;
  registration_number: string;
  name: string;
  directory_type: string;
  internal_identifier: string;
  duns_number: string | null;
  dnb_status: string;
  kompany_status: string;
  eular_status: string;
  owner: number;
  creator: number;
}

export interface CreateDirectoryResponse extends CommonSuccessResponse {
  data: CreateDirectoryResponseData;
}

export enum ModalActions {
  SHOW_INVOICE_DETAIL = 'showInvoiceDetail',
  SHOW_BUYER_DETAIL = 'showBuyerDetail',
  SHOW_SELLER_DETAIL = 'showSellerDetail',
  PAY_NOW_SINGLE = 'Pay Now',
  PAY_NOW_MULTIPLE = 'Pay Now Multiple Invoice',
  VIEW_PAYMENT_DETAIL = 'View payment details',
  VIEW_REFERENCE_ID = 'View Reference ID',
  CONTACT_BUYER = 'Contact Buyer',
  CONTACT_SELLER = 'Contact Seller',
  CONTACT_FUNDER = 'Contact Funder',
  MARK_AS_REPAID = 'Mark as Repaid',
}
export interface CountryCodeData {
  type: string;
  data: string;
}

export interface CommonErrorModalProps {
  open: boolean;
  message?: React.ReactNode | string;
  message_header?: string;
  type?: AlertColor;
}

export interface VerifyCompanyModalProps {
  open?: boolean;
  data?: Partial<Contacts>[];
  query?: string;
  id?: number;
  companyName?: string;
  companyAddress?: string;
  company_type?: string;
  searchType?: string;
}

export interface InfoModalProps {
  open?: boolean;
  message?: string;
  children?: string;
  type?: string;
  title?: string;
  handleAction?: () => void;
  openEmail?: boolean;
}

export interface InvoiceQueryParams {
  action: ModalActions;
  invoice?: InvoiceResultData;
}

export interface OwnCommonModalError {
  open: boolean;
  message?: React.ReactNode | string;
  message_header?: string;
  type?: AlertColor;
}
