import { createSlice } from '@reduxjs/toolkit';
import { CommonState } from '../common/common.type';
import { CommonApi } from 'api';
import { addFlagIcons } from 'utils/common';

const initialState: CommonState = {
  isLoading: false,
  isBlogLoading: false,
  isCurrencyLoading: false,
  isTilesInvoiceLoading: false,
  isTilesSaasLoading: false,
  isInvoiceCurrencyLoading: false,
  isRecCurrencyLoading: false,
  isCountryListLoading: false,
  isStateListLoading: false,
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setIsLoading: (state) => {
      state.isLoading = true;
    },
    reset: (state) => {
      state.isLoading = false;
    },
    setCommonError: (state, action) => {
      state.commonError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(CommonApi.getSupportedCurrenciesForInvoice.pending, (state) => {
        state.isCurrencyLoading = true;
        state.supportedCurrenciesData = undefined;
      })
      .addCase(CommonApi.getSupportedCurrenciesForInvoice.fulfilled, (state, action) => {
        state.isCurrencyLoading = false;
        state.supportedCurrenciesData = addFlagIcons(action.payload);
      })
      .addCase(CommonApi.getSupportedCurrenciesForInvoice.rejected, (state, action) => {
        state.isCurrencyLoading = false;
        state.commonError = action.payload;
      })
      .addCase(CommonApi.getAllCurrencies.pending, (state) => {
        state.isCurrencyLoading = true;
        state.allCurrenciesData = undefined;
      })
      .addCase(CommonApi.getAllCurrencies.fulfilled, (state, action) => {
        state.isCurrencyLoading = false;
        state.allCurrenciesData = action.payload;
      })
      .addCase(CommonApi.getSupportedCurrencies.pending, (state) => {
        state.isCurrencyLoading = true;
        state.supportedCurrenciesData = undefined;
      })
      .addCase(CommonApi.getSupportedCurrencies.fulfilled, (state, action) => {
        state.isCurrencyLoading = false;
        state.supportedCurrenciesData = addFlagIcons(action.payload);
      })
      .addCase(CommonApi.getSupportedCurrencies.rejected, (state, action) => {
        state.isCurrencyLoading = false;
        state.commonError = action.payload;
      })
      .addCase(CommonApi.getSupportedCurrenciesAndTiles('invoice').pending, (state) => {
        state.isInvoiceCurrencyLoading = true;
        state.invoiceCurrenciesData = undefined;
      })
      .addCase(CommonApi.getSupportedCurrenciesAndTiles('invoice').fulfilled, (state, action) => {
        state.isInvoiceCurrencyLoading = false;
        state.invoiceCurrenciesData = action.payload;
      })
      .addCase(CommonApi.getSupportedCurrenciesAndTiles('invoice').rejected, (state, action) => {
        state.isInvoiceCurrencyLoading = false;
        state.commonError = action.payload;
      })
      .addCase(CommonApi.getSupportedCurrenciesAndTiles('saas_contract').pending, (state) => {
        state.isRecCurrencyLoading = true;
        state.recurrCurrenciesData = undefined;
      })
      .addCase(
        CommonApi.getSupportedCurrenciesAndTiles('saas_contract').fulfilled,
        (state, action) => {
          state.isRecCurrencyLoading = false;
          state.recurrCurrenciesData = action.payload;
        }
      )
      .addCase(
        CommonApi.getSupportedCurrenciesAndTiles('saas_contract').rejected,
        (state, action) => {
          state.isRecCurrencyLoading = false;
          state.commonError = action.payload;
        }
      )
      .addCase(CommonApi.getBlogs.pending, (state) => {
        state.isBlogLoading = true;
        state.blogData = undefined;
      })
      .addCase(CommonApi.getBlogs.fulfilled, (state, action) => {
        state.isBlogLoading = false;
        state.blogData = action.payload;
      })
      .addCase(CommonApi.getBlogs.rejected, (state, action) => {
        state.isBlogLoading = false;
        state.commonError = action.payload;
      })
      .addCase(CommonApi.getTilesData('invoice').pending, (state) => {
        state.isTilesInvoiceLoading = true;
        state.tilesInvoiceData = undefined;
      })
      .addCase(CommonApi.getTilesData('invoice').fulfilled, (state, action) => {
        state.isTilesInvoiceLoading = false;
        state.tilesInvoiceData = action.payload;
      })
      .addCase(CommonApi.getTilesData('invoice').rejected, (state, action) => {
        state.isTilesInvoiceLoading = false;
        state.commonError = action.payload;
      })
      .addCase(CommonApi.getTilesData('saas_contract').pending, (state) => {
        state.isCurrencyLoading = true;
        state.tilesSaasData = undefined;
      })
      .addCase(CommonApi.getTilesData('saas_contract').fulfilled, (state, action) => {
        state.isCurrencyLoading = false;
        state.tilesSaasData = action.payload;
      })
      .addCase(CommonApi.getTilesData('saas_contract').rejected, (state, action) => {
        state.isCurrencyLoading = false;
        state.commonError = action.payload;
      })
      .addCase(CommonApi.getCountriesList.pending, (state) => {
        state.isCountryListLoading = true;
        state.countryListData = undefined;
      })
      .addCase(CommonApi.getCountriesList.fulfilled, (state, action) => {
        state.isCountryListLoading = false;
        state.countryListData = action.payload;
      })
      .addCase(CommonApi.getCountriesList.rejected, (state, action) => {
        state.isCountryListLoading = false;
        state.commonError = action.payload;
      })
      .addCase(CommonApi.getStateData.pending, (state) => {
        state.isStateListLoading = true;
        state.stateList = undefined;
      })
      .addCase(CommonApi.getStateData.fulfilled, (state, action) => {
        state.isStateListLoading = false;
        state.stateList = action.payload;
      })
      .addCase(CommonApi.getStateData.rejected, (state, action) => {
        state.isStateListLoading = false;
        state.commonError = action.payload;
      })
      .addCase(CommonApi.getMultipleCompanyList.pending, (state) => {
        state.isLoading = true;
        state.multipleCompanyAddressData = undefined;
      })
      .addCase(CommonApi.getMultipleCompanyList.fulfilled, (state, action) => {
        state.multipleCompanyAddressData = action.payload;
        state.isLoading = false;
      })
      .addCase(CommonApi.getMultipleCompanyList.rejected, (state, action) => {
        state.commonError = action.payload;
        state.isLoading = false;
      });
    // .addCase(CommonApi.getVideoTutorial.pending, (state) => {
    //   state.videoTutorialData = undefined;
    // })
    // .addCase(CommonApi.getVideoTutorial.fulfilled, (state, action) => {
    //   state.videoTutorialData = action.payload;
    // })
    // .addCase(CommonApi.getVideoTutorial.rejected, (state, action) => {
    //   state.commonError = action.payload as Error;
    // })
    // .addCase(CommonApi.getProfileFields.pending, (state) => {
    //   state.profileFieldsData = undefined;
    // })
    // .addCase(CommonApi.getProfileFields.fulfilled, (state, action) => {
    //   state.profileFieldsData = action.payload;
    // })
    // .addCase(CommonApi.getProfileFields.rejected, (state, action) => {
    //   state.commonError = action.payload as Error;
    // })
  },
});

export const { setIsLoading, reset, setCommonError } = commonSlice.actions;

export default commonSlice.reducer;
