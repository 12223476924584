// TODO review it
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ServerErrors, CommonSuccessResponse } from 'redux/common/common.type';
import { OfferSummeryData } from 'redux/invoice/invoice.type';
import { BuyerListData, SingleBuyerInvoiceListResult } from 'redux/marketplace/marketplace.type';
import axios, { handleServerErrors } from 'utils/axios.config';

// TODO
const getOfferSummary = createAsyncThunk<OfferSummeryData, string, { rejectValue: ServerErrors }>(
  'getOfferSummary',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<OfferSummeryData>(
        `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/basic_offer_details/${params}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

const getBuyerList = createAsyncThunk<BuyerListData, string, { rejectValue: ServerErrors }>(
  'getBuyerList',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<BuyerListData>(
        `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/buyer_list/${params}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

const getSingleBuyerInvoiceList = createAsyncThunk<
  SingleBuyerInvoiceListResult,
  string,
  { rejectValue: ServerErrors }
>('getSingleBuyerInvoiceList', async (params, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<SingleBuyerInvoiceListResult>(
      `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/buyer_invoice_list/${params}`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

const expireOffer = createAsyncThunk<
  CommonSuccessResponse,
  {
    auction_id?: number;
  },
  { rejectValue: ServerErrors }
>('expireOffer', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await axios.post<CommonSuccessResponse>(
      `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/expire_offer/`,
      payload
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});
export default {
  getOfferSummary,
  getBuyerList,
  expireOffer,
  getSingleBuyerInvoiceList,
};
