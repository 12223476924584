import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {
  FormControl,
  Grid,
  Input,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  styled,
  Typography,
  InputLabel,
} from '@mui/material';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { ErrorMessage, Field, Form, Formik, useFormik } from 'formik';
import { useDispatch } from 'redux/store';
import { BankApi } from 'api';
import { ManuallyAddBankAccountPayload } from '../../redux/bank/bank.type';
import IconButton from '@mui/material/IconButton';
import CommonBootstrapDialogTitle from 'components/CommonBootstrapDialogTitle';
import * as Yup from 'yup';
import { BANk_PATTERN_VALIDATION } from 'utils/inputValidation';

const ValidationSchema = Yup.object().shape({
  name_on_account: Yup.string().required('This field is required.'),
  bank_name: Yup.string()
    .required('This field is required.')
    .matches(BANk_PATTERN_VALIDATION, 'Enter valid Bank Name'),
  account_number: Yup.number().required('This field is required.'),
  sub_type: Yup.string().required('This field is required.'),
  routing_number: Yup.number().required('This field is required.'),
});
interface OwnProps {
  open: boolean;
  onClose: (values?: any) => void;
  handleConfirmSuccessOrError: (message: string) => void;
  labelFields: any;
}
const StyledLabel = styled(InputLabel)(({ theme }) => ({
  fontSize: '13px',
  color: theme.palette.grey[900],
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const ManuallyAddBankAccount = ({
  open,
  onClose,
  handleConfirmSuccessOrError,
  labelFields,
}: OwnProps) => {
  const dispatch = useDispatch();

  const handleAddBankAccount = async (values: ManuallyAddBankAccountPayload) => {
    const action = await dispatch(BankApi.addManualBankAccount(values));
    if (BankApi.addManualBankAccount.fulfilled.match(action)) {
      onClose();
      handleConfirmSuccessOrError(action.payload?.success?.message);
    }
    if (BankApi.addManualBankAccount.rejected.match(action)) {
      onClose();
      handleConfirmSuccessOrError(action.payload?.error?.message || '');
    }
  };
  const initialValues = {
    name_on_account: '',
    bank_name: '',
    account_number: '',
    sub_type: '',
    routing_number: '',
  } as ManuallyAddBankAccountPayload;

  return (
    <>
      <BootstrapDialog open={open} onClose={onClose} maxWidth={'sm'}>
        <CommonBootstrapDialogTitle title={' Manually Add Bank Account'} onClose={onClose} />
        <Formik
          initialValues={initialValues}
          validationSchema={ValidationSchema}
          onSubmit={(values) => {
            handleAddBankAccount(values);
          }}
        >
          {(formProps) => {
            return (
              <Form>
                <DialogContent>
                  <Grid container marginBottom={4}>
                    <Grid item xs={12} sm={12}>
                      <Stack>
                        <FormControl variant="standard">
                          <StyledLabel>Company Recipient Name</StyledLabel>
                          <Input
                            name="name_on_account"
                            placeholder="Company Recipient Name"
                            value={formProps.values.name_on_account}
                            onChange={(e) => {
                              formProps.setFieldValue('name_on_account', e.target.value);
                            }}
                          />
                        </FormControl>
                        <ErrorMessage
                          name="name_on_account"
                          component="div"
                          render={(msg) => (
                            <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                              {msg}
                            </Typography>
                          )}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                  <Grid container columnSpacing={4} rowGap={4}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Stack>
                        <FormControl variant="standard">
                          <StyledLabel>Bank Name</StyledLabel>
                          <Input
                            name="bank_name"
                            placeholder="Bank Name"
                            value={formProps.values.bank_name}
                            onChange={(e) => {
                              formProps.setFieldValue('bank_name', e.target.value);
                            }}
                          />
                        </FormControl>
                        <ErrorMessage
                          name="bank_name"
                          component="div"
                          render={(msg) => (
                            <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                              {msg}
                            </Typography>
                          )}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Stack>
                        <FormControl variant="standard">
                          <StyledLabel>
                            {labelFields?.accountField?.label
                              ? labelFields?.accountField?.label + '*'
                              : 'Account Number*'}
                          </StyledLabel>
                          <Input
                            name="account_number"
                            placeholder="Account Number"
                            value={formProps.values.account_number}
                            onChange={(e) => {
                              formProps.setFieldValue('account_number', e.target.value);
                            }}
                            inputProps={{ max: 50 }}
                          />
                        </FormControl>
                        <ErrorMessage
                          name="account_number"
                          component="div"
                          render={(msg) => (
                            <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                              {msg}
                            </Typography>
                          )}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Stack>
                        <FormControl variant="standard">
                          <StyledLabel id="select-standard-label">Type of Account</StyledLabel>
                          <Select
                            name="sub_type"
                            labelId="select-standard-label"
                            id="simple-select-standard"
                            value={formProps.values.sub_type}
                            onChange={(e) => {
                              formProps.setFieldValue('sub_type', e.target.value);
                            }}
                            label="Type of Account"
                          >
                            <MenuItem value="current_account">Current Account</MenuItem>
                            <MenuItem value="fixed_account">Fixed Account</MenuItem>
                            <MenuItem value="saving_account">Savings Account</MenuItem>
                          </Select>
                        </FormControl>
                        <ErrorMessage
                          name="sub_type"
                          component="div"
                          render={(msg) => (
                            <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                              {msg}
                            </Typography>
                          )}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Stack>
                        <FormControl variant="standard">
                          <StyledLabel>
                            {labelFields?.routingField?.label
                              ? labelFields?.routingField?.label + '*'
                              : 'Routing Number*'}
                          </StyledLabel>
                          <Input
                            name="routing_number"
                            placeholder="BSB Number"
                            value={formProps.values.routing_number}
                            onChange={(e) => {
                              formProps.setFieldValue('routing_number', e.target.value);
                            }}
                            inputProps={{ max: 50 }}
                          />
                        </FormControl>
                        <ErrorMessage
                          name="routing_number"
                          component="div"
                          render={(msg) => (
                            <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                              {msg}
                            </Typography>
                          )}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button variant="contained" type="submit">
                    Add Bank Account
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
        {/* <form onSubmit={formik.handleSubmit}>
        
        </form> */}
      </BootstrapDialog>
    </>
  );
};

export default ManuallyAddBankAccount;
