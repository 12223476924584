import { createAsyncThunk } from '@reduxjs/toolkit';
import { CommonSuccessResponse, ServerErrors } from 'redux/common/common.type';
import { ParameterData } from 'redux/parameters/parameters.type';
import axios, { handleServerErrors } from 'utils/axios.config';

const getParameterData = createAsyncThunk<ParameterData, string, { rejectValue: ServerErrors }>(
  'getParameterData',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<ParameterData>(
        `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/parameters/${params}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO check return type
const updateParameters = createAsyncThunk<
  CommonSuccessResponse,
  Partial<ParameterData>,
  { rejectValue: ServerErrors }
>('updateParameters', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await axios.put<CommonSuccessResponse>(
      `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/parameters/`,
      {
        ...payload,
      }
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

export default {
  getParameterData,
  updateParameters,
};
