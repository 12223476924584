import styled from '@mui/material/styles/styled';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { getLinkFromWarning } from 'commonFunctions/commonFunctions';
import { SETTINGS_PAGES } from 'routes/paths';
import { useNavigate } from 'react-router-dom';

interface OwnProps {
  type?: string;
  title?: string;
  message: string;
  handleCloseNotification?: () => void;
  redirectLink?: string;
  receivableType?: string;
}

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: '20px',
  display: 'block',
  padding: '20px',
  maxWidth: '600px',
  border: '2px solid',
  marginBottom: theme.spacing(3),
  background: 'transparent',
  [theme.breakpoints.down('sm')]: {
    background: 'transparent',
    boxShadow: 'none',
    padding: '10px',
  },
}));

const Notifications = ({
  type,
  title,
  message,
  redirectLink,
  handleCloseNotification,
  receivableType,
}: OwnProps) => {
  const navigate = useNavigate();
  const m = getLinkFromWarning(message);
  const getLink = () => {
    if (redirectLink === 'accounting-system') {
      return { link: SETTINGS_PAGES.accountingSystem, state: receivableType };
    }
    return { link: '#', state: undefined };
  };
  return (
    <>
      <StyledCard
        sx={{
          ...(type === 'success' && {
            color: (theme) => theme.palette.success.main,
            borderColor: (theme) => theme.palette.success.main,
          }),
          ...(type === 'information' && {
            color: (theme) => theme.palette.info.main,
            borderColor: (theme) => theme.palette.info.main,
          }),
          ...(type === 'warning' && {
            color: (theme) => theme.palette.warning.main,
            borderColor: (theme) => theme.palette.warning.main,
          }),
          ...(type === 'error' && {
            color: (theme) => theme.palette.error.main,
            borderColor: (theme) => theme.palette.error.main,
          }),
          ...(type === 'custom' && {
            color: (theme) => theme.palette.others?.main,
            borderColor: (theme) => theme.palette.primary.main,
          }),
        }}
      >
        {type !== 'custom' && (
          <Grid container>
            <Grid item xs={2} lg={1} alignItems={'center'}>
              {(type === 'success' && <CheckCircleOutlineIcon />) ||
                (type === 'information' && <InfoOutlinedIcon />) ||
                (type === 'warning' && <WarningAmberOutlinedIcon />) ||
                (type === 'error' && <ErrorOutlineOutlinedIcon />)}
            </Grid>
            <Grid item xs={10} lg={11}>
              <Box
                sx={{
                  marginBottom: '10px',
                  color: (theme) => theme.palette.text.primary,
                }}
              >
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Typography variant="body2">{title}</Typography>
                  <IconButton onClick={handleCloseNotification}>
                    <CloseOutlinedIcon />
                  </IconButton>
                </Stack>
              </Box>
              <Box sx={{ textAlign: 'left', color: (theme) => theme.palette.text.primary }}>
                <Typography variant="body2">
                  {m.message}{' '}
                  {!!m.link && m.link.length > 0 && (
                    <Link
                      sx={{ fontSize: '14px', textDecoration: 'none', cursor: 'pointer' }}
                      onClick={() => {
                        const c = getLink();
                        navigate(c.link || '#', { state: c.link });
                      }}
                    >
                      {m.link}
                    </Link>
                  )}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        )}
        {type === 'custom' && (
          <>
            <Box sx={{ marginBottom: '10px' }}>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography variant="body2">{title}</Typography>
                <IconButton onClick={handleCloseNotification}>
                  <CloseOutlinedIcon />
                </IconButton>
              </Stack>
            </Box>
            <Box sx={{ textAlign: 'left', display: 'flex' }}>
              <Typography variant="body2">
                {m.message}{' '}
                {!!m.link && m.link.length > 0 && (
                  <Link
                    sx={{ fontSize: '14px', textDecoration: 'none', cursor: 'pointer' }}
                    onClick={() => {
                      const c = getLink();
                      navigate(c.link || '#', { state: c.link });
                    }}
                  >
                    {m.link}
                  </Link>
                )}
              </Typography>
            </Box>
          </>
        )}
      </StyledCard>
    </>
  );
};

export default Notifications;
