import { createAsyncThunk } from '@reduxjs/toolkit';
import { ServerErrors } from 'redux/common/common.type';
import { CheckBankAccountExists, WalletsData } from 'redux/wallet/wallet.type';

import axios, { handleServerErrors } from 'utils/axios.config';

// TODO why review
const getInvoicePaymentDetails = createAsyncThunk<any, string, { rejectValue: ServerErrors }>(
  'getInvoicePaymentDetails',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<any>(
        `${process.env.REACT_APP_SERVER_URL}invoice-exchange/dashboard/invoice/payment/details/${params}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO  review
const getFinancialAccounts = createAsyncThunk<any, string, { rejectValue: ServerErrors }>(
  'getFinancialAccounts',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<any>(
        `${process.env.REACT_APP_SERVER_URL}directory/financial_accounts/${params}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO  review
const editFinancialAccounts = createAsyncThunk<any, any, { rejectValue: ServerErrors }>(
  'editFinancialAccounts',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.put<any>(
        `${process.env.REACT_APP_SERVER_URL}directory/financial_accounts/`,
        payload
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO  review
const deleteFinancialAccounts = createAsyncThunk<any, string, { rejectValue: ServerErrors }>(
  'deleteFinancialAccounts',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.delete<any>(
        `${process.env.REACT_APP_SERVER_URL}directory/financial_accounts/${params}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO  review
const addWalletFinancialAccounts = createAsyncThunk<any, any, { rejectValue: ServerErrors }>(
  'addFinancialAccounts',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post<any>(
        `${process.env.REACT_APP_SERVER_URL}directory/financial_accounts/`,
        payload
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO  review
const addWalletFormDataFinancialAccounts = createAsyncThunk<
  any,
  any,
  { rejectValue: ServerErrors }
>('addFinancialAccounts', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await axios.post<any>(
      `${process.env.REACT_APP_SERVER_URL}directory/saving-checking-account/`,
      payload
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

// TODO  review
const checkWalletFinancialAccountsExists = createAsyncThunk<
  CheckBankAccountExists,
  void,
  { rejectValue: ServerErrors }
>('checkWalletFinancialAccountsExists', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<CheckBankAccountExists>(
      `${process.env.REACT_APP_SERVER_URL}directory/bank_account_exists/`
    );
    return data;
  } catch (err) {
    return rejectWithValue(handleServerErrors(err));
  }
});

// TODO  review
const verifyPaymentBankAccount = createAsyncThunk<any, any, { rejectValue: ServerErrors }>(
  'verifyPaymentBankAccount',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post<any>(
        `${process.env.REACT_APP_SERVER_URL}payments/verify_bank_account/`,
        payload
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO  review
const getAllPaymentAccount = createAsyncThunk<any, void, { rejectValue: ServerErrors }>(
  'getAllPaymentAccount',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<any>(
        `${process.env.REACT_APP_SERVER_URL}payments/all_payment_accounts/`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO  review
const getPaymentMethodList = createAsyncThunk<any, string, { rejectValue: ServerErrors }>(
  'getPaymentMethodList',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<any>(
        `${process.env.REACT_APP_SERVER_URL}payments/get_payment_method/${params}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO  review
const moveMoney = createAsyncThunk<any, any, { rejectValue: ServerErrors }>(
  'moveMoney',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post<any>(
        `${process.env.REACT_APP_SERVER_URL}payments/move_money/`,
        payload
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);

// TODO check wallet types
const getWallets = createAsyncThunk<WalletsData[], void, { rejectValue: ServerErrors }>(
  'getWalletsPayment',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get<WalletsData[]>(
        `${process.env.REACT_APP_SERVER_URL}payments/get_wallets/`
      );
      return data;
    } catch (err) {
      return rejectWithValue(handleServerErrors(err));
    }
  }
);
export default {
  getInvoicePaymentDetails,
  getFinancialAccounts,
  editFinancialAccounts,
  deleteFinancialAccounts,
  addWalletFinancialAccounts,
  addWalletFormDataFinancialAccounts,
  checkWalletFinancialAccountsExists,
  verifyPaymentBankAccount,
  getAllPaymentAccount,
  getPaymentMethodList,
  moveMoney,
  getWallets,
};
