// @ts-nocheck
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enUK from './en/en.json';
import en from './en/enUS.json';
import fr from './fr/fr.json';

export const resources = {
  en: {
    en,
  },
  enUK: { enUK },
  fr: {
    fr,
  },
};

i18n.use(initReactI18next).init({
  // resources,
  lng: 'en',
  defaultNS: 'en',
  ns: ['en', 'enUS', 'fr'],
  fallbackLng: 'en',
  react: {
    // wait: true,
    useSuspense: true,
  },
  resources,
});

window.i18n = i18n as any;
export default i18n;
