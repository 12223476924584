import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { BreadCrumbData } from 'redux/breadcrumb/breadCrumb.type';

export interface OwnProps {
  data: BreadCrumbData;
}

const StyledBreadCrumbs = styled(Breadcrumbs)(({ theme }) => ({
  marginTop: '10px',
  marginBottom: '15px',
  li: {
    fontSize: '14px',
    color: theme.palette.grey[600],
  },
}));

const SettingsBreadCrumbs = ({ data }: OwnProps) => {
  return (
    <StyledBreadCrumbs aria-label="breadcrumb">
      {data.root && (
        <Link underline="hover" color="inherit" href={data.path}>
          {data.root}
        </Link>
      )}

      <Typography variant="body2">{data.name}</Typography>
    </StyledBreadCrumbs>
  );
};
export default SettingsBreadCrumbs;
