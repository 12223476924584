import {
  IconButton,
  styled,
  Typography,
  Grid,
  Avatar,
  SelectChangeEvent,
  MenuItem,
  Button,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { CreateSecurityQuestionPayload } from 'redux/userManagement/userManagement.type';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import FormikTextField from 'components/formik/FormikTextField';
import FormikSelect from 'components/formik/FormikSelect';
import { useDispatch, useSelector } from 'redux/store';
import { UserManagementApi } from 'api';

interface OwnProps {
  onSubmit: (values: CreateSecurityQuestionPayload) => void;
  init: CreateSecurityQuestionPayload;
  isLoading: boolean;
}

// TODO check security data type
const ValidationSchema = Yup.object().shape({
  security_answer: Yup.string().required('This field is required.'),
  security_question: Yup.number().required('This field is required.'),
});

const SecurityQuestionRegStep = ({ onSubmit, init, isLoading }: OwnProps) => {
  const dispatch = useDispatch();
  const { securityQData } = useSelector((state) => state.userManagement);
  // const handleChange = (event: SelectChangeEvent) => {
  //   setSelectCountry(event.target.value as string);
  // };

  useEffect(() => {
    dispatch(UserManagementApi.getSecurityQuestionsList());
  }, []);

  return (
    <>
      <Formik
        initialValues={init}
        validationSchema={ValidationSchema}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          onSubmit(values);
        }}
      >
        {(props) => (
          <Form autoComplete="off" onSubmit={props.handleSubmit}>
            <Grid container sx={{ marginTop: 3 }}>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  fontSize={'16px'}
                  fontWeight={(theme) => theme.typography.fontWeightBold}
                  marginBottom={'30px'}
                >
                  Select a security question.
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Field
                  component={FormikSelect}
                  name={`security_question`}
                  label={
                    <span style={{ color: 'textSecondary', fontSize: '15px' }}>
                      Select a security question
                    </span>
                  }
                  fontSize={'16px'}
                  marginBottom={'10px'}
                >
                  {!!securityQData &&
                    Array.isArray(securityQData.results) &&
                    securityQData.results.map((p) => (
                      <MenuItem key={p.id} value={p.id}>
                        {p.question}
                      </MenuItem>
                    ))}
                </Field>
                <ErrorMessage
                  name="security_question"
                  component="div"
                  render={(msg) => (
                    <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                      {msg}
                    </Typography>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  id="standard-password-input"
                  component={FormikTextField}
                  label={<span style={{ color: 'textSecondary', fontSize: '15px' }}>Answer</span>}
                  name={`security_answer`}
                  fontSize={'16px'}
                  marginBottom={'10px'}
                  autoComplete={'off'}
                />
                <ErrorMessage
                  name="security_answer"
                  component="div"
                  render={(msg) => (
                    <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                      {msg}
                    </Typography>
                  )}
                />
              </Grid>
              <Grid item sx={{ marginTop: 2 }} xs={12}>
                <LoadingButton loading={isLoading} type="submit" variant="contained">
                  Next
                </LoadingButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
export default SecurityQuestionRegStep;
