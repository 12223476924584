import { InputAdornment, Typography, Grid, MenuItem, Button, useMediaQuery } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { CreatePhoneNumberPayload, SendOTPPayload } from 'redux/userManagement/userManagement.type';
import * as Yup from 'yup';
import ReactPhoneInput from 'react-phone-input-2';
import FormikTextField from 'components/formik/FormikTextField';
import FormikSelect from 'components/formik/FormikSelect';
import { useState, useEffect } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { useDispatch, useSelector } from 'redux/store';
import { CommonApi } from 'api';

interface OwnProps {
  onSubmit: (values: CreatePhoneNumberPayload) => void;
  handleSendOTP: (phone: SendOTPPayload) => void;
  init: CreatePhoneNumberPayload;
  countryCode?: string;
  isLoading: boolean;
}
// TODO check country code. clean after test
const ValidationSchema = Yup.object().shape({
  phone: Yup.string().required('This field is required.'),
  otp: Yup.number().required('This field is required.'),
});

const PhoneNumberRegStep = ({
  handleSendOTP,
  onSubmit,
  init,
  countryCode,
  isLoading,
}: OwnProps) => {
  const [selectedCountry, setSelectedCountry] = useState({
    dialCode: '',
    code: '',
  });
  const matches = useMediaQuery('(max-width:599px)');
  const { countryListData } = useSelector((state) => state.common);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!countryListData) {
      dispatch(CommonApi.getCountriesList('?limit=250&sort_type=country_name'));
    }
  }, []);
  return (
    <>
      <Formik
        initialValues={init}
        validationSchema={ValidationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          onSubmit({
            ...values,
            country_isd_code: Number(
              countryListData?.results?.find(
                (f) => f.alpha2_code.toLowerCase() === selectedCountry.code
              )?.id || 0
            ),
          });
        }}
      >
        {({ errors, touched, ...others }) => {
          return (
            <Form autoComplete="off">
              <Grid container sx={{ marginTop: 3 }}>
                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    fontSize={'16px'}
                    fontWeight={(theme) => theme.typography.fontWeightBold}
                    marginBottom={'30px'}
                  >
                    Let's verify your mobile Phone Number.
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={8} md={8} lg={9} xl={9}>
                  <ReactPhoneInput
                    country={countryCode}
                    inputProps={{
                      name: 'phone',
                    }}
                    enableAreaCodes={false}
                    enableAreaCodeStretch={false}
                    onChange={(value, ...restProps: any) => {
                      console.log('>>>', restProps);
                      if (restProps[0]?.countryCode !== selectedCountry.code) {
                        setSelectedCountry({
                          dialCode: restProps[0]?.dialCode,
                          code: restProps[0]?.countryCode,
                        });
                      }
                      others.setFieldValue('phone', value);
                    }}
                    value={others.values.phone}
                    inputStyle={{
                      border: ' 0',
                      borderBottom: '1px solid #8c8e8f',
                      borderRadius: '0',
                      backgroundColor: 'transparent',
                      height: '35px',
                      padding: '0 37px',
                    }}
                    buttonStyle={{
                      border: '0',
                      backgroundColor: 'transparent',
                    }}
                    dropdownStyle={{ maxHeight: '150px' }}
                  />
                  <ErrorMessage
                    name="phone"
                    component="div"
                    render={(msg) => (
                      <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                        {msg}
                      </Typography>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={3} xl={3} textAlign="right">
                  <Button
                    type="button"
                    variant="contained"
                    sx={{
                      marginTop: matches ? 1 : undefined,
                    }}
                    disabled={!others.values.phone} //TODO not enabled evn after adding value to input
                    onClick={() =>
                      handleSendOTP({
                        country_isd_code: Number(
                          countryListData?.results?.find(
                            (f) => f.alpha2_code.toLowerCase() === selectedCountry.code
                          )?.id || 0
                        ),
                        phone: others.values.phone?.replace(selectedCountry.dialCode, ''),
                      })
                    }
                  >
                    Send OTP
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                  <Field
                    component={FormikTextField}
                    fullWidth
                    placeholder="Enter One type Passcode"
                    variant="standard"
                    name="otp"
                    autoComplete={'off'}
                    inputProps={{ maxLength: 6 }}
                  />
                  <ErrorMessage
                    name="otp"
                    component="div"
                    render={(msg) => (
                      <Typography sx={{ color: 'red', paddingTop: '5px', fontSize: '12px' }}>
                        {msg}
                      </Typography>
                    )}
                  />
                </Grid>

                <Grid item sx={{ marginTop: 2 }} xs={12}>
                  <LoadingButton
                    loading={isLoading}
                    type="submit"
                    variant="contained"
                    disabled={!others.dirty || !others.values.phone || !others.values.otp}
                  >
                    Next
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default PhoneNumberRegStep;
