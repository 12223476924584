import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import { DASHBOARD_PAGES, PATH_AUTH } from '../routes/paths';

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: ReactNode;
};

export default function LoginCheck({ children }: GuestGuardProps) {
  const { isAuthenticated, role, isMissingBasicSteps, registrationStepsData } = useAuth();
  const location = useLocation();

  if (
    isAuthenticated &&
    !isMissingBasicSteps &&
    location.pathname !== '/auth/signup-success-lander' &&
    location.pathname !== '/auth/sign-up' &&
    !!role
  ) {
    return (
      <Navigate
        to={
          role === 'funder' ? DASHBOARD_PAGES.funderDashboard : DASHBOARD_PAGES.marketplace // TODO check here
        }
      />
    );
  }

  if (isAuthenticated && isMissingBasicSteps && location.pathname !== '/auth/sign-up') {
    return (
      <Navigate
        to={PATH_AUTH.register}
        state={
          (Array.isArray(registrationStepsData) &&
            registrationStepsData.find((f) => f.step_number <= 7 && !f.is_completed)
              ?.step_number) ||
          0
        }
      />
    );
  }
  return <>{children}</>;
}
